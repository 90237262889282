import Request from "../../../Classes/Requests";

class AdminRegisterUserReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            get_all_user_list: super.url("/get_all_user_list?type=__TYPE__"),
            user_login_history: super.url("/user_login_history?user_id=__USER_ID__"),
        }
    }
}
export default new AdminRegisterUserReq();
