import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dashboard from '../DashBoard'
import './companyDocuments.scss';
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';
import { Link, useNavigate } from 'react-router-dom';
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading';
import CommanButton from '../../CommanComponents/CommanButton/CommanButton';
import FolderModal from '../DashBoard/RightBarPannel/TopActionButtons/Popups/FolderModal/FolderModal';
import SearchControl from '../../CommanComponents/SearchControl/SearchControl';
import Storage from '../../Classes/Storage';
import { folder_grid_View } from '../../Constant/auth';
import { useDispatch, useSelector } from 'react-redux';
import useFolders from '../../CustomHooks/Folders/useFolders';
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop';
import { resetReminderError, resetReminderFolder, searchReset, serachFolder } from '../../store/Actions/CompanyDocuments';
import ImageViewer from '../../CommanComponents/ImageViewer/ImageViewer';
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import Auth from '../../Classes/Auth';
import DocumentPrev from '../../CommanComponents/DocumentPrev/DocumentPrev';
import { SwiperSlide } from 'swiper/react';
import Breadcrumb from '../../CommanComponents/Breadcrumb';
import BreadcrumbItem from '../../CommanComponents/Breadcrumb/BreadcrumbItem';
import getFileExtension from '../../HelperFunction/getFileExtension';
import { ALLOW_IMAGE_EXTENSIONS, getBaseUrl } from '../../config';
import FileIcon from '../../Assets/images/fileIcon.png'
import { fileDownloaderHelperFunc } from '../../HelperFunction/fileDownloaderHelperFunc';
import { FILE_ACCEPT, IMG_ACCEPT } from '../../Constant/Constants';
import CompanyDocumentsListView from './CompanyDocumentsListView';
import ReminderPopup from '../../CommanComponents/ReminderPopup/ReminderPopup';
import useCompanyDocuments from '../../CustomHooks/CompanyDocuments/useCompanyDocuments';
import DropdownSearch from '../../CommanComponents/DropdownSearch/DropdownSearch';
const CompanyDocuments = ({ children }) => {
    const companyDocuments = useSelector((e) => e.companyDocuments) // FOLDERS SELECTOR   
    const dispatch = useDispatch()
    const ref = useRef(null)
    const ref2 = useRef(null)
    const folderPopPopRef = useRef();
    const uploadFileRef = useRef()
    const reminderPopRef = useRef()
    const inputFile = useRef(null)
    const navigate = useNavigate()
    const userId = Auth?.user()?.user_id // this is for added by user
    const [activeOptionsDrop, setActiveOptionsDrop] = useDetectOutsideClick(ref, false)
    const [activeOptionsDrop2, setActiveOptionsDrop2] = useDetectOutsideClick(ref2, false)
    // const [searchVal, setSearchVal] = useState('')
    // const [image, setImage] = useState(null);

    const GridVal = Storage.alive(folder_grid_View) ? Storage.getString(folder_grid_View) : null
    // const [gridView, setGridView] = useState(GridVal !== null ? GridVal : 'grid')
    const [gridView, setGridView] = useState('list')
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [deleteType, setDeleteType] = useState(null);

    const {
        folderName, setFolderName, handleInput, saveBtnHandle,
        showError, deleteHandlerBtn, resetFolderName, renameHandlerBtn, editable,
        uploadType, setUploadType, setShowError, FolderDetailsHandler, onUploadFileChange,
        breadcrumb, folderId, setFolderId, searchVal, setSearchVal, filePrevHandler, prevRef, filePrevHandlerClose,
        prevData, deleteFileHanlder, reminderDate, setReminderDate, expiryDate, setExpiryDate, setReminderHandler,
        setReminderID, reminderFolderID, setReminderFileType, handleMouseDown, handleMouseUp,
        fileInputRef, folderInputRef,

        folderSelectClientData, selectedClientDropdown, DropdownClientChanger
    } = useCompanyDocuments(folderPopPopRef)


    useEffect(() => {
        Storage.set(folder_grid_View, gridView)
    }, [gridView])

    const handleDotsBtn = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop(prev => val === prev ? -1 : val)
    }
    const handleDotsBtn2 = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop2(prev => val === prev ? -1 : val)
    }
    const onClickHandle = () => {
        // navigate("/folder/data")
    }
    const openFolderPopup = () => {
        folderPopPopRef.current.open();
        setUploadType('create')
    }

    const closeFolderPopup = () => {
        folderPopPopRef.current.close();
    }

    const closeUploadFilePop = () => {
        uploadFileRef.current.close();
        // setImage(null);
    }
    // Debounced search function
    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(serachFolder(value, userId, folderId));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
        if (e.target.value == 0) {
            // setFolderId(null)
        }
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const handleResetSearch = () => {
        setSearchVal('')
        dispatch(searchReset(userId, folderId))
    }

    const handleDltBtn = (e, id, type) => {
        e.stopPropagation()
        setDeletePop(true);
        setDeleteId(id)
        setDeleteType(type)
    }

    const CancleDelete = () => {
        setDeletePop(false);
        setDeleteType(null)
    }

    const handlePopDeleteBtn = () => {
        setDeletePop(false);
        setDeleteType(null)
        if (deleteType == "folder") {
            deleteHandlerBtn(deleteId)
        } else if (deleteType == "file") {
            deleteFileHanlder(deleteId)
        }
        // deleteHandler(deleteId) 
    }
    // console.log(companyDocuments?.data?.companyDocuments, "asfefg")

    const openReminderPop = () => {
        reminderPopRef.current.open();
        dispatch(resetReminderError())
    }

    const closeReminderPop = () => {
        reminderPopRef.current.close();
        dispatch(resetReminderFolder())
        setReminderDate(null)
        setExpiryDate(null)
    }

    useEffect(() => {
        if (companyDocuments?.reminder?.success) {
            closeReminderPop()
        }
    }, [companyDocuments?.reminder])
    console.log(companyDocuments, "companyDocumentscompanyDocuments")

    useEffect(() => {
        if (reminderFolderID) {
            if (reminderFolderID?.reminder_date !== null) {
                setReminderDate(reminderFolderID?.reminder_date)
            }
            if (reminderFolderID?.expiry_date !== null) {
                setExpiryDate(reminderFolderID?.expiry_date)
            }
        }
    }, [reminderFolderID])
    console.log(companyDocuments, "fdrrgfdh")
    return (
        <>
            <div className="company-documents-main-wrapper">
                <div className="folder-wrap">
                    <div className="heading-search-item justify-between align-center">
                        <Breadcrumb >
                            <BreadcrumbItem title={"Company Documents"}
                                className={`section-heading primary text-sm w-500 
                            ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}
                            />
                            {companyDocuments?.data?.breadcrumbs?.map((val, key) => {
                                const disableLast = companyDocuments?.data?.breadcrumbs?.length - 1
                                return (
                                    <>
                                        <p
                                            className={`section-heading primary text-sm w-500 
                                          ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}

                                        >
                                            &nbsp;{`>`} &nbsp;</p>
                                        <BreadcrumbItem
                                            key={key}
                                            title={`${val?.name}`}
                                            className={`section-heading text-sm w-500   ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"} ${disableLast == key && "disabled"} `}
                                            to={`?folderId=${val?.id}&folderName=${val?.name}`}
                                            disabled={disableLast == key}
                                        />
                                    </>
                                )
                            })}
                        </Breadcrumb>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            {/* <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            /> */}
                        </div>
                        <div className="item-wrapper justify-between">
                            <form className=" actions-wrappper d-flex justify-between align-center " onSubmit={e => e.preventDefault()}>
                                {(companyDocuments?.data?.breadcrumbs?.length < 4 || companyDocuments?.data?.breadcrumbs == undefined) && <CommanButton onClick={(e) => { return openFolderPopup() }} ClassName={"action-btn"} >
                                    <p className='w-700'> <span className='text-xs'>  + </span> Folder</p>
                                </CommanButton>}
                                {/* {folderId !== null && */}
                                <CommanButton ClassName={"action-btn"}
                                    // onClick={() => inputFile?.current?.click()}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onTouchStart={handleMouseDown}
                                    onTouchEnd={handleMouseUp}
                                >
                                    <p className='w-700'> <span className='text-xs'>  + </span> Upload File</p>
                                    <input id="myInput"
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ height: 0, width: 0, opacity: 0, padding: 0 }}
                                        accept={`${IMG_ACCEPT},${FILE_ACCEPT}`}
                                        multiple
                                        onChange={(e) => { return onUploadFileChange(e) }}
                                    />
                                    <input
                                        ref={folderInputRef}
                                        type="file"
                                        multiple
                                        webkitdirectory="true" // This allows folder selection
                                        // onChange={handleFolderChange}
                                        style={{ display: "none" }}
                                        onChange={(e) => { return onUploadFileChange(e) }}
                                    />
                                </CommanButton>

                                {/* } */}
                            </form>
                            {/* <div className="view-changer-wrapper">
                                <div className="view-change-wrap">
                                    <button className={`transparent  icons-wrap d-flex align-center justify-center  ${gridView === 'list' && "active"}`} title='List view'
                                        onClick={() => setGridView('list')}
                                    >
                                        <i className="icon list-view"></i>
                                    </button>
                                    <button className={`transparent  icons-wrap d-flex align-center justify-center ${gridView === "grid" && "active"} `} title='Grid view'
                                        onClick={() => setGridView('grid')}
                                    >
                                        <i className="icon grid-view"></i>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {
                        <div className="folder-item-wrapper">
                            {(Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) && folderId === null && <DropdownSearch
                                data={folderSelectClientData?.map((e) => e)}
                                defaultValue={selectedClientDropdown?.business_name ? selectedClientDropdown?.business_name : selectedClientDropdown?.first_name ? selectedClientDropdown?.first_name + " " + selectedClientDropdown?.last_name : selectedClientDropdown}
                                className={'select-client-dropper mb-10'}
                                clickHandle={(val) => DropdownClientChanger(val)}
                                ObjectKey={`business_name`}
                            />}
                            {gridView === "grid" ?
                                companyDocuments?.data?.companyDocuments?.length || companyDocuments?.data?.files?.length ?
                                    <div className="grid-folder-item-wrap ">
                                        {companyDocuments?.data?.companyDocuments?.map((val, key) => {
                                            return (
                                                <div className="folder-item d-flex align-center relative" key={key} onClick={(e) => { return FolderDetailsHandler(val?.id, val?.name), e.stopPropagation }} >
                                                    <i className="folder-icon"></i>
                                                    <p className="text-xxs w-400 secondary mr-15 folder-name" onClick={() => onClickHandle()} title={val.name}> {val.name}</p>
                                                    <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn(e, key)}>
                                                        <i className='dots-icon'> </i>
                                                    </div>
                                                    {activeOptionsDrop === key && <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref}>
                                                        <div className="item d-flex align-center mb-10 pointer" onClick={(e) => renameHandlerBtn(e, val?.id)}  >
                                                            <i className='icons mr-5 sT-icon secondary i-xxs edit-icon'></i>
                                                            <p className="text-2xs w-500 secondary" >Rename</p>
                                                        </div>
                                                        <div className="item d-flex align-center mb-10 pointer" onClick={(e) => handleDltBtn(e, val?.id, 'folder')}  >
                                                            <i className='icons mr-5 sT-icon secondary i-xxs delete-icon'></i>
                                                            <p className="text-2xs w-500 secondary" >Delete</p>
                                                        </div>
                                                        {/* <p className="text-2xs w-500 secondary mb-10 pointer" ></p> */}
                                                        {/* <p className="text-2xs w-500 secondary pointer"  ></p> */}

                                                        <div className="item d-flex align-center mt-10 pointer" onClick={(e) => { return openReminderPop(), e.stopPropagation() }}>
                                                            <i className='icons mr-5 sT-icon secondary i-xxs bell-icon'></i>
                                                            <p className="text-2xs w-500 secondary" > Set reminder</p>
                                                        </div>
                                                        {/* <p className="text-2xs w-500 secondary pointer">Access</p> */}
                                                    </div>}
                                                </div>
                                            )
                                        })
                                        }
                                        {companyDocuments?.data?.files?.map((val, key) => {
                                            return (
                                                <>
                                                    <div className="folder-item file-wrapper relative" key={key}   >
                                                        <div className="file-top-wrap d-flex mb-10 align-center">
                                                            <i className="file-icon"></i>
                                                            <p className="text-xxs w-400 secondary mr-15 folder-name" > {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}</p>
                                                            <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn2(e, key)}>
                                                                <i className='dots-icon'> </i>
                                                            </div>
                                                        </div>
                                                        <div className="file-img-wrap" onDoubleClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}>
                                                            <ImageViewer
                                                                object={ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) && val.file}
                                                                defaultImage={FileIcon}
                                                                className={!ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) && 'doc-img'}
                                                            />
                                                        </div>
                                                        {
                                                            activeOptionsDrop2 === key &&
                                                            <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref2}>
                                                                <p className="text-2xs w-500 secondary mb-10 pointer"

                                                                    onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}
                                                                >
                                                                    Preview
                                                                </p>
                                                                <p className="text-2xs w-500 secondary pointer" onClick={(e) => handleDltBtn(e, val?.id, 'file')}   >Delete</p>
                                                            </div>}
                                                    </div>

                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                    : <DataNotFound />
                                :
                                <CompanyDocumentsListView deleteHandlerBtn={deleteHandlerBtn} renameHandlerBtn={renameHandlerBtn}
                                    FolderDetailsHandler={FolderDetailsHandler} filePrevHandler={filePrevHandler}
                                    deleteFileHanlder={deleteFileHanlder} openReminderPop={openReminderPop}
                                    setReminderID={setReminderID} reminderFolderID={reminderFolderID}
                                    setReminderFileType={setReminderFileType}

                                    openFolderPopup={openFolderPopup} folderId={folderId}
                                    inputFile={inputFile} IMG_ACCEPT={IMG_ACCEPT} FILE_ACCEPT={FILE_ACCEPT} onUploadFileChange={onUploadFileChange}
                                />

                            }
                        </div>
                        // : <DataNotFound />
                    }
                </div>

            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handlePopDeleteBtn()}
            />}
            <FolderModal reference={folderPopPopRef} onClose={closeFolderPopup} uploadType={uploadType}
                folderName={folderName} handleInput={handleInput} saveBtnHandle={saveBtnHandle}
                showError={showError} setShowError={setShowError}
                resetFolderName={resetFolderName}
                data={companyDocuments}
            />
            <DocumentPrev reffrence={prevRef} onClose={filePrevHandlerClose} >
                <SwiperSlide>
                    <div className="prev-img-wrap">
                        <ImageViewer object={prevData?.file} alt="Image" />
                    </div>
                </SwiperSlide>
            </DocumentPrev>
            <ReminderPopup
                reference={reminderPopRef} onClose={closeReminderPop}
                reminderDate={reminderDate} setReminderDate={setReminderDate}
                expiryDate={expiryDate} setExpiryDate={setExpiryDate} onSubmitFunc={setReminderHandler}
                error={companyDocuments?.reminder?.error}
                resetError={resetReminderError}
            />
        </>
    )
}

export default CompanyDocuments