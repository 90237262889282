export const login = "__sn_lg__"; // Login status for login user
export const userType = "__sn_utp__"; // Login status for login user
export const user_offer_type = "__sn_uST__"; // Login status for login user
export const userDetail = "__sn_ud__"; // Logged in user data
export const parentUserDetail = "__sn_udP__"; // Logged in user data
export const registerDetails = "__sn_rd__"; // Storage key for email signup data
export const folder_grid_View = "__g_f_view__"; // Storage key for folder grid view change
export const document_grid_View = "__g_d_view__"; // Storage key for document grid view change
export const rem_user_name = "__sn_uname__"; // Storage key for remembder me email
export const rem_user_pass = "__sn_upass__"; // Storage key for remembder me pass
export const noty_count = "__sn_notiCount__"; // Storage key for remembder me email
