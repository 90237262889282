import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './documentsDetails.scss';
import Dropdown from '../../../CommanComponents/Dropdown/Dropdown';
// import dummyImg from '../../../Assets/images/noimage.svg';
import dummyImg from '../../../Assets/icons/upload.svg';

import DynamicInputFileds from '../../../CommanComponents/DynamicInputFileds/DynamicInputFileds';
import useHandleDownload from '../../../CustomHooks/useHandleDownload'
import { useLocation, useParams } from 'react-router-dom';
import { useEmployeePersonalDetails } from '../../../CustomHooks/EmployeePersonalDetails/useEmployeePersonalDetails';
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';
import useDocumentDetails from '../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails';

import UploadedDocsList from './UploadedDocsList';
import { useDispatch, useSelector } from 'react-redux';
import PassportFieldsFrontSide from './DynamicdocumentFields/PassportFields/PassportFieldsFrontSide';
import PassportFieldsBackSide from './DynamicdocumentFields/PassportFields/PassportFieldsBackSide';
import ImageViewer from '../../../CommanComponents/ImageViewer/ImageViewer';
import DocumentViewer from '../../../CommanComponents/DocumentViewer/DocumentViewer';
import PDFViewer from '../../../CommanComponents/PDFViewer/usePDFViewer';
import { Document, Page, pdfjs } from 'react-pdf';
import EmiratesIDFrontSide from './DynamicdocumentFields/EmiratesID/EmiratesIDFrontSide';
import EmiratesIDBackSide from './DynamicdocumentFields/EmiratesID/EmiratesIDBackSide';
import PDF_file_icon from '../../../Assets/icons/PDF_file_icon.svg'
import { setDocType } from '../../../store/Actions/DocumentUpload';
import DeleteConfirmPop from '../../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const DocumentsDetails = ({
    activeDoc,
    DynamicInputValues,
    setDynamicInputValues,
    documentSaveBtn,
    onChangeFile,
    handleDelete,
    HandleDownload,
    handleInputChange,
    ondynamicDropdownChange,
    handleDocumentChnage,
    inputFile,
    inputFile2,
    imageRef2,
    fileName,
    fileName2,
    setFileName,
    setFileName2,
    EmployeeDocumentsList,
    imageRef,
    documentUpload,
    deleteHandler,
    DocuemntEditBtnHandler,
    handleDocumentCancelBtn,
    showDocError,
    PDFImg,
    PDFImg2, setPDFImg,
    setPDFImg2,
    emiratesIDFileds,
    setEmiratesIDFileds,
    regexError,
    handleDateInput, CancleDeleteDoc, deletePopDoc, deleteDocType, setDeleteDocType, handleDeleteBtnPop
}) => {
    // const [activeEdit, editBtnHandler, setActiveEdit, handleEditSubmit, dateValue, setDateValue, type] = useEmployeePersonalDetails();
    // const [activeEdit, editBtnHandler, setActiveEdit, handlePersonalDetailsBtn,
    //     dobValue, setDobValue, type, setType, joiningDate, setJoiningDate,
    //     employeeData, handleInput, handleGenderChange, handlePhoneInput, showError,
    //     formRef, resetEmployeeData, personalDetails, singleEmployee
    // ] = useEmployeePersonalDetails();
    const personalDetails = useSelector((state) => state.personalDetails)
    const employeeManagement = useSelector((state) => state.employeeManagement)



    // const { DynamicInputValues, setDynamicInputValues, documentSaveBtn,
    //     onChangeFile, handleDelete, HandleDownload, handleInputChange,
    //     ondynamicDropdownChange, inputFile,
    //     inputFile2,
    //     imageRef2,
    //     fileName,
    //     fileName2, setFileName,
    //     setFileName2, imageRef, deleteHandler, DocuemntEditBtnHandler
    //     , handleDocumentCancelBtn, showDocError, PDFImg, PDFImg2, emiratesIDFileds, setEmiratesIDFileds } = useDocumentDetails(onActiveDocChange)
    const [defaultPDF1, setDefaultPDF1] = useState(false)
    const [defaultPDF2, setDefaultPDF2] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (documentUpload?.set_update && documentUpload?.document?.front_document) {
            const splitData = documentUpload?.document?.front_document.split('/')
            setFileName(splitData[splitData.length - 1])
        }
        if (documentUpload?.set_update && documentUpload?.document?.back_document) {
            const splitData = documentUpload?.document?.back_document.split('/')
            setFileName2(splitData[splitData.length - 1])
        }
    }, [documentUpload])

    const DropdownDisableList = documentUpload?.data?.map(doc => doc.Document_type)
    const imageExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'webp', ''];
    const fileExtension1 = fileName.split('.').pop();
    const fileExtension2 = fileName2.split('.').pop();

    // https://morth.nic.in/sites/default/files/dd12-13_0.pdf 
    useLayoutEffect(() => {
        setPDFImg(null)
        setPDFImg2(null)
    }, [documentUpload?.set_update])

    const location = useLocation();

    useEffect(() => {
        if (location?.pathname?.includes("/employees-management")) {
            // dispatch(resetPassportAnalyzer())
        }
    }, [])



    return (
        <div className="employee-documents-details-main-wrapper">

            <div className="employee-documents-wrapper">
                <>
                    {activeDoc !== 'Add document*' ?
                        <>
                            <div className="document-item-wrapper">
                                <div className="uploaded-document-item">
                                    <h4 className="text-ss w-500 mb-5 secondary heading-para"> {activeDoc} - frontside</h4>
                                    <div className={`uploaded-doc-img-wrapper`}>
                                        <div className="img-wrapper">
                                            {/* <div className={`img-wrap ${fileName.length || documentUpload?.set_update && documentUpload?.document?.front_document ? "" : "default-show"}`}> */}
                                            <div className={`img-wrap ${fileName.length || documentUpload?.set_update && documentUpload?.document?.front_document ? "" : "default-show"}`}>
                                                {/* <img src={dummyImg} ref={imageRef} className='document-img' alt="Document Image" /> */}
                                                <ImageViewer object={documentUpload?.set_update ? documentUpload?.document?.front_document : ''} defaultImage={dummyImg} ref={imageRef} alt="Document Image" className={`document-img ${imageExtensions.includes(fileExtension1) ? 'uploaded-img' : "uploaded-docs"} `} />
                                                {
                                                    // documentUpload?.set_update && !imageExtensions.includes(fileExtension1)
                                                    !imageExtensions.includes(fileExtension1)
                                                        ?
                                                        PDFImg2 ?
                                                            <>
                                                                <img src={PDFImg2} alt="pdf preview"
                                                                    className={`document-img ${fileName.length || documentUpload?.document?.front_document ? "" : "dummy-img"}  `}
                                                                />
                                                                {console.log("into pdf2")}
                                                            </>
                                                            :
                                                            defaultPDF1 ?
                                                                <div className="default-pdf-wrap">
                                                                    <ImageViewer defaultImage={PDF_file_icon} alt="Document Image" className={`document-img ${!imageExtensions.includes(fileExtension1) ? 'uploaded-img' : "uploaded-docs"} default-pdf `} />
                                                                </div> :
                                                                <Document file={process.env.REACT_APP_BACKEND_URL + documentUpload?.document?.front_document}
                                                                    onLoadError={() => setDefaultPDF1(true)}
                                                                    onLoadSuccess={() => setDefaultPDF1(false)}
                                                                >
                                                                    <Page pageNumber={1} />
                                                                </Document>
                                                        : ''
                                                    // onLoadSuccess={() => console.log('Document loaded successfully')} // Optional: Log when the document is loaded successfully
                                                    // onLoadError={(error) => console.error('Error loading document:', error)} // Optional: Log the error when document fails to load
                                                }
                                                {/* // {defaultPDF1 && } */}
                                                <div className="overlay d-flex justify-center cursor" onClick={() => inputFile.current.click()}>
                                                    <button className="icons-wrap transparent ">
                                                        <i className="icons upload-icon"></i>
                                                        <p className="text-ss w-500 mb-5 secondary upload-para">Upload frontside of your document.<br /> It should be .jpg, .jpeg, .png, .webp, .pdf files.</p>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img-actions-wrap mt-25 d-flex justify-between ">
                                                <p className="img-name text-xxs w-500 secondary">
                                                    {fileName || documentUpload?.set_update && documentUpload?.document?.front_document ? fileName || documentUpload?.document?.front_document.split('/') : "your-file-name.jpeg"}
                                                </p>
                                                <div className="action-wrap d-flex">
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName !== "" || employeeManagement?.client?.access_level == "view"} onClick={() => inputFile.current.click()}> <i className="icons upload-icon"></i></button>
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName === "" || employeeManagement?.client?.access_level == "view"} onClick={() => { (documentUpload?.document?.front_document) ? setDeleteDocType('front_document') : handleDeleteBtnPop(inputFile, imageRef) }}> <i className="icons delete-icon"></i></button>
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName === ""} onClick={() => HandleDownload(imageRef, fileName)}> <i className="icons download-icon"></i></button>
                                                    <input id="myInput"
                                                        type="file"
                                                        ref={inputFile}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => onChangeFile(e, imageRef)}
                                                        accept='image/*,.pdf,'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`uploaded-doc-fields ${employeeManagement?.client?.access_level == "view" && "view-tc-upload-wrapper"}`}>

                                        <div className="form-wrapper">
                                            <form className="form" >
                                                <div className="form-control">
                                                    {/* <DynamicInputFileds
                                                        WrapperClassName={"document-dyanmic-fields"}
                                                        InputClassName={"details-InputClassName"}
                                                        activeDoc={activeDoc}
                                                        sideShow={"front"}
                                                        inputValue={DynamicInputValues}
                                                        onInputChange={(field, value) => handleInputChange(field, value)}
                                                        onDropdownChange={(field, value) => ondynamicDropdownChange(field, value)}
                                                        reset={() => setDynamicInputValues({})}
                                                    /> */}
                                                    {
                                                        activeDoc === 'Passport' ?
                                                            <PassportFieldsFrontSide
                                                                DynamicInputValues={DynamicInputValues}
                                                                handleInputChange={(e) => handleInputChange(e, DynamicInputValues, setDynamicInputValues)}
                                                                ondynamicDropdownChange={ondynamicDropdownChange}
                                                                setDynamicInputValues={setDynamicInputValues}
                                                                showError={showDocError}
                                                                handleDateInput={handleDateInput}
                                                            /> :
                                                            activeDoc === 'Emirates ID' ?
                                                                <EmiratesIDFrontSide
                                                                    emiratesIDFileds={emiratesIDFileds}
                                                                    setEmiratesIDFileds={setEmiratesIDFileds}
                                                                    handleInputChange={(e) => handleInputChange(e, emiratesIDFileds, setEmiratesIDFileds)}
                                                                    ondynamicDropdownChange={ondynamicDropdownChange}
                                                                    showError={showDocError}
                                                                    regexError={regexError}
                                                                    handleDateInput={handleDateInput}
                                                                />
                                                                :
                                                                ""
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="uploaded-document-item">
                                    <h4 className="text-ss w-500 mb-5 secondary heading-para"> {activeDoc} - backside </h4>
                                    <div className="uploaded-doc-img-wrapper">
                                        <div className="img-wrapper">
                                            <div className={`img-wrap ${fileName2.length || documentUpload?.set_update && documentUpload?.document?.back_document ? "" : "default-show"}`}>
                                                {/* <img src={dummyImg} ref={imageRef2} className={`document-img ${fileName2.length ? "" : "dummy-img"}`} alt="Document Image" /> */}
                                                <ImageViewer
                                                    object={documentUpload?.document?.back_document}
                                                    defaultImage={dummyImg} ref={imageRef2} alt="Document Image"
                                                    className={`document-img ${fileName2.length || documentUpload?.document?.back_document ? "" : "dummy-img"}  ${imageExtensions.includes(fileExtension2) ? 'uploaded-img' : "uploaded-docs"} `} />

                                                {
                                                    // documentUpload?.set_update && !imageExtensions.includes(fileExtension2)
                                                    !imageExtensions.includes(fileExtension2)
                                                        ?
                                                        PDFImg ?
                                                            <img src={PDFImg} alt="pdf preview"
                                                                className={`document-img ${fileName2.length || documentUpload?.document?.back_document ? "" : "dummy-img"}  `}
                                                            />
                                                            :
                                                            defaultPDF2 ?
                                                                <div className="default-pdf-wrap">
                                                                    <ImageViewer defaultImage={PDF_file_icon} alt="Document Image" className={`document-img ${!imageExtensions.includes(fileExtension2) ? 'uploaded-img' : "uploaded-docs"} default-pdf `} />
                                                                </div> :
                                                                <Document file={process.env.REACT_APP_BACKEND_URL + documentUpload?.document?.back_document}
                                                                    onLoadError={() => setDefaultPDF2(true)}
                                                                    onLoadSuccess={() => setDefaultPDF2(false)}
                                                                >

                                                                    <Page pageNumber={1} />
                                                                </Document>
                                                        : ''
                                                }


                                                {/* Embeded code for all dpoc type preview  */}
                                                {/* <embed src={imageRef2?.current?.src || process.env.REACT_APP_BACKEND_URL + documentUpload?.document?.back_document} style={{ "height": "100%", width: "100%" }} /> */}


                                                <div className={`overlay d-flex justify-center cursor ${employeeManagement?.client?.access_level == "view" && "overlay-view"}`} onClick={() => inputFile2.current.click()}>
                                                    <button className="icons-wrap transparent ">
                                                        <i className="icons upload-icon"></i>
                                                        <p className="text-ss w-500 mb-5 secondary upload-para">Upload backside of your document . <br /> It should be .jpg, .jpeg, .png, .webp, .pdf files.</p>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img-actions-wrap mt-25 d-flex justify-between ">
                                                <p className="img-name text-xxs w-500 secondary">
                                                    {fileName2 || documentUpload?.set_update && documentUpload?.document?.back_document ? fileName2 || documentUpload?.document?.back_document.split('/') : "your-file-name.jpeg"}
                                                </p>
                                                <div className="action-wrap d-flex">
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName2 !== "" || employeeManagement?.client?.access_level == "view"} onClick={() => inputFile2.current.click()}> <i className="icons upload-icon"></i></button>
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName2 === "" || employeeManagement?.client?.access_level == "view"} onClick={() => { (documentUpload?.document?.back_document) ? setDeleteDocType('back_document') : handleDeleteBtnPop(inputFile2, imageRef2) }}> <i className="icons delete-icon"></i></button>
                                                    <button className="icons-wrap transparent d-flex align-center justify-center" disabled={fileName2 === ""} onClick={() => HandleDownload(imageRef2, fileName2)}> <i className="icons download-icon"></i></button>
                                                    <input id="myInput"
                                                        type="file"
                                                        ref={inputFile2}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => onChangeFile(e, imageRef2)}
                                                        accept='image/*,.pdf,'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`uploaded-doc-fields ${employeeManagement?.client?.access_level == "view" && "view-tc-upload-wrapper"}`}>
                                        <div className="form-wrapper">
                                            <form className="form" >
                                                <div className="form-control">
                                                    {/* <DynamicInputFileds
                                                        WrapperClassName={"document-dyanmic-fields"}
                                                        activeDoc={activeDoc}
                                                        sideShow={"back"}
                                                        inputValue={DynamicInputValues}
                                                        onInputChange={(field, value) => handleInputChange(field, value)}
                                                        onDropdownChange={(field, value) => ondynamicDropdownChange(field, value)}
                                                        reset={() => setDynamicInputValues({})}
                                                    /> */}
                                                    {activeDoc === 'Passport' ? <PassportFieldsBackSide
                                                        DynamicInputValues={DynamicInputValues}
                                                        handleInputChange={(e) => handleInputChange(e, DynamicInputValues, setDynamicInputValues)}
                                                        showError={showDocError}

                                                    /> :
                                                        activeDoc === 'Emirates ID' ?
                                                            <EmiratesIDBackSide
                                                                emiratesIDFileds={emiratesIDFileds}
                                                                handleInputChange={(e) => handleInputChange(e, emiratesIDFileds, setEmiratesIDFileds)}
                                                                ondynamicDropdownChange={ondynamicDropdownChange}
                                                                showError={showDocError}
                                                                regexError={regexError}
                                                            />
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="save-btn-wrap d-flex mt-40 mb-30 ">
                                <CommanButton Text={'Save'} ClassName={`save-btn ${employeeManagement?.client?.access_level == "view" && "view-tc-btn"}`} onClick={() => documentSaveBtn(activeDoc, inputFile.current.files[0], inputFile2.current.files[0])} />
                                <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => handleDocumentCancelBtn()} />
                            </div>
                        </>
                        : ""
                    }
                </>
            </div>
            {deleteDocType && <DeleteConfirmPop
                handleCancelVar={CancleDeleteDoc}
                deleteVarHandler={() =>
                    handleDeleteBtnPop(
                        deleteDocType === "front_document" ? inputFile : inputFile2,
                        deleteDocType === "front_document" ? imageRef : imageRef2
                    )
                }
            />}
            {activeDoc == 'Add document*'
                &&
                <UploadedDocsList editHandlerFunc={DocuemntEditBtnHandler} />
            }
        </div>
    )
}

export default memo(DocumentsDetails)