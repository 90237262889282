import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Validation from '../../Classes/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorReset, createFolderReset, createNewFolder, deleteFiles, deleteFolder, getFolderDetail, getFoldersList, renameFolder, renameFolderSet, renameReset, resetFolderDetail, resetFolderList, setBreadcrumb, setFolderClient, uploadFile } from '../../store/Actions/Folders';
import Auth from '../../Classes/Auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compressFile } from '../../HelperFunction/FileCompressor';
import { FILE_ACCEPT, IMG_ACCEPT } from '../../Constant/Constants';
import { ALLOW_IMAGE_EXTENSIONS } from '../../config';
import { getClientsList } from '../../store/Actions/Clients';
const useFolders = (folderPopPopRef) => {
    const folders = useSelector((e) => e.folders) // FOLDERS SELECTOR  
    const clients = useSelector(e => e.clients)
    const userId = Auth?.user()?.user_id // this is for added by user 
    const valid = new Validation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false)
    const [editable, setEditabel] = useState(false)
    const [uploadType, setUploadType] = useState('create')
    const [renameId, setRenameId] = useState('')
    // const [breadcrumb, setBreadcrumb] = useState('')
    const [folderId, setFolderId] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const prevRef = useRef(null)
    const [prevData, setPrevData] = useState(null)
    // const employeeManagement = useSelector((state) => state.employeeManagement)
    const selectedClientDropdown = folders?.client
    // const { folderId } = useParams()
    const location = useLocation()
    const searchParamsString = location.search.replace(/\?/g, '&');
    const params = new URLSearchParams(searchParamsString);

    useEffect(() => {
        setFolderId(params.get('folderId'));
        dispatch(setBreadcrumb(params.get('folderName')))
    }, [location, searchVal])

    console.log(folderId, "folderIdfolderId")

    const [folderName, setFolderName] = useState({
        name: {
            value: "",
            isValid: false,
        },
        validation: false,
    })
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "name":
                return valid.isNotEmpty(inputValue);

            default:
                return false;
        }
    };

    const isFormValid = () => {
        return folderName.name.isValid ? true : false;
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...folderName,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setFolderName(data);
        dispatch(ErrorReset())
    };

    // SAVE BTN HANDLE
    const saveBtnHandle = () => {
        if (isFormValid()) {
            const data = {
                "name": folderName?.name?.value,
                "added_by_user_id": userId,
                "parent": folderId
            }
            if (uploadType == 'create') {
                dispatch(createNewFolder(data))
            } else if (uploadType == 'rename' || uploadType == 'fileRename') {
                // dispatch(renameFolder(renameId, data))

                // Modify the data object before dispatching
                const renamedData = {
                    ...data,
                    "new_name": data.name, // Replace 'name' with 'new_name'
                    "name": undefined // Remove 'name' key from the object
                };
                dispatch(renameFolder(renameId, renamedData, folderId, uploadType));

            }
        } else {
            setShowError(true)
        }
    }

    // DELETE BTN HANDLER
    const deleteHandlerBtn = (id) => {
        dispatch(deleteFolder(id, userId, folderId))
    }


    // GET FOLDER LIST
    // useEffect(() => {
    //     dispatch(getFoldersList())
    // }, [dispatch, folders?.created])


    // RENAME FOLDER NAME
    function binarySearch(arr, id) {
        let low = 0;
        let high = arr.length - 1;

        while (low <= high) {
            let mid = Math.floor((low + high) / 2);
            let midId = arr[mid]?.id;

            if (midId === id) {
                return arr[mid]; // Found the item
            } else if (midId < id) {
                low = mid + 1; // Continue searching in the right half
            } else {
                high = mid - 1; // Continue searching in the left half
            }
        }

        return null; // Item not found
    }
    const renameHandlerBtn = (e, id, type) => {
        e.stopPropagation()
        if (type == "file") {
            setUploadType('fileRename')
        } else {
            setUploadType('rename')
        }
        setRenameId(id)
        folderPopPopRef.current.open();
        // const foundItem = folders?.data.find(item => item?.id == id);
        let foundItem;
        if (type == "file") {
            foundItem = binarySearch(folders?.data?.files, id);
        } else {
            foundItem = binarySearch(folders?.data?.folders, id);
        }

        if (foundItem) {
            setFolderName({
                name: {
                    value: foundItem?.name,
                    isValid: true,
                },
                validation: true,
            });
        }
    }


    // FOLDER DETAIL HANDLER
    const FolderDetailsHandler = (id, name) => {
        // setBreadcrumb(name)
        dispatch(setBreadcrumb(name))
        navigate(`/folders?folderId=${id}&folderName=${name}`)
        dispatch(getFolderDetail(id))
    }

    // UPLOAD FILE INTEGRATION 
    function onUploadFileChange(e) {
        const file = e.target.files[0];
        console.log(file?.type, FILE_ACCEPT.includes(file?.type), "ascsafsadf")
        const maxSize = 500 * 1024 * 1024; // 2 MB in bytes 
        if (IMG_ACCEPT.includes(file?.type) || FILE_ACCEPT.includes(file?.type)) {
            if (file && file.size <= maxSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    // setImage(e.target.result);
                };

                reader.readAsDataURL(file);

                const formData = new FormData();

                const data = {
                    folder_id: folderId,
                    added_by_user_id: userId
                }

                Object.entries(data).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                // formData.append("file", file)
                // dispatch(uploadFile(formData, folderId));
                compressFile(file)
                    .then((compressedFile) => {
                        formData.append("file", compressedFile, file.name); // Append the compressed file 
                        dispatch(uploadFile(formData, folderId));
                    })
                    .catch((err) => {
                        console.error("File compression error:", err);
                        // Handle the error appropriately, maybe show an error message to the user
                    });


                // compressFile(file)
                //     .then((compressedFile) => {
                //         const formData = new FormData();
                //         formData.append("folder_id", folderId);
                //         formData.append("added_by_user_id", userId);
                //         formData.append("file", compressedFile, file.name); // Append the compressed file 

                //         dispatch(uploadFile(formData, folderId));
                //     })
                //     .catch((err) => {
                //         console.error("File compression error:", err);
                //         // Handle the error appropriately, maybe show an error message to the user
                //     });

                // Clear the file input
                e.target.value = null;
            } else {
                alert('File size exceeds the limit of 500 MB');
            }

        } else {
            alert('Invalid Format! Accept only images, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt');
        }
    }


    // useEffect(() => {
    //     if (folder_ID == 'all') {
    //         dispatch(getFoldersList())
    //         dispatch(resetFolderDetail())
    //         setBreadcrumb('')
    //     }
    // }, [folder_ID])


    useEffect(() => {
        if (folderId == null && params.get('folderId') == null) {
            dispatch(getClientsList(userId))
            dispatch(getFoldersList(userId, selectedClientDropdown?.id == undefined ? null : selectedClientDropdown?.id))
            dispatch(resetFolderDetail())

            // setBreadcrumb('')
            dispatch(setBreadcrumb(''))
        } else { }
        if (folderId == null && folders?.created) {
            dispatch(getClientsList(userId))
            dispatch(getFoldersList(userId, selectedClientDropdown?.id == undefined ? null : selectedClientDropdown?.id))
        }
        if (folderId !== null) {
            dispatch(getFolderDetail(folderId))
        }
    }, [folderId, folders?.created, selectedClientDropdown])
    console.log(selectedClientDropdown, "sdfdshgfh")
    useEffect(() => {
        const folder_id_param = params.get('folderId')
        if (folder_id_param !== null) {
            dispatch(getFolderDetail(folder_id_param))
        }
        // setBreadcrumb(folders?.data[0]?.name)
    }, [])
    useEffect(() => {
        if (params.get('folderId') !== null) {
            // setBreadcrumb(folders?.data[0]?.name)
        } else {
            // setBreadcrumb('')
        }
    }, [folders])


    // RESET FOLDER NAME 
    useEffect(() => {
        if (folders?.created || folders?.rename) {
            dispatch(createFolderReset())
            dispatch(renameReset())
            setFolderName({
                name: {
                    value: "",
                    isValid: false,
                },
                validation: false,
            })
        }
    }, [folders])


    const resetFolderName = () => {
        dispatch(ErrorReset())
        setFolderName({
            name: {
                value: "",
                isValid: false,
            },
            validation: false,
        });
    };
    useLayoutEffect(() => {
        if (location?.pathname.includes('folders')) {
            dispatch(resetFolderList())
        }
    }, [])

    const filePrevHandler = (data) => {
        prevRef.current.open();
        setPrevData(data)
        console.log(data, "sfsadvfc")
    }
    const filePrevHandlerClose = () => {
        prevRef.current.close();
    }

    const deleteFileHanlder = (id) => {
        dispatch(deleteFiles(id, userId, folderId))
    }


    // Create a new array 'data2' by mapping over the original 'data' array
    const folderSelectClientData = clients.data.map(item => {
        // Check if 'business_name' is an empty string and both 'first_name' and 'last_name' are not empty
        if (item.business_name === "" && item.first_name && item.last_name) {
            // Return a new object with all properties of the original item, but with 'business_name' set to 'first_name' + 'last_name'
            return {
                ...item,
                business_name: item.first_name + " " + item.last_name
            };
        }
        // If the condition is not met, return the item as is
        return item;
    });

    const DropdownClientChanger = (val) => {
        dispatch(setFolderClient(val))
    }

    console.log(selectedClientDropdown, "SDAcsvc")

    return {
        folderName, setFolderName, handleInput, saveBtnHandle,
        showError, deleteHandlerBtn, resetFolderName, renameHandlerBtn, editable,
        uploadType, setUploadType, setShowError, FolderDetailsHandler, onUploadFileChange,
        setBreadcrumb, folderId, setFolderId, searchVal, setSearchVal, filePrevHandler, prevRef, filePrevHandlerClose,
        prevData, deleteFileHanlder,

        folderSelectClientData, selectedClientDropdown, DropdownClientChanger
    }
}

export default useFolders