import AiChatActions from "../Actions/AiChat/actionType"


const initState = {
    loading: false,
    success: false,
    error: false,
    data: '',
}

const aiChat = (state = initState, { type, payload }) => {
    switch (type) {
        case AiChatActions.AI_CHAT_LOADING: return {
            ...state,
            loading: true,
            success: false,
            error: false,
            data: '',
        }
        case AiChatActions.AI_CHAT_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: false,
            data: payload,
        }
        case AiChatActions.AI_CHAT_ERROR: return {
            ...state,
            loading: false,
            success: false,
            error: true,
            data: payload,
        }
        case AiChatActions.AI_CHAT_RESET: return {
            ...state,
            loading: false,
            success: false,
            error: false,
            data: '',
        }
        default: return state
    }
}

export default aiChat