import Request from "../../../Classes/Requests";

class contactUsReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            contact_snapit: super.url("/contact-snapit "),
        }
    }
}
export default new contactUsReq();
