import ConatctUsActions from "../Actions/ContactUs/actionType"


const initState = {
    landingPage: {
        loading: false,
        success: false,
        error: false,
        data: []
    }
}

const contactUs = (state = initState, { type, payload }) => {
    switch (type) {
        case ConatctUsActions.CONTACT_US_SUCCESS: return {
            ...state,
            landingPage: {
                ...state.landingPage,
                success: true
            }
        }
        case ConatctUsActions.CONTACT_US_RESET: return {
            ...state,
            landingPage: {
                ...state.landingPage,
                success: false
            }
        }
        default: return state
    }
}

export default contactUs