import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useEmployeesManagement } from '../EmployeesManagement/useEmployeesManagement';
import { getListOfDocument, resetUplodedDocs } from '../../store/Actions/DocumentUpload';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../Classes/Auth';
import { employeeSearch, resetEmployeeSearch } from '../../store/Actions/EmployeeManagement';
import { actionHandlerTypingCenter, checkTcExist, deleteAccess, getAccessUsers, getCompanyFolderAndFilesAccessData, getFolderAndFilesAccessData, getViewAccessDeatils, resetGrantBoError, resetSendAccessBoToTc, resetUpdateAccessBO_TC, resetViewAccessDeatils, revokeAccess, setSendAccessBoToTc, updateAccessBO_TC } from '../../store/Actions/AccessManagement';
import ValidationFile from '../../Classes/ValidationFile';

const useAccessModal = (onClose) => {
    const [employeeManagement] = useEmployeesManagement()
    const documentUpload = useSelector((e) => e.documentUpload)
    const accessManagement = useSelector(e => e.accessManagement)
    const folders = useSelector((e) => e.folders)
    const dispatch = useDispatch()
    const [currentStep, setCurrentStep] = useState(1);
    const [accessOf, setAccessOf] = useState("Employees")
    const [showDocs, setShowDocs] = useState(false)
    const userId = Auth?.user()?.user_id // this is for added by user
    const [searchValModal, setSearchVal] = useState('')
    const [selectAll, setSelectAll] = useState('')
    const [selectAllDoc, setSelectAllDoc] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const selectedClient = employeeManagement?.client;
    const [updateId, setUpdatedId] = useState(null)
    const selectAllEmpRef = useRef()
    const selectAll_docRef = useRef()
    const [accessBOEmail, setAccessBOEmail] = useState({
        email: {
            value: "",
            isValid: false,
        },
        permission: "Editor",
        validation: false,
    })
    const [acessBOError, setAcessBOError] = useState(false)

    const [formData, setFormData] = useState(
        {
            employee: {},
            email: "",
            permission: "",
            folders: "",
            files: "",
            company_folders: "",
            company_files: ""

        }
    );


    const nextStep = () => setCurrentStep(currentStep + 1);
    const previousStep = () => setCurrentStep(currentStep - 1);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, Number(value), type, checked, "handleInputChange123")
        // Check if Select All was previously selected
        const isSelectAllChecked = formData?.employee?.selected_employees?.length === employeeManagement?.data?.length;
        console.log(isSelectAllChecked, "isSelectAllChecked")
        // if (isSelectAllChecked) {
        setFormData({
            ...formData,
            employee: {}
        });

        // }
        if (type === 'checkbox') {
            if (name === 'selected_employees') {
                if (checked) {
                    setSelectedEmployee(null)
                    setShowDocs(true)

                    setFormData({
                        ...formData,
                        employee: {
                            ...formData?.employee,
                            selected_employees: [
                                ...(formData?.employee?.selected_employees || []),
                                {
                                    emp_id: Number(value),
                                    doc_type: []
                                }
                            ]
                        }
                    });
                    // dispatch(getListOfDocument(value))
                } else {
                    setSelectedEmployee(null)
                    setSelectAll("")
                    setShowDocs(false)
                    if (selectAllEmpRef.current.checked) {
                        selectAllEmpRef.current.checked = false;
                    }
                    setFormData({
                        ...formData,
                        employee: {
                            ...formData?.employee,
                            selected_employees:
                                formData?.employee?.selected_employees.filter((item) => item?.emp_id !== Number(value))
                        }
                    });
                    dispatch(resetUplodedDocs())
                }
            }
            if (name === 'selected_document') {
                const lastEmployeeIndex = formData.employee.selected_employees.length - 1;
                const lastEmployeeObj = formData.employee.selected_employees[lastEmployeeIndex];

                if (selectAll !== "select_all_emp") {
                    if (checked) {
                        setFormData({
                            ...formData,
                            employee: {
                                ...formData?.employee,
                                selected_employees: formData.employee.selected_employees.map(emp => {
                                    if (selectedEmployee !== null && selectedEmployee != undefined) {
                                        if (emp.emp_id === selectedEmployee.emp_id) {
                                            const updatedEmployee = { ...emp, doc_type: [...(emp.doc_type || []), value] };
                                            setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                            return updatedEmployee;
                                        } else {
                                            return emp;
                                        }
                                    } else {
                                        if (emp.emp_id === lastEmployeeObj.emp_id) {
                                            const updatedEmployee = { ...emp, doc_type: [...(emp.doc_type || []), value] };
                                            setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                            return updatedEmployee;
                                        } else {
                                            return emp
                                        }
                                    }
                                })
                            }
                        });
                    } else {
                        setSelectAllDoc("")
                        setFormData({
                            ...formData,
                            employee: {
                                ...formData?.employee,
                                // selected_employees: formData.employee.selected_employees.map(emp => {
                                //     if (selectedEmployee !== null && emp.emp_id === selectedEmployee?.emp_id) {
                                //         const updatedEmployee = { ...emp, doc_type: emp.doc_type.filter(doc => doc !== value) };
                                //         setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                //         return updatedEmployee;
                                //     } else {
                                //         return emp;
                                //     }
                                // })
                                selected_employees: formData.employee.selected_employees.map(emp => {
                                    if (selectedEmployee !== null && selectedEmployee != undefined) {
                                        if (emp.emp_id === selectedEmployee.emp_id) {
                                            const updatedEmployee = { ...emp, doc_type: emp.doc_type.filter(doc => doc !== value) };
                                            setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                            return updatedEmployee;
                                        } else {
                                            return emp;
                                        }
                                    } else {
                                        if (emp.emp_id === lastEmployeeObj.emp_id) {
                                            const updatedEmployee = { ...emp, doc_type: emp.doc_type.filter(doc => doc !== value) };
                                            setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                            return updatedEmployee;
                                        } else {
                                            return emp
                                        }
                                    }
                                })
                            }
                        });
                    }
                } else {
                    if (checked) {
                        setFormData({
                            ...formData,
                            employee: {
                                ...formData?.employee,
                                selected_employees: formData?.employee?.selected_employees.map(emp => ({
                                    ...emp,
                                    doc_type: [...(emp.doc_type || []), value]
                                }))
                            }
                        });
                    } else {
                        if (selectAll_docRef.current.checked) {
                            selectAll_docRef.current.checked = false;
                        }
                        setFormData({
                            ...formData,
                            employee: {
                                ...formData?.employee,
                                selected_employees: formData?.employee?.selected_employees.map(emp => ({
                                    ...emp,
                                    doc_type: emp.doc_type.filter(doc => doc !== value)
                                }))
                            }
                        });
                    }
                }


            }

        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleFolderInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, value, type, checked, "Afdsdgdtf")
        if (type === 'checkbox') {
            if (JSON.parse(name)?._type === 'Folder') {
                if (checked) {
                    setFormData({
                        ...formData,
                        folders: {
                            ...formData?.folders,
                            selected_folders: [
                                ...(formData?.folders?.selected_folders || []),
                                JSON.parse(value)
                            ]
                        }
                    }
                    )
                } else {
                    setFormData({
                        ...formData,
                        folders: {
                            ...formData?.folders,
                            selected_folders: [
                                ...formData?.folders?.selected_folders.filter(folder => folder.id !== JSON.parse(value)?.id),
                            ]
                        }
                    });
                }
            } else if (JSON.parse(name)?._type === 'File') {
                if (checked) {
                    setFormData({
                        ...formData,
                        files: {
                            ...formData?.files,
                            selected_files: [
                                ...(formData?.files?.selected_files || []),
                                JSON.parse(value)
                            ]
                        }
                    }
                    )
                } else {
                    setFormData({
                        ...formData,
                        files: {
                            ...formData?.files,
                            selected_files: [
                                ...formData?.files?.selected_files.filter(folder => folder.id !== JSON.parse(value)?.id),
                            ]
                        }
                    });
                }
            }
        }
    }
    const handleComapanyDocInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, value, type, checked, "Afdsdgdtf")
        if (type === 'checkbox') {
            if (JSON.parse(name)?._type === 'CompanyFolder') {
                if (checked) {
                    setFormData({
                        ...formData,
                        company_folders: {
                            ...formData?.company_folders,
                            selected_company_folders: [
                                ...(formData?.company_folders?.selected_company_folders || []),
                                JSON.parse(value)
                            ]
                        }
                    }
                    )
                } else {
                    setFormData({
                        ...formData,
                        company_folders: {
                            ...formData?.company_folders,
                            selected_company_folders: [
                                ...formData?.company_folders?.selected_company_folders.filter(folder => folder.id !== JSON.parse(value)?.id),
                            ]
                        }
                    });
                }
            } else if (JSON.parse(name)?._type === 'CompanyFile') {
                if (checked) {
                    setFormData({
                        ...formData,
                        company_files: {
                            ...formData?.company_files,
                            selected_company_files: [
                                ...(formData?.company_files?.selected_company_files || []),
                                JSON.parse(value)
                            ]
                        }
                    }
                    )
                } else {
                    setFormData({
                        ...formData,
                        company_files: {
                            ...formData?.company_files,
                            selected_company_files: [
                                ...formData?.company_files?.selected_company_files.filter(folder => folder.id !== JSON.parse(value)?.id),
                            ]
                        }
                    });
                }
            }
        }
    }

    console.log(formData, "formDatafolders")


    useEffect(() => {
        setFormData({
            ...formData,
            email: accessBOEmail?.email?.value,
            permission: accessBOEmail?.permission,
        })
    }, [accessBOEmail])


    // Debounced search function
    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(employeeSearch(userId, value));
        }, 400);
    }, [dispatch]);

    const handleSearchModal = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    // const handleSearchModal = (e) => { 
    //     dispatch(employeeSearch(e.target.value))
    // }
    const handleResetSearchModal = () => {
        setSearchVal('')
        dispatch(resetEmployeeSearch(selectedClient?.id))
    }

    // Select ALL check handler 
    const selectAllHandler = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, checked, "SADvsdfbgrfdv")
        if (name === "select_all_emp") {
            if (checked) {
                setSelectAll("select_all_emp")
                setSelectedEmployee(null)
                console.log(selectedEmployee, "fsdgsgh")
                setFormData({
                    ...formData,
                    employee: {
                        ...formData?.employee,
                        selected_employees: employeeManagement.data.map(emp => ({
                            emp_id: emp.employee_id,
                            doc_type: []
                        }))
                    }
                });
            } else {
                setSelectAll("")
                setFormData({
                    ...formData,
                    employee: {
                        ...formData?.employee,
                        selected_employees: []
                    }
                });
            }
        } else if (name === "select_all_doc") {
            if (checked) {
                setSelectAllDoc("select_all_doc")
                let updatedSelectedEmployees = formData?.employee?.selected_employees.map(emp => ({
                    ...emp,
                    doc_type: ['passport', 'emirates_id']
                }));
                if (selectAll === "select_all_emp") {
                    setFormData({
                        ...formData,
                        employee: {
                            ...formData?.employee,
                            selected_employees: updatedSelectedEmployees
                        }
                    });
                } else {
                    const lastEmployeeIndex = formData.employee.selected_employees.length - 1;
                    const lastEmployeeObj = formData.employee.selected_employees[lastEmployeeIndex];
                    setFormData({
                        ...formData,
                        employee: {
                            ...formData?.employee,
                            selected_employees: formData.employee.selected_employees.map(emp => {
                                if (selectedEmployee !== null) {
                                    if (emp.emp_id === selectedEmployee.emp_id) {
                                        const updatedEmployee = { ...emp, doc_type: ['passport', 'emirates_id'] };
                                        setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                        return updatedEmployee;
                                    } else {
                                        return emp;
                                    }
                                } else {
                                    if (emp.emp_id === lastEmployeeObj.emp_id) {
                                        const updatedEmployee = { ...emp, doc_type: ['passport', 'emirates_id'] };
                                        setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                        return updatedEmployee;
                                    } else {
                                        return emp;
                                    }
                                }
                            })
                        }
                    });
                }
            } else {
                setSelectAllDoc("")
                const lastEmployeeIndex = formData.employee.selected_employees.length - 1;
                const lastEmployeeObj = formData.employee.selected_employees[lastEmployeeIndex];
                setFormData({
                    ...formData,
                    employee: {
                        ...formData?.employee,
                        // selected_employees: formData?.employee?.selected_employees.map(emp => ({
                        //     ...emp,
                        //     doc_type: []
                        // }))
                        selected_employees: formData.employee.selected_employees.map(emp => {
                            if (selectedEmployee !== null && selectedEmployee != undefined) {
                                if (emp.emp_id === selectedEmployee.emp_id) {
                                    const updatedEmployee = { ...emp, doc_type: [] };
                                    setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                    return updatedEmployee;
                                } else {
                                    return emp;
                                }
                            } else {
                                if (emp.emp_id === lastEmployeeObj.emp_id) {
                                    const updatedEmployee = { ...emp, doc_type: [] };
                                    setSelectedEmployee(updatedEmployee); // Update selectedEmployee
                                    return updatedEmployee;
                                } else {
                                    return emp;
                                }
                            }
                        })
                    }
                });
            }
        }
    };

    const saveBtnHandler = () => {
        // const data = {
        //     email: formData?.email,
        //     business_owner_id: userId
        // }

        const data = {
            "email": updateId ? updateId : formData?.email,
            "business_owner_id": userId,
            "access_level": formData?.permission,
            "employees": formData?.employee?.selected_employees,
            "folders": formData?.folders?.selected_folders,
            "files": formData?.files?.selected_files,
            "company_folders": formData?.company_folders?.selected_company_folders,
            "company_files": formData?.company_files?.selected_company_files,
        }
        if (updateId) {
            dispatch(updateAccessBO_TC(data))
        } else {
            dispatch(setSendAccessBoToTc(data))
        }

    }
    console.log(formData, "wdsfcsdgvsc")
    const handleSelectedDocs = (employeeId) => {
        const selectedEmp = formData?.employee?.selected_employees?.find(emp => emp.emp_id == employeeId);
        setSelectedEmployee(selectedEmp)
        console.log(selectedEmp, "Selected Employee Details");
    }

    useEffect(() => {
        const lastEmployeeIndex = formData?.employee?.selected_employees?.length ? formData?.employee?.selected_employees?.length - 1 : [];
        const selectedEmployees = formData?.employee?.selected_employees;
        const lastEmployeeObj = Array.isArray(selectedEmployees) && selectedEmployees.length > lastEmployeeIndex
            ? selectedEmployees[lastEmployeeIndex]
            : null;
        setSelectedEmployee(lastEmployeeObj)
    }, [setSelectedEmployee])

    // useEffect(() => {
    //     if (accessManagement?.success == true) {
    //         setFormData({
    //             employee: {},
    //             email: "",
    //             permission: ""
    //         })
    //         setCurrentStep(1)
    //     }

    // }, [accessManagement, dispatch])


    // Old code from use Access 


    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "email": return ValidationFile.validEmail(inputValue);

            default: return false
        }
    }

    const isFormValid = () => {
        return accessBOEmail?.email?.isValid ? true : false
    }


    const handleInputBOchange = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...accessBOEmail,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setAccessBOEmail(data)
        if (inputName === 'email' && accessManagement?.error) {
            dispatch(resetGrantBoError())
        }
    }


    const giveAccessBtn = (e, nextStep) => {
        e.preventDefault()
        if (ValidationFile.validEmail(accessBOEmail?.email?.value)) {
            const data = {
                email: accessBOEmail?.email?.value,
                // business_owner_id: userId
            }
            dispatch(checkTcExist(data))
            if (updateId == null && !accessManagement?.message?.length) {
                // dispatch(setSendAccessBoToTc(data))
                nextStep()
            }
        } else {
            setAcessBOError(true)
        }
    }

    console.log(accessManagement, "Sdfds")

    const resetAccessBOEmail = () => {
        setAccessBOEmail({
            email: {
                value: "",
                isValid: false,
            },
            validation: false,
        })
    }


    const resetFormData = () => {
        setFormData({
            employee: {},
            email: "",
            permission: ""
        })
    }
    // Edit button hanlder 
    const editBtnHandle = (ref, id) => {
        resetFormData()
        ref.current.open()
        setUpdatedId(id)
        setAcessBOError(false)
        dispatch(resetGrantBoError())
        // nextStep()
        setCurrentStep(2)
        dispatch(getViewAccessDeatils(id))
        // const updateItem = accessManagement?.data.find(item => item?.id == id);
        // if (updateItem) {
        //     setAccessBOEmail({
        //         email: {
        //             value: updateItem?.email,
        //             isValid: false,
        //         },
        //         validation: false,
        //     });
        // }
    }

    useEffect(() => {
        if (accessManagement?.view_access?.success) {
            const foldersData = accessManagement?.view_access?.data?.company_files

            function collectAllSubfiles(folders) {
                let allSubfiles = [];

                function traverse(folder) {
                    // Collect subfiles of the current folder
                    if (folder.subfiles && folder.subfiles.length > 0) {
                        allSubfiles = allSubfiles.concat(folder.subfiles);
                    }

                    // Traverse through each subfolder recursively
                    if (folder.subfolder && folder.subfolder.length > 0) {
                        folder?.subfolder?.forEach(sub => traverse(sub));
                    }
                }

                // Start the traversal for each top-level folder
                folders?.forEach(folder => traverse(folder));

                return allSubfiles;
            }
            function collectAllSubfolders(folders) {
                let allSubfolders = [];

                function traverse(folder) {
                    // Collect the current folder
                    allSubfolders.push(folder);

                    // Traverse through each subfolder recursively
                    if (folder.subfolder && folder.subfolder.length > 0) {
                        folder.subfolder.forEach(sub => traverse(sub));
                    }
                }

                // Start the traversal for each top-level folder
                folders?.forEach(folder => traverse(folder));

                return allSubfolders;
            }
            function collectAllSubfolderscomapnyDocs(folders) {
                let allSubfolders = [];

                function traverse(folder) {
                    // Collect the current folder
                    allSubfolders.push(folder);

                    // Traverse through each subfolder recursively
                    if (folder.subfolders && folder.subfolders.length > 0) {
                        folder.subfolders.forEach(sub => traverse(sub));
                    }
                }

                // Start the traversal for each top-level folder
                folders?.forEach(folder => traverse(folder));

                return allSubfolders;
            }

            const allSubfiles = collectAllSubfiles(foldersData);
            const allSubfolders = collectAllSubfolders(accessManagement?.view_access?.data?.folders);

            console.log(allSubfolders, "SAFadfgdvc")

            setFormData((prevFormData) => ({
                ...prevFormData,
                employee: {
                    selected_employees: accessManagement?.view_access?.data?.employees,
                },
                folders: {
                    selected_folders: collectAllSubfolders(accessManagement?.view_access?.data?.folders),
                },
                files: {
                    selected_files: collectAllSubfiles(accessManagement?.view_access?.data?.folders),
                },
                company_folders: {
                    selected_company_folders: collectAllSubfolderscomapnyDocs(accessManagement?.view_access?.data?.company_folders),
                },
                // company_files: {
                //     selected_company_files: collectAllSubfiles(accessManagement?.view_access?.data?.company_folders),
                // },
                company_files: {
                    selected_company_files: [
                        ...collectAllSubfiles(accessManagement?.view_access?.data?.company_folders || []),  // Spread the collected subfiles
                        ...accessManagement?.view_access?.data?.company_files || []                     // Spread the company folders
                    ]
                },

                email: updateId,
            }));
            // dispatch(resetViewAccessDeatils())
            // console.log("ASdfsdfgsdgv 2222222222222222")
        }
    }, [accessManagement?.view_access])

    console.log(accessManagement?.view_access?.data?.folders, "adfsdgfv")

    const deleteBtnHandler = (delete_id) => {
        if (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) {
            dispatch(deleteAccess(delete_id, userId))
        } else {
            dispatch(deleteAccess(userId, delete_id))
        }
    }
    const revokeBtnHandler = (id) => {
        dispatch(revokeAccess(id))
    }
    const actionHandler = (id, type) => {
        dispatch(actionHandlerTypingCenter(id, { "action": type }))
    }

    useEffect(() => {
        if (accessManagement?.error) {
            setAcessBOError(true)
        }
        if (accessManagement?.success == true) {
            onClose && onClose()
            dispatch(resetSendAccessBoToTc())
            resetAccessBOEmail()
            setFormData({
                employee: {},
                email: "",
                permission: ""
            })
            setSelectedEmployee(null)
            setCurrentStep(1)
        }
        if (accessManagement?.updateAccess?.success == true) {
            onClose && onClose()
            dispatch(resetUpdateAccessBO_TC())
            dispatch(resetViewAccessDeatils())
            setFormData({
                employee: {},
                email: "",
                permission: ""
            })
            // setCurrentStep(2)
        }
        // if (updateId) {
        //     setCurrentStep(2)
        // }else{
        //     setCurrentStep(1)
        // }
    }, [accessManagement, onClose, dispatch])

    useEffect(() => {
        dispatch(getAccessUsers(userId));
    }, [])

    useEffect(() => {
        if (accessManagement?.message?.length) {
            setCurrentStep(1)
        }

    }, [accessManagement?.message])

    //  Folder get 
    useEffect(() => {
        if (accessOf === "Folders") {
            dispatch(getFolderAndFilesAccessData(userId))
        } else if (accessOf === "Company Documents") {
            dispatch(getCompanyFolderAndFilesAccessData(userId))
        }
    }, [accessOf])




    // Access Level handler

    const accessLevelDropHandler = (value) => {
        setAccessBOEmail((prev) => ({ ...prev, permission: value }))
    }
    console.log(accessBOEmail, "Acsdvc")

    return {
        currentStep, setCurrentStep,
        accessOf, setAccessOf,
        formData, setFormData,
        nextStep,
        previousStep,
        handleInputChange,
        saveBtnHandler,
        selectAllHandler,
        employeeManagement,
        documentUpload, showDocs, searchValModal, handleSearchModal, handleResetSearchModal, selectAll,
        handleSelectedDocs, selectedEmployee, selectAllEmpRef, selectAll_docRef, selectAllDoc,

        accessManagement, giveAccessBtn, accessBOEmail, acessBOError,
        handleInputBOchange, resetAccessBOEmail, setAcessBOError, editBtnHandle
        , deleteBtnHandler, actionHandler, revokeBtnHandler, updateId, setUpdatedId,
        //  folders

        folders, handleFolderInputChange, handleComapanyDocInputChange,

        accessLevelDropHandler
    }
}

export default useAccessModal