import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Validation from '../../Classes/Validation';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../Classes/Auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compressFile } from '../../HelperFunction/FileCompressor';
import { FILE_ACCEPT, IMG_ACCEPT } from '../../Constant/Constants';
import { ALLOW_IMAGE_EXTENSIONS, noticationTiggerTime } from '../../config';
import { companydocsCreateNewFolder, getComapanyDocumentsFoldersList, renameCompanyDocsFolder, uploadComapanyDocsFile, getCompanyDocsSubFolder, setReminderFolder, setReminderFile, setCompanyDocumentsNotifications, triggerCompanyDocumentsNotification, setCompanyDocsClient } from '../../store/Actions/CompanyDocuments';
import { ErrorReset, createFolderReset, deleteFiles, deleteFolder, renameReset, resetFolderDetail, resetFolderList, setBreadcrumb } from '../../store/Actions/CompanyDocuments';
import { setClient } from '../../store/Actions/EmployeeManagement';
import { getClientsList } from '../../store/Actions/Clients';
const useCompanyDocuments = (folderPopPopRef) => {
    const companyDocuments = useSelector((e) => e.companyDocuments)
    const clients = useSelector(e => e.clients)
    // const employeeManagement = useSelector((state) => state.employeeManagement)
    const userId = Auth?.user()?.user_id // this is for added by user 
    const valid = new Validation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false)
    const [editable, setEditabel] = useState(false)
    const [uploadType, setUploadType] = useState('create')
    const [renameId, setRenameId] = useState('')
    // const [breadcrumb, setBreadcrumb] = useState('')
    const [folderId, setFolderId] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const prevRef = useRef(null)
    const [prevData, setPrevData] = useState(null)
    // const { folderId } = useParams()
    const location = useLocation()
    const searchParamsString = location.search.replace(/\?/g, '&');
    const params = new URLSearchParams(searchParamsString);
    const [reminderDate, setReminderDate] = useState(null)
    const [expiryDate, setExpiryDate] = useState(null)
    const [reminderFolderID, setReminderID] = useState(null)
    const [reminderFileType, setReminderFileType] = useState(null)

    const [longPress, setLongPress] = useState(false);
    const pressTimer = useRef(null);
    const folderInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const selectedClientDropdown = companyDocuments?.client

    useEffect(() => {
        setFolderId(params.get('folderId'));
        dispatch(setBreadcrumb(params.get('folderName')))
    }, [location, searchVal])

    console.log(folderId, "folderIdfolderId")

    const [folderName, setFolderName] = useState({
        name: {
            value: "",
            isValid: false,
        },
        validation: false,
    })
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "name":
                return valid.isNotEmpty(inputValue);

            default:
                return false;
        }
    };

    const isFormValid = () => {
        return folderName.name.isValid ? true : false;
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...folderName,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setFolderName(data);
        dispatch(ErrorReset())
    };

    // SAVE BTN HANDLE
    const saveBtnHandle = () => {
        if (isFormValid()) {
            const data = {
                "name": folderName?.name?.value,
                "added_by_user_id": userId,
                "parent": folderId
            }
            if (uploadType == 'create') {
                dispatch(companydocsCreateNewFolder(data))
            } else if (uploadType == 'rename') {
                // dispatch(renameFolder(renameId, data))

                // Modify the data object before dispatching
                const renamedData = {
                    "new_name": data.name, // Replace 'name' with 'new_name'
                };
                dispatch(renameCompanyDocsFolder(renameId, renamedData, folderId, userId));

            }
        } else {
            setShowError(true)
        }
    }

    // DELETE BTN HANDLER
    const deleteHandlerBtn = (id) => {
        dispatch(deleteFolder(id, userId, folderId))
    }


    // GET FOLDER LIST
    // useEffect(() => {
    //     dispatch(getFoldersList())
    // }, [dispatch, companyDocuments?.created])


    // RENAME FOLDER NAME
    function binarySearch(arr, id) {
        let low = 0;
        let high = arr.length - 1;

        while (low <= high) {
            let mid = Math.floor((low + high) / 2);
            let midId = arr[mid]?.id;

            if (midId === id) {
                return arr[mid]; // Found the item
            } else if (midId < id) {
                low = mid + 1; // Continue searching in the right half
            } else {
                high = mid - 1; // Continue searching in the left half
            }
        }

        return null; // Item not found
    }
    const renameHandlerBtn = (e, id) => {
        e.stopPropagation()
        setUploadType('rename')
        setRenameId(id)
        folderPopPopRef.current.open();
        const foundItem = companyDocuments?.data?.folders.find(item => item?.id == id);
        // let foundItem = binarySearch(companyDocuments?.data?.folders, id);

        if (foundItem) {
            setFolderName({
                name: {
                    value: foundItem?.name,
                    isValid: true,
                },
                validation: true,
            });
        }
    }


    // FOLDER DETAIL HANDLER
    const FolderDetailsHandler = (id, name) => {
        // setBreadcrumb(name)
        dispatch(setBreadcrumb(name))
        navigate(`/company-documents?folderId=${id}&folderName=${name}`)
        dispatch(getCompanyDocsSubFolder(id))
    }

    // UPLOAD FILE INTEGRATION 

    function onUploadFileChange(e) {
        const files = e.target.files;
        if (!files.length) {
            setLongPress(false); // Reset when no files are selected and dialog is closed
            return;
        }

        const compressedFiles = []; // Initialize an array to store compressed files 
        const validFiles = []; // Array to store valid file data
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
        let processedCount = 0; // Counter to track processed files

        // Get the folder name from the first file's path
        const firstFilePath = files[0].webkitRelativePath;
        const folderName = firstFilePath.split('/')[0]; // Extracting the folder name
        console.log('Folder Name:', files[0]);

        Array.from(files).forEach((file) => {
            if (IMG_ACCEPT.includes(file?.type) || FILE_ACCEPT.includes(file?.type)) {
                if (file.size <= maxSize) {
                    validFiles.push(file); // Store valid files

                    // Compress the file
                    compressFile(file)
                        .then((compressedFile) => {
                            compressedFiles.push(compressedFile); // Add compressed file to the array
                            processedCount++; // Increment the counter

                            // Check if all files have been processed
                            if (processedCount === validFiles.length) {
                                const formData = new FormData();
                                const data = {
                                    folder_id: folderId,
                                    // folder_id: 20,
                                    added_by_user_id: userId,
                                    // folder_name: folderName // Add folder name to the data
                                };

                                Object.entries(data).forEach(([key, value]) => {
                                    formData.append(key, value);
                                });

                                // Append all compressed files to formData
                                compressedFiles.forEach((compressedFile, index) => {
                                    formData.append("file", compressedFile, validFiles[index].name);
                                    formData.append(`webkitRelativePath`, compressedFile?.webkitRelativePath); // Append folder name with each file
                                });

                                // Dispatch the upload action
                                dispatch(uploadComapanyDocsFile(formData, userId, folderId));
                                e.target.value = null; // Reset the file input
                            }
                        })
                        .catch((err) => {
                            console.error("File compression error:", err);
                            processedCount++; // Ensure the counter increments even on error
                        });
                } else {
                    alert('File size exceeds the limit of 10 MB');
                }
            } else {
                alert('Invalid Format! Accept only images, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt');
            }
        });
        setLongPress(false); // Reset after file is processed
    }




    // function onUploadFileChange(e) {
    //     const files = e.target.files;
    //     if (!files.length) {
    //         setLongPress(false); // Reset when no files are selected and dialog is closed
    //         return;
    //     }

    //     const compressedFiles = []; // Initialize an array to store compressed files 
    //     const validFiles = []; // Array to store valid file data
    //     const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    //     let processedCount = 0; // Counter to track processed files

    //     Array.from(files).forEach((file) => {
    //         if (IMG_ACCEPT.includes(file?.type) || FILE_ACCEPT.includes(file?.type)) {
    //             if (file.size <= maxSize) {
    //                 validFiles.push(file); // Store valid files

    //                 // Compress the file
    //                 compressFile(file)
    //                     .then((compressedFile) => {
    //                         compressedFiles.push(compressedFile); // Add compressed file to the array
    //                         processedCount++; // Increment the counter

    //                         // Check if all files have been processed
    //                         if (processedCount === validFiles.length) {
    //                             const formData = new FormData();
    //                             const data = {
    //                                 folder_id: folderId,
    //                                 added_by_user_id: userId
    //                             };

    //                             Object.entries(data).forEach(([key, value]) => {
    //                                 formData.append(key, value);
    //                             });

    //                             // Append all compressed files to formData
    //                             compressedFiles.forEach((compressedFile, index) => {
    //                                 formData.append("file", compressedFile, validFiles[index].name);
    //                             });

    //                             // Dispatch the upload action
    //                             dispatch(uploadComapanyDocsFile(formData, userId, folderId));
    //                             e.target.value = null; // Reset the file input
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         console.error("File compression error:", err);
    //                         processedCount++; // Ensure the counter increments even on error
    //                     });
    //             } else {
    //                 alert('File size exceeds the limit of 10 MB');
    //             }
    //         } else {
    //             alert('Invalid Format! Accept only images, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt');
    //         }
    //     });
    //     setLongPress(false); // Reset after file is processed
    // }



    // function onUploadFileChange(e) {
    //     const files = e.target.files; // Get the list of files
    //     if (!files.length) {
    //         setLongPress(false); // Reset when no files are selected and dialog is closed
    //         return;
    //     }

    //     const maxSize = 2 * 1024 * 1024; // 10 MB in bytes
    //     const formData = new FormData(); // Create a single FormData object for all files

    //     const data = {
    //         folder_id: folderId,
    //         added_by_user_id: userId,
    //     };

    //     Object.entries(data).forEach(([key, value]) => {
    //         formData.append(key, value); // Append metadata to formData
    //     });

    //     let validFilesCount = 0; // Track the number of valid files
    //     const filePromises = Array.from(files).map((file) => {
    //         return new Promise((resolve, reject) => {
    //             if (IMG_ACCEPT.includes(file?.type) || FILE_ACCEPT.includes(file?.type)) {
    //                 if (file.size <= maxSize) {
    //                     compressFile(file)
    //                         .then((compressedFile) => {
    //                             formData.append("file", compressedFile, file.name); // Append each compressed file
    //                             validFilesCount++; // Increment valid files count
    //                             resolve(); // Resolve the promise
    //                         })
    //                         .catch((err) => {
    //                             console.error("File compression error:", err);
    //                             reject(err); // Reject the promise on error
    //                         });
    //                 } else {
    //                     alert(`File ${file.name} size exceeds the limit of 10 MB`);
    //                     reject(new Error("File size exceeds the limit")); // Reject if the file is too large
    //                 }
    //             } else {
    //                 alert(`File ${file.name} has an invalid format! Accept only images, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt`);
    //                 reject(new Error("Invalid file format")); // Reject if the file format is invalid
    //             }
    //         });
    //     });

    //     Promise.all(filePromises)
    //         .then(() => {
    //             if (validFilesCount > 0) {
    //                 dispatch(uploadComapanyDocsFile(formData, userId, folderId)); // Call the API once after all files are processed
    //             }
    //         })
    //         .catch((err) => {
    //             console.error("One or more files could not be processed:", err);
    //             // Handle the error appropriately
    //         });

    //     // Clear the file input
    //     e.target.value = null;
    //     setLongPress(false); // Reset after file processing
    // }



    const handleMouseDown = () => {
        pressTimer.current = setTimeout(() => {
            setLongPress(true);
            folderInputRef.current.click(); // Trigger folder upload
        }, 500); // Long press threshold: 500ms
    };

    const handleMouseUp = () => {
        clearTimeout(pressTimer.current);
        if (!longPress) {
            fileInputRef.current.click(); // Trigger file upload on short click
        }
        setLongPress(false);
    };
    console.log(longPress, "AScsadvc")

    // useEffect(() => {
    //     if (folder_ID == 'all') {
    //         dispatch(getFoldersList())
    //         dispatch(resetFolderDetail())
    //         setBreadcrumb('')
    //     }
    // }, [folder_ID])


    useEffect(() => {
        if (folderId == null && params.get('folderId') == null) {
            dispatch(getClientsList(userId))
            if (Auth?.boolUserType() != 2) {
                dispatch(getComapanyDocumentsFoldersList(userId, selectedClientDropdown?.id == undefined ? null : selectedClientDropdown?.id))
            } else if (selectedClientDropdown?.id) {
                dispatch(getComapanyDocumentsFoldersList(userId, selectedClientDropdown?.id == undefined ? null : selectedClientDropdown?.id))
            }
            dispatch(resetFolderDetail())
            // setBreadcrumb('')
            dispatch(setBreadcrumb(''))
        } else { }
        if (folderId == null && companyDocuments?.created) {
            dispatch(getClientsList(userId))
            dispatch(getComapanyDocumentsFoldersList(userId, selectedClientDropdown?.id == undefined ? null : selectedClientDropdown?.id))
        }
        if (folderId !== null) {
            dispatch(getClientsList(userId))
            dispatch(getCompanyDocsSubFolder(folderId))
        }
    }, [folderId, companyDocuments?.created, companyDocuments?.reminder, selectedClientDropdown])

    useEffect(() => {
        const folder_id_param = params.get('folderId')
        if (folder_id_param !== null) {
            dispatch(getCompanyDocsSubFolder(folder_id_param))
        }
        // setBreadcrumb(companyDocuments?.data[0]?.name)
    }, [])
    useEffect(() => {
        if (params.get('folderId') !== null) {
            // setBreadcrumb(companyDocuments?.data[0]?.name)
        } else {
            // setBreadcrumb('')
        }
    }, [companyDocuments])


    // RESET FOLDER NAME 
    useEffect(() => {
        if (companyDocuments?.created || companyDocuments?.rename) {
            dispatch(createFolderReset())
            dispatch(renameReset())
            setFolderName({
                name: {
                    value: "",
                    isValid: false,
                },
                validation: false,
            })
        }
    }, [companyDocuments])


    const resetFolderName = () => {
        dispatch(ErrorReset())
        setFolderName({
            name: {
                value: "",
                isValid: false,
            },
            validation: false,
        });
    };
    useLayoutEffect(() => {
        if (location?.pathname.includes('companyDocuments')) {
            dispatch(resetFolderList())
        }
    }, [])

    const filePrevHandler = (data) => {
        prevRef.current.open();
        setPrevData(data)
        console.log(data, "sfsadvfc")
    }
    const filePrevHandlerClose = () => {
        prevRef.current.close();
    }

    const deleteFileHanlder = (id) => {
        dispatch(deleteFiles(id, userId, folderId))
    }


    console.log(reminderFolderID, "SDfdsfgf")
    const setReminderHandler = (e) => {
        e.preventDefault()
        const data = {
            "folder_id": reminderFolderID?.id,
            "reminder_date": reminderDate,
            ...(expiryDate && { "expiry_date": expiryDate })
        }
        // if (reminderDate !== null) {
        if (reminderFileType === "folder") {
            dispatch(setReminderFolder(data))
        } else if (reminderFileType === "file") {
            dispatch(setReminderFile(data))
        }
        // }
    }

    // Trigger company documents noty
    useEffect(() => {
        dispatch(triggerCompanyDocumentsNotification(userId))
        dispatch(setCompanyDocumentsNotifications(userId))
    }, []);



    // Create a new array 'data2' by mapping over the original 'data' array
    const folderSelectClientData = clients.data.map(item => {
        // Check if 'business_name' is an empty string and both 'first_name' and 'last_name' are not empty
        if (item.business_name === "" && item.first_name && item.last_name) {
            // Return a new object with all properties of the original item, but with 'business_name' set to 'first_name' + 'last_name'
            return {
                ...item,
                business_name: item.first_name + " " + item.last_name
            };
        }
        // If the condition is not met, return the item as is
        return item;
    });

    const DropdownClientChanger = (val) => {
        dispatch(setCompanyDocsClient(val))
    }


    return {
        folderName, setFolderName, handleInput, saveBtnHandle,
        showError, deleteHandlerBtn, resetFolderName, renameHandlerBtn, editable,
        uploadType, setUploadType, setShowError, FolderDetailsHandler, onUploadFileChange,
        setBreadcrumb, folderId, setFolderId, searchVal, setSearchVal, filePrevHandler, prevRef, filePrevHandlerClose,
        prevData, deleteFileHanlder, reminderDate, setReminderDate, expiryDate, setExpiryDate, setReminderHandler, reminderFolderID, setReminderID,
        setReminderFileType, handleMouseDown,
        handleMouseUp, fileInputRef, folderInputRef,

        folderSelectClientData, selectedClientDropdown, DropdownClientChanger
    }
}

export default useCompanyDocuments