import React from 'react'
import Modals from '../../../../CommanComponents/Modals'
import ModalsHeader from '../../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../../CommanComponents/Modals/ModalsBody'
import "./lastLoginPopup.scss"
import DataNotFound from '../../../../CommanComponents/DataNotFound/DataNotFound'
const LastLoginPopup = ({ reference, onClose, data }) => {
    console.log(data, "ASfegv")
    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            Position="center"
            ModalsSize={'modal-s'}
            ClassName={'LastLoginPopup-upload-wrapper'}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>Login history</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={'LastLoginPopup-modal-body'}>
                <div className="table-wrap">
                    {data?.login_history?.loading ? <DataNotFound className={"mt-20"} text={"Loading..."} /> :
                        data?.login_history?.data?.login_history?.length > 0 ?
                            <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th className='srNo'>IP address </th>
                                        <th className='name'>Login date </th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {data?.login_history?.data?.login_history?.map((val, key) => {
                                        return (
                                            <tr className="table-row" key={key}  >
                                                <td  >
                                                    {val?.ip_address}
                                                </td>
                                                <td>
                                                    {val?.login_time}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> :

                            <DataNotFound className={"secondary"} />
                    }

                </div>
            </ModalBody >

        </Modals>
    )
}

export default LastLoginPopup