import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useFolders from '../../CustomHooks/Folders/useFolders'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import { ALLOW_IMAGE_EXTENSIONS, getBaseUrl } from '../../config'
import getFileExtension from '../../HelperFunction/getFileExtension'
import { fileDownloaderHelperFunc } from '../../HelperFunction/fileDownloaderHelperFunc'
import { dateFormat } from '../../CustomHooks/dateFormat'
import LoadingData from '../../CommanComponents/LoadingData/LoadingData'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import Auth from '../../Classes/Auth'

const CompanyDocumentsListView = ({ deleteHandlerBtn, renameHandlerBtn, FolderDetailsHandler, filePrevHandler, deleteFileHanlder, openReminderPop, setReminderID, setReminderFileType,
    openFolderPopup, folderId, inputFile, IMG_ACCEPT, FILE_ACCEPT, onUploadFileChange
}) => {
    const companyDocuments = useSelector((e) => e.companyDocuments) // FOLDERS SELECTOR 
    const ref = useRef(null)
    const ref2 = useRef(null)
    const [activeOptionsDrop, setActiveOptionsDrop] = useDetectOutsideClick(ref, false)
    const [activeOptionsDrop2, setActiveOptionsDrop2] = useDetectOutsideClick(ref2, false)
    const [deleteId, setDeleteId] = useState("");
    const [deletePop, setDeletePop] = useState(false)
    const [deleteType, setDeleteType] = useState(null);

    const handleDotsBtn = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop(prev => val === prev ? -1 : val)

    }
    const handleDotsBtn2 = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop2(prev => val === prev ? -1 : val)

    }
    const handleDltBtn = (e, id, type) => {
        e.stopPropagation()
        setDeletePop(true);
        setDeleteId(id)
        setDeleteType(type)
    }

    const CancleDelete = () => {
        setDeletePop(false);
        setDeleteType(null)
    }
    const handlePopDeleteBtn = () => {
        setDeletePop(false);
        setDeleteType(null)
        if (deleteType == "folder") {
            deleteHandlerBtn(deleteId)
        } else if (deleteType == "file") {
            deleteFileHanlder(deleteId)
        }
    }
    console.log(companyDocuments?.data, "SDgdsfgfv")
    return (
        <div className="CompanyDocumentsListView-list-folder-item-wrap ">
            <div className="folder-table-wrapper ">
                <div className="table-wrap">
                    {

                        companyDocuments?.loading ? <LoadingData /> : companyDocuments?.data?.folders?.length || companyDocuments?.data?.files?.length ?
                            <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th > Name  </th>
                                        <th > Expiry  </th>
                                        <th > Reminder  </th>
                                        <th > Action  </th>
                                        {/* <th >Owner</th>
                            <th >Last modified</th>
                            <th >File size</th>
                            <th >Type</th> */}
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {companyDocuments?.data?.folders?.map((val, key) => {
                                        return (
                                            // !val.hasOwnProperty("files") ?
                                            <tr className={`table-row relative ${val?.reminder_date !== null && 'have_reminder_date'}`} key={key} onClick={() => FolderDetailsHandler(val?.id, val?.name)}>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        <i className="folder-icon"></i>
                                                        {val?.name}
                                                    </div>
                                                </td>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        {/* <i className="folder-icon"></i> */}
                                                        {val?.expiry_date ? dateFormat(val?.expiry_date) : "N/A"}
                                                    </div>
                                                </td>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        {/* <i className="folder-icon"></i> */}
                                                        {val?.reminder_date ? dateFormat(val?.reminder_date) : "N/A"}
                                                    </div>
                                                </td>
                                                <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                    <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                        <div className={`item d-flex align-center justify-center pointer`}
                                                            onClick={() => FolderDetailsHandler(val?.id, val?.name)} >
                                                            <i className='icons sT-icon secondary i-xxs eye'></i>
                                                        </div>
                                                        {companyDocuments?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? <div className={`item d-flex align-center justify-center pointer`}
                                                            onClick={(e) => renameHandlerBtn(e, val?.id)} >
                                                            <i className='icons sT-icon secondary i-xxs edit-icon'></i>
                                                        </div> :
                                                            Auth?.boolUserType() !== 2 ?
                                                                <div className={`item d-flex align-center justify-center pointer`}
                                                                    onClick={(e) => renameHandlerBtn(e, val?.id)} >
                                                                    <i className='icons sT-icon secondary i-xxs edit-icon'></i>
                                                                </div> : ""
                                                        }
                                                        {companyDocuments?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'folder')}>
                                                            <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                        </div> :

                                                            Auth?.boolUserType() !== 2 ? <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'folder')}>
                                                                <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                            </div> : ""
                                                        }
                                                        <div className="item d-flex align-center justify-center pointer " onClick={(e) => { return openReminderPop(), e.stopPropagation(), setReminderID(val), setReminderFileType('folder') }}>
                                                            <i className='icons sT-icon secondary i-xxs bell-icon'></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {companyDocuments?.data?.files?.map((val, key) => {
                                        return (
                                            <tr className={`table-row relative ${val?.reminder_date !== null && 'have_reminder_date'}`} key={key} onDoubleClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        <i className="folder-icon gallery-icon"></i>
                                                        {val?.file?.split('/')[val?.file?.split('/').length - 1].split('.')[0]}
                                                    </div>
                                                </td>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        {/* <i className="folder-icon"></i> */}
                                                        {val?.expiry_date ? dateFormat(val?.expiry_date) : "N/A"}
                                                    </div>
                                                </td>
                                                <td  >
                                                    <div className="folder-name-wrap d-flex align-center">
                                                        {/* <i className="folder-icon"></i> */}
                                                        {val?.reminder_date ? dateFormat(val?.reminder_date) : "N/A"}
                                                    </div>
                                                </td>
                                                <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                    <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                        <div className={`item d-flex align-center justify-center pointer`}
                                                            onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}  >
                                                            <i className='icons sT-icon secondary i-xxs eye'></i>
                                                        </div>
                                                        {companyDocuments?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'file')}>
                                                            <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                        </div>
                                                            : Auth?.boolUserType() !== 2 ?
                                                                <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'file')}>
                                                                    <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                                </div> : ""
                                                        }
                                                        <div className="item d-flex align-center justify-center pointer " onClick={(e) => { return openReminderPop(), e.stopPropagation(), setReminderID(val), setReminderFileType("file") }}>
                                                            <i className='icons sT-icon secondary i-xxs bell-icon'></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* <td >
                                                    <div className="relative">
                                                        <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn2(e, key)}>
                                                            <i className='dots-icon'> </i>
                                                        </div>
                                                        {activeOptionsDrop2 === key && <div className={`dropdown-wrap absolute active`} ref={ref2}>
                                                            <div className="item d-flex align-center mb-10 pointer" onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}  >
                                                                <i className='icons mr-5 sT-icon secondary i-xxs eye'></i>
                                                                <p className="text-2xs w-500 secondary" >Preview</p>
                                                            </div>
                                                            <div className="item d-flex align-center mb-10 pointer" onClick={(e) => handleDltBtn(e, val?.id, 'file')} >
                                                                <i className='icons mr-5 sT-icon secondary i-xxs delete-icon'></i>
                                                                <p className="text-2xs w-500 secondary" >Delete</p>
                                                            </div>
                                                            <div className="item d-flex align-center mt-10 pointer" onClick={(e) => { return openReminderPop(), e.stopPropagation(), setReminderID(val), setReminderFileType("file") }}>
                                                                <i className='icons mr-5 sT-icon secondary i-xxs bell-icon'></i>
                                                                <p className="text-2xs w-500 secondary" > Set reminder</p>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </td> */}
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='d-flex add-folder-btn'>
                                <DataNotFound />
                                {folderId === null && (companyDocuments?.data?.breadcrumbs?.length < 4 || companyDocuments?.data?.breadcrumbs == undefined) && <CommanButton onClick={(e) => { return openFolderPopup() }} ClassName={"action-btn"} >
                                    <p className='w-700'> <span className='text-xs'>  + </span> Folder</p>
                                </CommanButton>}
                                {folderId === null && <CommanButton ClassName={"action-btn"} onClick={() => inputFile.current.click()} >
                                    <p className='w-700'> <span className='text-xs'>  + </span> Upload File</p>
                                    <input id="myInput"
                                        type="file"
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                        accept={`${IMG_ACCEPT},${FILE_ACCEPT}`}
                                        onChange={(e) => { return onUploadFileChange(e) }}
                                    />
                                </CommanButton>}
                            </div>
                    }
                </div>
            </div>
            {
                deletePop && <DeleteConfirmPop
                    handleCancelVar={CancleDelete}
                    deleteVarHandler={() => handlePopDeleteBtn()}
                />
            }
        </div >
    )
}

export default CompanyDocumentsListView