import React, { useEffect, useRef, useState } from 'react'
import ValidationFile from '../../../Auth/Login/ValidationFile';
import FormError from '../../../../CommanComponents/Form/FormError';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { resetContactUs, setContactUs } from '../../../../store/Actions/ContactUs';
import "./getInvite.scss"

const GetInvite = ({ invite, setInvite, inviteRef }) => {
    const contactUs = useSelector((e) => e.contactUs)
    const dispatch = useDispatch()
    const phoneInputRef = useRef(null);
    const [showMobileInput, setShowMobileInput] = useState(false);
    const [getInviteData, setGetInviteData] = useState({
        firstname: {
            value: "",
            isValid: false,
        },
        lastname: {
            value: "",
            isValid: true,
        },
        email: {
            value: "",
            isValid: false,
        },
        phone: {
            value: "",
            country_code: "+971",
            isValid: true,
        },
        city: {
            value: "",
            isValid: true,
        },
        type: {
            value: "",
            isValid: true,
        },

        validation: false,
    });

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "firstname":
                return ValidationFile.validEmpty(inputValue);
            case "email":
                return ValidationFile.validEmail(inputValue);
            case "lastname":
                return true;
            case "phone":
                return true;
            case "city":
                return true;
            case "type":
                return true;

            default:
                return false;
        }
    };
    const isFormValid = () => {
        return (
            getInviteData.firstname.isValid &&
                getInviteData.lastname.isValid &&
                getInviteData.email.isValid &&
                getInviteData.phone.isValid &&
                getInviteData.city.isValid &&
                getInviteData.type.isValid
                ? true : false
        );
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName == "firstname" || inputName == "lastname") {
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        }
        let data = {
            ...getInviteData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setGetInviteData(data);
        switch (inputName) {
            case "first_name":
            case "last_name":
            // if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
            //     return setGetInviteData(data), setRegexError('');
            // } else {
            //     return setRegexError(inputName);
            // }
            default: setGetInviteData(data);
        }


    };

    const handleCityChange = (event) => {
        const inputName = event.target?.name
        const inputValue = event.target?.value
        setGetInviteData({
            ...getInviteData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
        });
    };

    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...getInviteData,
            "phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "phone"),
            }
        }
        setGetInviteData(mobileData)
    }


    const submitHandler = (e) => {
        e.preventDefault()
        const data = {
            firstname: getInviteData.firstname.value,
            lastname: getInviteData.lastname.value,
            email: getInviteData.email.value,
            phone: getInviteData.phone.value,
            city: getInviteData.city.value,
            type: getInviteData.type.value
        }

        dispatch(setContactUs(data))

    }

    const focusMobile = () => {
        setShowMobileInput(true)
        if (phoneInputRef?.current) {
            phoneInputRef?.current?.numberInputRef?.focus();
        }
    }

    const resetData = () => {
        setGetInviteData({
            firstname: {
                value: "",
                isValid: false,
            },
            lastname: {
                value: "",
                isValid: true,
            },
            email: {
                value: "",
                isValid: false,
            },
            phone: {
                value: "",
                country_code: "+971",
                isValid: true,
            },
            city: {
                value: "",
                isValid: true,
            },
            type: {
                value: "",
                isValid: true,
            },

            validation: false,
        })
    }

    useEffect(() => {
        if (invite) {
            resetData()
            setShowMobileInput(false)
        }
        if (contactUs?.landingPage?.success) {
            setInvite(false)
            resetData()
            dispatch(resetContactUs())
        }
    }, [contactUs?.landingPage, invite])

    console.log(contactUs, invite, "getInviteDatagetInviteData")

    return (
        <div className={`modal  popup-wrapper invite-wrapper get-invite-main-wrapper ${invite && "active"}`}  >
            <div className="modal-dialog">
                <div className="modal-content model-content-wrapper invite-modal-wrap" ref={inviteRef}>
                    <div className="modal-header">
                        <h5 className="modal-title text-center">Contact us</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setInvite(e => !e)}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="form-invite">
                            <form className="needs-validation" novalidate onSubmit={submitHandler}>
                                <div className="row">
                                    <div className="col-md-6 group-form">
                                        <input
                                            type=""
                                            name="firstname"
                                            className="form-control"
                                            placeholder=""
                                            required
                                            value={getInviteData?.firstname?.value}
                                            onChange={handleInput}
                                        />
                                        <label>First Name</label>
                                        {/* <FormError show={true} error={" gettitng error"} /> */}
                                    </div>
                                    <div className="col-md-6 group-form">
                                        <input
                                            type=""
                                            name="lastname"
                                            className="form-control"
                                            placeholder=""
                                            required=""
                                            value={getInviteData?.lastname?.value}
                                            onChange={handleInput}
                                        />
                                        <label>Last Name</label>
                                    </div>
                                    <div className="col-md-6 group-form">
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder=""
                                            required
                                            value={getInviteData?.email?.value}
                                            onChange={handleInput}
                                        />
                                        <label>Email</label>
                                    </div>
                                    <div className="col-md-6 group-form" onFocus={() => focusMobile()}>

                                        {!showMobileInput &&
                                            <>
                                                <input
                                                    type=""
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder=""
                                                    required=""
                                                    value={getInviteData?.phone?.value}
                                                    onChange={handleInput}
                                                />
                                                <label>Phone</label>
                                            </>
                                        }
                                        {true && < div className={`auth-phpne-wrapper ${showMobileInput && 'active'}`}>

                                            <PhoneInput
                                                ref={phoneInputRef}
                                                countryCodeEditable={false}
                                                containerClass="custom-form-container"
                                                inputClass="custom-form-input"
                                                specialLabel
                                                value={`${getInviteData.phone.country_code} ${getInviteData.phone.value}`}
                                                onChange={(value, formattedValue, nameValue) => {
                                                    handlePhoneInput(value, formattedValue, nameValue);
                                                }}
                                                onKeyUp={(value, formattedValue, nameValue) => {
                                                    handlePhoneInput(value, formattedValue, nameValue);
                                                }}
                                                onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                                preserveOrder={['onlyCountries', 'preferredCountries']}
                                                inputProps={{
                                                    name: "phone",
                                                    autoFocus: false,
                                                    placeholder: "Phone Number *",
                                                    // ref: phoneInputRef,
                                                    // tabIndex: Auth?.boolUserType() == 3 ? '3' : ""
                                                    tabIndex: 4

                                                }}
                                                enableSearch
                                                name="mobileNo"
                                                searchPlaceholder="Search Country"
                                                disableSearchIcon
                                            />
                                        </ div>
                                        }




                                    </div>
                                    <div className="col-md-6 form-group select-wrapper">
                                        <div className="select-group ">
                                            {/* <label className="control-label" for="selectCtrl">City</label> */}
                                            <select
                                                className="form-select form-control addmember1"
                                                id="selectCtrl"
                                                aria-label="Default select example"
                                                onChange={handleCityChange}
                                                name='city'
                                            >
                                                <option>City</option>
                                                <option value="UAE">UAE</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Yemen">Yemen</option>
                                            </select>
                                            <label>City</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 group-form select-wrapper">
                                        <select
                                            className="form-select form-control addmember1"
                                            aria-label="Default select example"
                                            onChange={handleCityChange}
                                            name='type'
                                        >
                                            <option>Type</option>
                                            <option value="Business Owner">Business Owner</option>
                                            <option value="Typing / Business Center">Typing / Business Center</option>
                                            <option value="Individual">Individual</option>
                                        </select>
                                        <label>Type</label>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-primary">
                                            submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetInvite