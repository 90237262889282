import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRecycleList } from '../../store/Actions/RecycleBin'
import Auth from '../../Classes/Auth'

const useRecycleBin = () => {
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user
    useEffect(() => {
        dispatch(getRecycleList(userId))
    }, [])
    return {}
}

export default useRecycleBin