import Request from "../../../Classes/Requests";

class documentsReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            passport_documents: super.url("/__DOC__TYPE_documents?added_by_user_id=__USER_ID__&order_by=__ORDER_BY__&date1=__DATE_1__&date2=__DATE_2__"),
            documents_search: super.url("/documents_search?added_by_user_id=__USER_ID__&query=__VALUE__"),
            get_list_of_documents: super.url("/get_list_of_documents?user_id=__USER_ID__&client_id=__CLIENT_ID__"),

            // /documents_search?query=tamang&added_by_user_id=1
        }
    }
}
export default new documentsReq();
