export const ManageCompany_Actions = {
    SET_LOADING: "SET_LOADING",
    SET_SUBCOMPANY_LIST: "SET_SUBCOMPANY_LIST",
    RESET_CLIENTS_LIST: "RESET_CLIENTS_LIST",

    CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
    CREATE_CLIENT_RESET: "CREATE_CLIENT_RESET",
    CREATE_CLIENT_ERROR: "CREATE_CLIENT_ERROR",
    CREATE_CLIENT_ERROR_RESET: "CREATE_CLIENT_ERROR_RESET",

    SET_SINGLE_CLIENT_LOADING: "SET_SINGLE_CLIENT_LOADING",
    SET_SINGLE_CLIENT: "SET_SINGLE_CLIENT",
    RESET_SINGLE_CLIENT: "RESET_SINGLE_CLIENT",

    SET_CLIENT_TYPE: "SET_CLIENT_TYPE",
    SET_CLIENT_ACTIVE_TAB: "SET_CLIENT_ACTIVE_TAB",


    SET_DELETE: "SET_DELETE",
    RESET_DELETE: "RESET_DELETE",

    SET_FETCH_TYPE: "SET_FETCH_TYPE",

};