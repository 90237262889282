import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import IndustryType from '../Components/IndustrySelection/IndustryType/IndustryType'
import Login from "../Components/Auth/Login/Login";
import Signup from "../Components/Auth/Signup/Signup";
import ForgotPassword from "../Components/Auth/ForgotPassword/ForgotPassword";
import SendOtp from "../Components/Auth/ForgotPassword/SendOtp/SendOtp";
import NewPassword from "../Components/Auth/ForgotPassword/NewPassword/NewPassword";
import ScrollToTop from "../CustomHooks/useScrolltoTop";
import AllEmployees from "../Components/AllEmployees/AllEmployees";
import EmployeesManagement from "../Components/EmployeesManagement/EmployeesManagement";
import AccessManagement from "../Components/AccessManagement/AccessManagement";
import Documents from "../Components/Documents/Documents";
import Folders from "../Components/Folders/Folders";
// import SubDocuments from "../Components/Documents/SubDocuments/SubDocuments";
import Auth from "../Classes/Auth";
import Cookies from "../Classes/Cookies";
import FAQ from "../Components/FAQ/FAQ";
import Settings from "../Components/Settings/Settings";
import Support from "../Components/Support/Support";
import EmployeeDetail from "../Components/EmployeeDetail/EmployeeDetail";
import Notifications from "../Components/Notifications/Notifications";
import LandingPage from "../Components/LandingPage/LandingPage";
import SliderTest from "../Components/Slider/Slider";
import MessageToast from "../CommanComponents/MessageToast/MessageToast";
import Dashboard from "../Components/DashBoard";
import Clients from "../Components/Clients/Clients";
import AddClients from "../Components/Clients/AddClients/AddClients";
import VerifyEmailSuccess from "../Components/Auth/VerifyEmailSuccess/VerifyEmailSuccess";
import Users from "../Components/Users/Users";
import UserDetail from "../Components/Users/UserDetail/UserDetail";
import ForceToChangePass from "../Components/Auth/ForceToChangePass/ForceToChangePass";
import LoaderFetching from "../CommanComponents/LoaderFetching/LoaderFetching";
import RegisteredUser from "../Components/AdminUser/RegisteredUser/RegisteredUser";
import CommanErrror from "../CommanComponents/CommanErrror/CommanErrror";
import CompanyDocuments from "../Components/CompanyDocuments/CompanyDocuments";
import RecycleBin from "../Components/RecycleBin/RecycleBin";
import AiChat from "../Components/AiChat/AiChat";
import ManageCompany from "../Components/ManageCompany/ManageCompany";
import AddCompany from "../Components/ManageCompany/AddCompany/AddCompany";
const AppRouting = () => {
    function AuthenticatedRoute({ Component }) {
        return Auth.isLogin() ? <Dashboard><Component /></Dashboard> : <Navigate to="/login" />;
    }
    function PublicRoute({ Component }) {
        return !Auth.isLogin() ? <Component /> : <Navigate to={Auth?.boolUserType() !== 5 ? "/summary" : `/employees-management/id=${Auth?.user()?.employee_id}`} />;
    }

    const Logout = () => {
        return Auth.logout(), <Navigate to="/login" />
    }

    console.log(Auth?.user(), "successddatasss 11111")
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <MessageToast />
            <ForceToChangePass />
            <LoaderFetching />
            <CommanErrror />
            <Routes >
                {/* <Route path="/" element={<PublicRoute Component={Login} />} /> */}
                <Route path="/" element={<PublicRoute Component={LandingPage} />} />
                <Route path="/login" element={<PublicRoute Component={Login} />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/verify-successfull" element={<VerifyEmailSuccess />} />
                <Route path="/create-account/" element={<PublicRoute Component={Signup} />} />
                <Route path="/forgot-password/" element={<PublicRoute Component={ForgotPassword} />} />
                <Route path="/forgot-password/verify-otp" element={<PublicRoute Component={SendOtp} />} />
                <Route path="/forgot-password/confirm-new-password" element={<PublicRoute Component={NewPassword} />} />
                <Route path="*" element={Auth.isLogin() ? <Dashboard>Page not found blue!</Dashboard> : 'Page not found blue!'} />
                <Route path="/summary" element={<AuthenticatedRoute Component={AllEmployees} />} />
                {Auth?.boolUserType() !== 5 && <Route path="/employees-management" element={<AuthenticatedRoute Component={EmployeesManagement} />} />}
                <Route path="/access-management" element={<AuthenticatedRoute Component={AccessManagement} />} />
                <Route path="/documents/:document_param" element={<AuthenticatedRoute Component={Documents} />} />
                {/* <Route path="/documents/:document_ID" element={<AuthenticatedRoute Component={SubDocuments} />} /> */}
                <Route path="/folders" element={<AuthenticatedRoute Component={Folders} />} />
                {/* <Route path="/folders/:folder_ID" element={<AuthenticatedRoute Component={SubFolder} />} /> */}
                <Route path="/settings" element={<AuthenticatedRoute Component={Settings} />} />
                <Route path="/support" element={<AuthenticatedRoute Component={Support} />} />
                <Route path="/faq" element={<AuthenticatedRoute Component={FAQ} />} />
                <Route path="/employees-management/:employee_ID" element={<AuthenticatedRoute Component={EmployeeDetail} />} />
                <Route path="/notifications" element={<AuthenticatedRoute Component={Notifications} />} />
                <Route path="/clients-management" element={<AuthenticatedRoute Component={Clients} />} />
                <Route path="/clients-management/:client_id" element={<AuthenticatedRoute Component={AddClients} />} />
                <Route path="/user" element={<AuthenticatedRoute Component={Users} />} />
                <Route path="/user/user-details" element={<AuthenticatedRoute Component={UserDetail} />} />
                <Route path="/snapit-admin" element={<AuthenticatedRoute Component={RegisteredUser} />} />
                <Route path="/company-documents" element={<AuthenticatedRoute Component={CompanyDocuments} />} />
                <Route path="/recycle" element={<AuthenticatedRoute Component={RecycleBin} />} />
                {/* <Route path="/chat" element={<AuthenticatedRoute Component={AiChat} />} /> */}
                <Route path="/chat" element={<Dashboard><AiChat /></Dashboard>} />
                <Route path="/manage-companies" element={<AuthenticatedRoute Component={ManageCompany} />} />
                <Route path="/manage-companies/:client_id" element={<AuthenticatedRoute Component={AddCompany} />} />
                {/* for testing purpose */}
                <Route path="slider" element={<SliderTest />} />
            </Routes>
        </BrowserRouter>
    )
}
export default AppRouting