import AdminRegisterUserReq from "./AdminRegisterUserReq";
import AdminRegisterUserAction from "./actionType";


const getAllRegisteredUserList = (type) => {
    return async (dispatch) => {
        dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_LOADING })
        AdminRegisterUserReq.get(`${AdminRegisterUserReq.endpoint.get_all_user_list.replace("__TYPE__", type)}`, (success) => {
            dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_SUCCESS, payload: success.data })
        },
            (error) => {

            }
        )
    }
}
const getUserLoginHistory = (user_id) => {
    return async (dispatch) => {
        dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_LOGIN_HISTORY_LOADING })
        AdminRegisterUserReq.get(`${AdminRegisterUserReq.endpoint.user_login_history.replace("__USER_ID__", user_id)}`, (success) => {
            dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_LOGIN_HISTORY, payload: success.data })
        },
            (error) => {

            }
        )
    }
}

// const setRemider2 = (id, docType, data) => {
//     return async (dispatch) => {
//         remindersReq.post(remindersReq.endpoint.reminder_2.replace('__EMPLOYEE__ID', id)
//             .replace('__VALUE__', docType), data, (success) => {
//                 dispatch(showSuccessToast('Update successfully!'))
//                 dispatch(getReminderList(id))
//             }, (error) => {

//             }
//         )
//     }
// }


export { getAllRegisteredUserList, getUserLoginHistory }