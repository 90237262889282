import React, { useState } from 'react'
import './personalSetting.scss'
import Auth from '../../../Classes/Auth'
import Dropdown from '../../../CommanComponents/Dropdown/Dropdown'
import CSC from '../../../CommanComponents/CSC/CSC'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
import PhoneInput from 'react-phone-input-2'
import usePersonalSettings from '../../../CustomHooks/Settings/usePersonalSettings'
import CountrySelector from '../../../CommanComponents/CSC2/CountrySelector'
import StateSelector from '../../../CommanComponents/CSC2/StateSelector'
import CitySelector from '../../../CommanComponents/CSC2/CitySelector'
import { useSelector } from 'react-redux'
import InputDatePicker from '../../../CommanComponents/Form/InputDatePicker'
import { use18YearsAgoDate } from '../../../CustomHooks/useTodayDate'
import FormError from '../../../CommanComponents/Form/FormError'
const PersonalSetting = () => {
    const { BusOwnerData, typingData, individualData, handleInput,
        handleGenderChange, handlePhoneInput, handleDateInput, handleSaveBtn, handleCancelBtn, showError,
        regexError } = usePersonalSettings()
    const [dateValue, setDateValue] = useState(null)
    const userDetails = Auth.user();
    const countryStateCity = useSelector((state) => state.countryStateCity)
    console.log(Auth.boolUserType() === 2 || Auth.boolUserType() === 4, "qsdgef")
    const Years18AgoDate = use18YearsAgoDate()

    console.log(Auth?.getParentUser(), "dsfsdgdhfgdh")
    console.log(Auth?.user(), "dsfsdgdhfgdh 11111111111111")
    return (
        <div className="personal-settings-main-wrapper">
            <div className="from-wrapper">
                <form className="form">
                    {Auth.boolUserType() === 1 &&
                        <div className="form-control">
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Business Name* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.business_name?.value}
                                    name='business_name'
                                    onChange={handleInput}
                                />
                                <FormError show={(!BusOwnerData?.business_name?.isValid && showError) || regexError === 'business_name'} error={regexError === 'business_name' ? "Please enter only alphabetical character." : 'Please enter business name.'} />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Contact Person Name* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.contact_person_name?.value}
                                    name='contact_person_name'
                                    onChange={handleInput}
                                />
                                <FormError show={(!BusOwnerData?.contact_person_name?.isValid && showError) || regexError === 'contact_person_name'} error={regexError === 'contact_person_name' ? "Please enter only alphabetical character." : 'Please enter contact person name.'} />
                            </div>

                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Email* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.email?.value}
                                    disabled={true}
                                    name='email'
                                />
                            </div>
                            <div className="form-field ">
                                <div className="mobile-no-wrap">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone No* </label>
                                    {/* <input type="text" /> */}
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        containerClass="custom-form-container"
                                        inputClass="custom-form-input"
                                        specialLabel
                                        value={`${BusOwnerData._phone.country_code} ${BusOwnerData._phone.value}`}
                                        onChange={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onKeyUp={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                        inputProps={{
                                            name: "_phone",
                                            autoFocus: false,
                                            placeholder: "Phone Number *",
                                        }}
                                        enableSearch
                                        name="mobileNo"
                                        searchPlaceholder="Search Country"
                                        disableSearchIcon
                                    />
                                    <FormError show={(!BusOwnerData?._phone?.isValid && showError)}
                                        error={
                                            BusOwnerData?._phone?.value?.length < 9 && BusOwnerData?._phone?.value?.length > 0
                                                ? 'Please enter correct phone number.'
                                                : 'Please enter phone number.'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Trade License No* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.business_licence_no?.value}
                                    name='business_licence_no'
                                    onChange={handleInput}
                                    maxLength={20}
                                />
                                <FormError show={(!BusOwnerData?.business_licence_no?.isValid && showError)} error={'Please enter trade license no.'} />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Emirates ID of Owner* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.Emirates_ID?.value}
                                    name='Emirates_ID'
                                    onChange={handleInput}
                                />
                                <FormError show={(!BusOwnerData?.Emirates_ID?.isValid && showError) || regexError === 'Emirates_ID'} error={regexError === 'Emirates_ID' ? "Please enter only numeric character without space." : 'Please enter emirates id.'} />
                            </div>
                            <div className="form-field ">
                                <div className="mobile-no-wrap">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Owner Phone No* </label>
                                    {/* <input type="text" /> */}
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        containerClass="custom-form-container"
                                        inputClass="custom-form-input"
                                        specialLabel
                                        value={`${BusOwnerData._phone.country_code} ${BusOwnerData._phone.value}`}
                                        onChange={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onKeyUp={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                        inputProps={{
                                            name: "owner_phone_no",
                                            autoFocus: false,
                                            placeholder: "Phone Number *",
                                        }}
                                        enableSearch
                                        name="mobileNo"
                                        searchPlaceholder="Search Country"
                                        disableSearchIcon
                                    />
                                    <FormError show={(!BusOwnerData?._phone?.isValid && showError)}
                                        error={
                                            BusOwnerData?._phone?.value?.length < 9 && BusOwnerData?._phone?.value?.length > 0
                                                ? 'Please enter correct phone number.'
                                                : 'Please enter phone number.'
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Address* </label>
                                <input
                                    type="text"
                                    value={BusOwnerData?.address?.value}
                                    name='address'
                                    onChange={handleInput}
                                />
                                <FormError show={(!BusOwnerData?.address?.isValid && showError)} error={'Please enter address.'} />
                            </div>
                            <div className="form-field address-wrap">
                                {/* <div className="other-address-detail">
                                    <CSC />
                                </div> */}
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Country*</label>
                                    <CountrySelector Defaultvalue={BusOwnerData?.country?.value} />
                                    <FormError show={!BusOwnerData?.country?.isValid && showError} error={'Please enter current country.'} />

                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > State*</label>
                                    <StateSelector Defaultvalue={BusOwnerData?.state?.value} />
                                    <FormError show={!BusOwnerData?.state?.isValid && showError} error={'Please enter current state.'} />
                                    {/* <input type="text" value={BusOwnerData?.state?.value} /> */}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > City*</label>
                                    <CitySelector Defaultvalue={BusOwnerData?.city?.value} />
                                    <FormError show={!BusOwnerData?.city?.isValid && showError} error={'Please enter current city.'} />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Zip code *</label>
                                    <input type="text"
                                        name='zip_code'
                                        maxLength={6}
                                        value={BusOwnerData?.zip_code?.value}
                                        onChange={handleInput}
                                    />
                                    <FormError show={regexError === "zip_code" || !BusOwnerData?.zip_code?.isValid && showError}
                                        error={regexError === "zip_code" ? "Please enter only numeric character without space." : "Zip code should not be less than 5 & more than 6 digits."}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {(Auth.boolUserType() === 2 || Auth.boolUserType() === 4) &&
                        <div className="form-control">
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Business Name* </label>
                                <input
                                    type="text"
                                    value={typingData?.business_name?.value}
                                    name='business_name'
                                    onChange={handleInput}
                                />
                                <FormError show={(!typingData?.business_name?.isValid && showError) || regexError === 'business_name'} error={regexError === 'business_name' ? "Please enter only alphabetical character." : 'Please enter business name.'} />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Contact Person Name* </label>
                                <input type="text"
                                    value={typingData?.contact_person_name?.value}
                                    name='contact_person_name'
                                    onChange={handleInput}
                                />
                                <FormError show={!typingData?.contact_person_name?.isValid && showError} error={'Please enter contact person name.'} />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Email* </label>
                                <input
                                    type="text"
                                    value={typingData?.email?.value}
                                    name='email'
                                    onChange={handleInput}
                                    disabled={true}
                                />
                            </div>

                            <div className="form-field ">
                                <div className="mobile-no-wrap">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone No* </label>
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        containerClass="custom-form-container"
                                        inputClass="custom-form-input"
                                        specialLabel
                                        value={`${typingData._phone.country_code} ${typingData._phone.value}`}
                                        onChange={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onKeyUp={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                        inputProps={{
                                            name: "_phone",
                                            autoFocus: false,
                                            placeholder: "Phone Number *",
                                        }}
                                        enableSearch
                                        name="mobileNo"
                                        searchPlaceholder="Search Country"
                                        disableSearchIcon
                                    />
                                    <FormError show={!typingData._phone.isValid && showError}
                                        error={
                                            typingData?._phone?.value?.length < 9 && typingData?._phone?.value?.length > 0
                                                ? 'Please enter correct phone number.'
                                                : 'Please enter phone number.'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {Auth.boolUserType() === 3 &&
                        <div className="form-control">
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > First Name* </label>
                                <input
                                    type="text"
                                    value={individualData?.first_name?.value}
                                    name='first_name'
                                    onChange={handleInput}
                                />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Last Name* </label>
                                <input
                                    type="text"
                                    value={individualData?.last_name?.value}
                                    name='last_name'
                                    onChange={handleInput}
                                />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Email* </label>
                                <input
                                    type="text"
                                    value={individualData?.email?.value}
                                    name='email'
                                    onChange={handleInput}
                                    disabled={true}

                                />
                            </div>
                            {/* <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone No* </label>
                                <input type="text" />
                            </div> */}
                            <div className="form-field ">
                                <div className="mobile-no-wrap">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone No* </label>
                                    {/* <input type="text" /> */}
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        containerClass="custom-form-container"
                                        inputClass="custom-form-input"
                                        specialLabel
                                        value={`${individualData?._phone?.country_code} ${individualData?._phone?.value}`}
                                        onChange={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onKeyUp={(value, formattedValue, nameValue) => {
                                            handlePhoneInput(value, formattedValue, nameValue);
                                        }}
                                        onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                        inputProps={{
                                            name: "_phone",
                                            autoFocus: false,
                                            placeholder: "Phone Number *",
                                        }}
                                        enableSearch
                                        name="mobileNo"
                                        searchPlaceholder="Search Country"
                                        disableSearchIcon
                                    />
                                </div>
                            </div>
                            {/* <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Business Full Name* </label>
                                <input
                                    type="text"
                                    value={individualData?.business_name?.value}
                                />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Emirates ID* </label>
                                <input
                                    type="text"
                                    value={individualData?.emirates_id?.value}
                                />
                            </div>
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Nationality* </label>
                                <input
                                    type="text"
                                    value={individualData?.nationality?.value}
                                />
                            </div> */}
                            <div className="form-field ">
                                <label htmlFor="" className='mb-3 d-block text-xxs'  > Address* </label>
                                <input
                                    type="text"
                                    value={individualData?.address?.value}
                                    name='address'
                                    onChange={handleInput}
                                />
                            </div>
                            <div className="form-field">
                                {/* <div className="dob-wrap d-flex align-center">
                                    <div className='bob_para text-ss w-400 secondary'>{individualData?.dob?.value ? individualData?.dob?.value : "DOB *"}</div>
                                    <input required={true} type="date"
                                        name=""
                                        value={individualData?.dob?.value} onChange={(e) => setDateValue(e.target.value)} />
                                </div> */}
                                <div className="datePickerWrap">
                                    <InputDatePicker
                                        name="dob"
                                        type="date"
                                        maxDate={Years18AgoDate}
                                        dateFormat="dd-MM-yyyy"
                                        onSelect={(e) => {
                                            handleDateInput(e, 'dob')
                                        }}
                                        placeholder="DD-MM-YYYY"
                                        value={individualData?.dob?.value ? individualData?.dob?.value : ''}
                                        className={'date-picker-auth'}
                                    />
                                </div>
                                {console.log(individualData?.dob?.value, "Zfgdgdfv")}
                            </div>
                            {console.log(individualData?.gender?.value, "Axcsfd")}
                            <div className="form-field dob-main-wrappper ">
                                <div className={`dob-gender-wrap`}>
                                    <Dropdown
                                        key={individualData?.gender?.value}
                                        ClassName={'gender-dropdown'}
                                        padding={'12.5px 16px'}
                                        borderRadius={'8px'}
                                        textAlign={'left'}
                                        data={['Male', 'Female']}
                                        clickHandle={(val) => handleGenderChange(val)}
                                        defaultValue={individualData?.gender?.value}
                                    />
                                </div>
                            </div>

                            {/* <div className="form-field address-wrap">
                                <div className="other-address-detail">
                                    <CSC />
                                </div>
                            </div> */}
                            <div className="form-field address-wrap">

                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Country*</label>
                                    <CountrySelector Defaultvalue={individualData?.country?.value} />

                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > State*</label>
                                    <StateSelector Defaultvalue={individualData?.state?.value} />
                                    {/* <input type="text" value={individualData?.state?.value} /> */}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > City*</label>
                                    <CitySelector Defaultvalue={individualData?.city?.value} />

                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Zip code *</label>
                                    <input type="text"
                                        name='zip_code'
                                        maxLength={6}
                                        value={individualData?.zip_code?.value}
                                        onChange={handleInput}
                                    />

                                </div>
                            </div>
                        </div>
                    }
                    <div className="save-btn-wrap d-flex mt-60 ">
                        <CommanButton Text={'Save'} ClassName={'save-btn'} onClick={handleSaveBtn} />
                        <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={handleCancelBtn} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PersonalSetting