const CompanyDocumentsAction = {
    CREATE_COMPANY_DOCS_SUCCESS: "CREATE_COMPANY_DOCS_SUCCESS",
    CREATE_COMPANY_DOCS_ERROR: "CREATE_COMPANY_DOCS_ERROR",
    CREATE_COMPANY_DOCS_ERROR_RESET: "CREATE_COMPANY_DOCS_ERROR_RESET",
    CREATE_COMPANY_DOCS_RESET: "CREATE_COMPANY_DOCS_RESET",

    COMPANY_DOCS_LIST_LOADING: "COMPANY_DOCS_LIST_LOADING",
    COMPANY_DOCS_LIST_LOADED: "COMPANY_DOCS_LIST_LOADED",
    COMPANY_DOCS_LIST_RESET: "COMPANY_DOCS_LIST_RESET",

    DELETE_COMPANY_DOCS_SUCCESS: "DELETE_COMPANY_DOCS_SUCCESS",
    DELETE_COMPANY_DOCS_FILES_SUCCESS: "DELETE_COMPANY_DOCS_FILES_SUCCESS",

    RENAME_COMPANY_DOCS_SUCCESS: "RENAME_COMPANY_DOCS_SUCCESS",
    RENAME_COMPANY_DOCS_RESET: "RENAME_COMPANY_DOCS_RESET",

    COMPANY_DOCS_SEARCH_SUCCESS: "COMPANY_DOCS_SEARCH_SUCCESS",
    COMPANY_DOCS_SEARCH_RESET: "COMPANY_DOCS_SEARCH_RESET",

    COMPANY_DOCS_DETAIL_SET: "COMPANY_DOCS_DETAIL_SET",
    COMPANY_DOCS_DETAIL_RESET: "COMPANY_DOCS_DETAIL_RESET",

    SET_BREADCRUMB: "SET_BREADCRUMB",


    SET_REMINDER_SUCCESS: "SET_REMINDER_SUCCESS",
    RESET_REMINDER_SUCCESS: "RESET_REMINDER_SUCCESS",
    SET_REMINDER_ERROR: "SET_REMINDER_ERROR",
    RESET_REMINDER_ERROR: "RESET_REMINDER_ERROR",


    COMPANY_DOCS_NOTIFICATION_TRIGGER_SET: "COMPANY_DOCS_NOTIFICATION_TRIGGER_SET",
    COMPANY_DOCS_NOTIFICATION_SUCCESS: "COMPANY_DOCS_NOTIFICATION_SUCCESS",

    COMPANY_DOCS_SET_CLIENT: "COMPANY_DOCS_SET_CLIENT",

}

export default CompanyDocumentsAction