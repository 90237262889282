import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Assets/style/_theme.scss";
import "react-phone-input-2/lib/style.css";
import AppRouting from "./AppRouting";
import { Provider } from "react-redux";
import Store from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Provider store={Store}>
      <AppRouting />
    </Provider>
  );
}

export default App;
