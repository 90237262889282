import { error } from "pdf-lib"
import CompanyDocumentsAction from "../Actions/CompanyDocuments/actionType"

const initState = {
    loading: false,
    data: [],
    created: false,
    error_msg: '',
    rename: false,
    folder_data: [],
    breadcrumb: '',
    reminder: {
        loading: false,
        data: [],
        success: false,
        error: false
    },
    notification: {
        data: [],
        trigger: false
    },
    client: {
        business_name: "Select client*"
    },

}

const companyDocuments = (state = initState, { type, payload }) => {
    switch (type) {
        case CompanyDocumentsAction.CREATE_COMPANY_DOCS_SUCCESS: return {
            ...state,
            created: true
        }
        case CompanyDocumentsAction.CREATE_COMPANY_DOCS_ERROR: return {
            ...state,
            error_msg: payload
        }
        case CompanyDocumentsAction.CREATE_COMPANY_DOCS_ERROR_RESET: return {
            ...state,
            error_msg: ''
        }
        case CompanyDocumentsAction.CREATE_COMPANY_DOCS_RESET: return {
            ...state,
            created: false
        }
        case CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADING: return {
            ...state,
            loading: true,
        }
        case CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADED: return {
            ...state,
            loading: false,
            data: payload
        }
        case CompanyDocumentsAction.COMPANY_DOCS_LIST_RESET: return {
            ...state,
            data: []
        }
        case CompanyDocumentsAction.RENAME_COMPANY_DOCS_SUCCESS: return {
            ...state,
            rename: true
        }
        case CompanyDocumentsAction.RENAME_COMPANY_DOCS_RESET: return {
            ...state,
            rename: false
        }
        case CompanyDocumentsAction.COMPANY_DOCS_SEARCH_SUCCESS: return {
            ...state,
            data: payload
        }
        // case CompanyDocumentsAction.COMPANY_DOCS_DETAIL_SET: return {
        //     ...state,
        //     data: [],
        //     folder_data: payload
        // }
        // case CompanyDocumentsAction.COMPANY_DOCS_DETAIL_RESET: return {
        //     ...state,
        //     folder_data: []
        // }
        case CompanyDocumentsAction.SET_BREADCRUMB: return {
            ...state,
            breadcrumb: payload
        }
        case CompanyDocumentsAction.SET_REMINDER_SUCCESS: return {
            ...state,
            reminder: {
                ...state?.reminder,
                success: true
            }
        }
        case CompanyDocumentsAction.RESET_REMINDER_SUCCESS: return {
            ...state,
            reminder: {
                ...state?.reminder,
                success: false
            }
        }
        case CompanyDocumentsAction.SET_REMINDER_ERROR: return {
            ...state,
            reminder: {
                ...state?.reminder,
                error: payload
            }
        }
        case CompanyDocumentsAction.RESET_REMINDER_ERROR: return {
            ...state,
            reminder: {
                ...state?.reminder,
                error: false
            }
        }

        case CompanyDocumentsAction.COMPANY_DOCS_NOTIFICATION_TRIGGER_SET: return {
            ...state,
            notification: {
                ...state?.notification,
                trigger: true
            }
        }
        case CompanyDocumentsAction.COMPANY_DOCS_NOTIFICATION_SUCCESS: return {
            ...state,
            notification: {
                ...state?.notification,
                data: payload
            }
        }
        case CompanyDocumentsAction.COMPANY_DOCS_SET_CLIENT: return {
            ...state,
            client: payload
        }
        default: return state
    }
}

export default companyDocuments