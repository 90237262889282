import Request from "../../../Classes/Requests";

class aiChatReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            // ai_chat: super.url("/api/chat/"),
        }
    }
}
export default new aiChatReq();
