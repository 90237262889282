import FoldersAction from "../Actions/Folders/actionType"

const initState = {
    loading: true,
    data: [],
    parentFolders: [],
    created: false,
    error_msg: '',
    rename: false,
    folder_data: [],
    breadcrumb: '',
    client: {
        business_name: "Select client*"
    },
}

const folders = (state = initState, { type, payload }) => {
    switch (type) {
        case FoldersAction.CREATE_FOLDER_SUCCESS: return {
            ...state,
            created: true
        }
        case FoldersAction.CREATE_FOLDER_ERROR: return {
            ...state,
            error_msg: payload
        }
        case FoldersAction.CREATE_FOLDER_ERROR_RESET: return {
            ...state,
            error_msg: ''
        }
        case FoldersAction.CREATE_FOLDER_RESET: return {
            ...state,
            created: false
        }
        case FoldersAction.FOLDER_LIST_LOADING: return {
            ...state,
            loading: true,
        }
        case FoldersAction.FOLDER_LIST_LOADED: return {
            ...state,
            loading: false,
            data: payload,
            parentFolders: payload
        }
        case FoldersAction.FOLDER_LIST_RESET: return {
            ...state,
            data: []
        }
        case FoldersAction.RENAME_FOLDER_SUCCESS: return {
            ...state,
            rename: true
        }
        case FoldersAction.RENAME_FOLDER_RESET: return {
            ...state,
            rename: false
        }
        case FoldersAction.FOLDER_SEARCH_SUCCESS: return {
            ...state,
            data: payload
        }
        // case FoldersAction.FOLDER_DETAIL_SET: return {
        //     ...state,
        //     data: [],
        //     folder_data: payload
        // }
        // case FoldersAction.FOLDER_DETAIL_RESET: return {
        //     ...state,
        //     folder_data: []
        // }
        case FoldersAction.SET_BREADCRUMB: return {
            ...state,
            breadcrumb: payload
        }
        case FoldersAction.FOLDER_SET_CLIENT: return {
            ...state,
            client: payload
        }
        default: return state
    }
}

export default folders