import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReminderList, setRemider2, setReminders } from '../../store/Actions/Reminders'
import { useParams } from 'react-router-dom'
import { useTodayDate } from '../useTodayDate'

const useReminders = () => {
    const reminders = useSelector((state) => state.reminders)
    const dispatch = useDispatch()
    const { employee_ID } = useParams()
    const get_employee_id = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    const [date, setDate] = useState(null)
    const [reminder1, setReminder1] = useState(null)
    const [reminder3, setReminder3] = useState(null)
    const todayDate = useTodayDate()

    const handleInputChange = (e, docType, reminderType) => {
        const inputValue = e.target.value;
        // setDate(inputValue);
        const Reminder_1 = { "reminder_1": inputValue }
        const Reminder_2 = { "reminder_2": inputValue }
        const Reminder_3 = { "reminder_3": inputValue }
        // let param;
        // if (docType === "Emirates ID") {
        //     param = 'Emirates_ID'
        // } else if (docType === "Passport") {
        //     param = 'Passport'
        // }
        switch (reminderType) {
            case "Reminder_1": return dispatch(setReminders(get_employee_id, Reminder_1))
            // case "Reminder_2": return dispatch(setRemider2(get_employee_id, param, data))
            case "Reminder_2": return dispatch(setReminders(get_employee_id, Reminder_2))
            case "Reminder_3": return dispatch(setReminders(get_employee_id, Reminder_3))
            default:
                break;
        }

    }

    useEffect(() => {
        const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
        dispatch(getReminderList(data))
    }, [])
    return { reminders, handleInputChange, date, todayDate, reminder1, reminder3 }
}

export default useReminders