import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRegisteredUserList, getUserLoginHistory } from '../../../store/Actions/AdminRegisteredUser'
import Auth from '../../../Classes/Auth'

const useRegisterUser = () => {
    // const adminRegisteredUser = useSelector((e => e.adminRegisteredUser))
    const adminRegisteredUser = useSelector((e => e.adminRegisteredUser))
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user 
    const selectTypeFilterHandler = (type) => {
        let data = null;
        if (type === "Business Owner") {
            data = 1
        } else if (type === "Typing center") {
            data = 2
        } else if (type === "Individuals") {
            data = 3
        }
        dispatch(getAllRegisteredUserList(data))
    }

    useEffect(() => {
        dispatch(getAllRegisteredUserList(null))
    }, [])

    const loginHistoryRef = useRef()

    const openLoginHistory = (id) => {
        loginHistoryRef.current.open();
        dispatch(getUserLoginHistory(id))
    }

    const closeLoginHistory = () => {
        loginHistoryRef.current.close();
    }

    console.log(adminRegisteredUser, "Dcvsdghrb")
    return { adminRegisteredUser, selectTypeFilterHandler, loginHistoryRef, openLoginHistory, closeLoginHistory }
}

export default useRegisterUser