import Request from "../../../Classes/Requests";

class recycleBinReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            get_deleted_folders: super.url("/get_deleted_folders?added_by_user_id=__USER_ID__"),
            delete_all: super.url("/delete_all?added_by_user_id=__USER_ID__"),
            restore_single: super.url("/restore_single"),
            delete_single: super.url("/delete_single"),
        }
    }
}
export default new recycleBinReq();
