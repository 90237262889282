// import React, { useEffect, useRef, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { getAiChatResponse, resetChataI } from '../../store/Actions/AiChat'
// import Auth from '../../Classes/Auth'
// import axios from 'axios'

// const useAiChat = () => {
//     const aiChat = useSelector((e => e.aiChat))
//     const dispatch = useDispatch()
//     const userId = Auth?.user()?.user_id // this is for added by user

//     const [messages, setMessages] = useState([]);
//     const [input, setInput] = useState('');
//     const [isBotLoading, setIsBotLoading] = useState(false);
//     const [abortController, setAbortController] = useState(null); // State for AbortController
//     const [listening, setListening] = useState(false);
//     const chatEndRef = useRef(null); // Ref for scrolling to the bottom

//     // Speech recognition setup
//     const recognition = useRef(null);

//     useEffect(() => {
//         if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
//             alert('Speech Recognition API is not supported in this browser');
//             return;
//         }

//         recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
//         recognition.current.continuous = true;
//         recognition.current.interimResults = true;

//         recognition.current.onresult = (event) => {
//             const transcript = event.results[event.results.length - 1][0].transcript;
//             setInput(transcript);  // Set recognized text into input
//         };

//         recognition.current.onend = () => {
//             if (listening) {
//                 setListening(false);
//             }
//         };
//     }, [listening]);



//     const handleMicClick = () => {
//         if (listening) {
//             recognition.current.stop();
//             setListening(false);
//         } else {
//             recognition.current.start();
//             setListening(true);
//         }
//     };
//     const handleSend = async () => {
//         if (!input) return;

//         // Add user message
//         setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);

//         // Prepare data for API call
//         const data = {
//             message: input,
//             value_fontend: userId,
//         };

//         // Abort the previous request if it exists
//         if (abortController) {
//             abortController.abort();
//         }
//         const controller = new AbortController();
//         setAbortController(controller); // Update the state with the new controller

//         setInput('');
//         setIsBotLoading(true); // Set loading to true
//         dispatch(getAiChatResponse(data, controller.signal))

//         const botResponse = aiChat?.data?.response;

//         // setMessages((prevMessages) => [...prevMessages, { text: botResponse, sender: 'bot' }]);

//     };

//     const onKeyPressHandler = (e) => {
//         if (e.key === 'Enter') {
//             handleSend();
//         }
//     };

//     useEffect(() => {
//         // Handle updates to the AI chat state if necessary
//         if (aiChat.data?.response) {
//             setMessages((prevMessages) => [...prevMessages, { text: aiChat.data.response, sender: 'bot' }]);
//             setIsBotLoading(false); // Reset loading state
//         }
//     }, [aiChat.data?.response])

//     useEffect(() => {
//         dispatch(resetChataI())
//         setMessages([])
//     }, [])
//     console.log(input, "ASfsagfv")
//     return {
//         aiChat, input, messages, setInput, handleSend, onKeyPressHandler, isBotLoading,
//         listening, chatEndRef, recognition, handleMicClick



//     }
// }

// export default useAiChat


import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAiChatResponse, resetChataI } from '../../store/Actions/AiChat';
import Auth from '../../Classes/Auth';

const useAiChat = () => {
    const aiChat = useSelector((e => e.aiChat));
    const dispatch = useDispatch();
    const userId = Auth?.user()?.user_id;
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isBotLoading, setIsBotLoading] = useState(false);
    const [abortController, setAbortController] = useState(null);
    const [listening, setListening] = useState(false);
    const chatEndRef = useRef(null);
    const recognition = useRef(null);
    const finalTranscriptRef = useRef(''); // Use ref instead of state

    useEffect(() => {
        if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
            alert('Speech Recognition API is not supported in this browser');
            return;
        }

        recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        recognition.current.continuous = false;
        recognition.current.interimResults = true;

        recognition.current.onresult = (event) => {
            const transcript = event.results[event.results.length - 1][0].transcript;
            if (event.results[event.results.length - 1].isFinal) {
                finalTranscriptRef.current = transcript; // Set the final transcript in ref
            } else {
                setInput(transcript); // Show interim results in the input
            }
        };

        recognition.current.onend = () => {
            setListening(false);
            console.log(finalTranscriptRef.current, "Final transcript"); // Log ref value
            if (finalTranscriptRef.current) {
                setInput(finalTranscriptRef.current); // Update input with the final transcript
                handleSend(finalTranscriptRef.current); // Trigger send with the final transcript
                setInput(''); // Clear the input after sending
            }
        };
    }, []);

    const handleMicClick = () => {
        if (listening) {
            recognition.current.stop();
            setListening(false);
        } else {
            recognition.current.start();
            setListening(true);
        }
    };

    const handleSend = async (message = input) => {
        if (!message) return;

        // Add user message
        setMessages((prevMessages) => [...prevMessages, { text: message, sender: 'user' }]);

        const data = {
            message,
            value_fontend: userId,
        };

        if (abortController) {
            abortController.abort();
        }
        const controller = new AbortController();
        setAbortController(controller);

        setInput('');
        finalTranscriptRef.current = ''; // Clear the final transcript after sending
        setIsBotLoading(true);
        dispatch(getAiChatResponse(data, controller.signal));
    };

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    useEffect(() => {
        if (aiChat.data?.response) {
            setMessages((prevMessages) => [...prevMessages, { text: aiChat.data.response, sender: 'bot' }]);
            setIsBotLoading(false);
        }
    }, [aiChat.data?.response]);

    useEffect(() => {
        dispatch(resetChataI());
        setMessages([]);
    }, []);

    return {
        aiChat, input, messages, setInput, handleSend, onKeyPressHandler, isBotLoading,
        listening, chatEndRef, recognition, handleMicClick
    };
};

export default useAiChat;
