import Request from "../../../Classes/Requests";

class manageClientReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            get_sub_companies: super.url("/get_sub_companies"),
            get_sub_company: super.url("/get_sub_company"),
            add_sub_company: super.url("/add_sub_company"),
            update_sub_company: super.url("/update_sub_company"),
            company_login: super.url("/company_login"),
            delete_sub_company: super.url("/delete_sub_company"),
            search_clients: super.url("/search_clients?typing_center_id=__TC_ID__&query=__VALUE__"),

            employee_search: super.url("/employee_search?added_by_user_id=__USER_ID__&query=__VALUE__"),
            //  /employee_search?added_by_user_id=305&query=guddu

        }
    }
}
export default new manageClientReq();
