import React, { useEffect, useRef, useState } from 'react';
import "./aiChat.scss";
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading';
import useAiChat from '../../CustomHooks/AiChat/useAiChat';
import SearchControl from '../../CommanComponents/SearchControl/SearchControl';
import AiChatLoader from '../../CommanComponents/AiChatLoader/AiChatLoader';
import speechLoading from '../../Assets/images/speech-bubble.gif';
import aiIconInput from '../../Assets/images/Ai-Working_Loader.gif';
import Typewriter from '../Typewriter/Typewriter';

const AiChat = () => {
    const { aiChat, messages, input, setInput, handleSend, onKeyPressHandler, isBotLoading, listening, chatEndRef, recognition, handleMicClick } = useAiChat();

    // Scroll to the bottom of the chat area
    const scrollToBottom = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Effect to scroll when messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    return (
        <div className="aiChat-main-wrapper">
            <div className="notification-wrap">
                <div className="heading-search-item justify-between align-center">
                    <SectionHeading ClassName={" "}>
                        Talk to your Documents
                    </SectionHeading>
                </div>

                <div className="aiBoat-container">
                    <div className="content-wrapper">
                        <div className="message-main-container">
                            <div className="chat-area">
                                {messages?.map((msg, index) => (
                                    <div key={index} className={`message ${msg.sender}`}>
                                        <div className='response'>
                                            {msg.sender === "bot" ? (
                                                <div className="msg-item-wrap">
                                                    <img src={aiIconInput} alt="loading..." className='loading-img' />
                                                    <span className='msg-para'>
                                                        {msg?.text}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="msg-item-wrap">
                                                    <span className='msg-para'>
                                                        {msg?.text}
                                                    </span>
                                                    <div className='loading-img user-img'>
                                                        <i className="profile-icon"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {isBotLoading && aiChat?.loading && (
                                    <div className="message bot">
                                        <div className="response">
                                            <div className="msg-item-wrap">
                                                <img src={aiIconInput} alt="loading..." className='loading-img' />
                                                <span className='msg-para ai-fetch-loading'>
                                                    <AiChatLoader />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div ref={chatEndRef} />
                            </div>
                        </div>

                        <div className='d-flex align-center justify-center search-page-wrap'>
                            <div className="input-wrapper">
                                <input
                                    type='text'
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    onKeyPress={onKeyPressHandler}
                                    placeholder="Ask SnapIT AI.."
                                    classNameWrappper={'employees-search'}
                                />
                                <div>
                                    <div className="send-btn-wrapper">
                                        <button className={`d-block transparent send-button`} onClick={handleSend}>
                                            <i className='icons sT-icon white i-xs msg-send-icon'></i>
                                        </button>
                                    </div>
                                    <div className="send-btn-wrapper audio-recognize">
                                        <button
                                            className={`d-block transparent send-button`}
                                            id="microphoneButton"
                                            onClick={handleMicClick}
                                        >
                                            <i className={`icons sT-icon white i-xs ${listening ? "stop-microphone-icon" : "microphone-icon"} `}></i>

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AiChat;
