import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
// import { useEmployeePersonalDetails } from './useEmployeePersonalDetails';
import { DeletePassportImg, deleteUploadedDocument, deleteUploadedPassport, getListOfDocument, viewPassport, resetUpdateDocs, setUpdateDocs, setUploadDocs, updateUploadPassport, uploadDocImage, uploadEmiratesID, uploadPassport, uploadReset, viewEmiratesID, deleteEmiratesID, updateEmiratesID, DeleteEmiratesImg, setDocType, setPassportAnalyzer, resetPassportAnalyzerFrontSide, resetPassportAnalyzerBackSide } from '../../store/Actions/DocumentUpload';
import { getSingleEmployee } from '../../store/Actions/PersonalDetails';
import { useDispatch, useSelector } from 'react-redux';
import useHandleDownload from '../useHandleDownload';
import dummyImg from '../../Assets/images/noimage.svg';
import dataJSON from '../../CommanComponents/DynamicInputFileds/data.json'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Validation from '../../Classes/Validation';
import { resetReminderList } from '../../store/Actions/Reminders';
import { pdfjs } from 'react-pdf';
import Auth from '../../Classes/Auth';
import { showSuccessToast } from '../../store/Actions/SuccessMessagePopup';
import RegexValid from '../../Classes/RegexValid';
import { dateFormat, dateFormatYYYY_MM_DD, formatDateDatePicker } from '../dateFormat';
import { compressFile } from '../../HelperFunction/FileCompressor';
import ValidationFile from '../../Classes/ValidationFile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import grlod from '../../CustomHooks/PDFViewerHook/pdf.worker'
// pdfjs.GlobalWorkerOptions.workerSrc = '../../CustomHooks/PDFViewerHook/pdf.worker'

const useDocumentDetails = (onActiveDocChange, dynamicDropDownVal) => {
    const userId = Auth?.user()?.user_id // this is for added by user
    const dispatch = useDispatch()
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const personalDetails = useSelector((state) => state.personalDetails)
    const singleEmployee = personalDetails?.single_employee?.data
    const params = new URLSearchParams(window.location.search);
    const tabIndex = params.get("tabIndex");
    const Valid = new Validation()
    const validReg = new RegexValid()
    const [showDocError, setShowDocError] = useState(false);
    const [getFile, setGetFile] = useState(null);
    const [getFile2, setGetFile2] = useState(null);
    const [PDFImg, setPDFImg] = useState(null);
    const [PDFImg2, setPDFImg2] = useState(null);
    const [regexError, setRegexError] = useState();
    const [deletePopDoc, setDeletePopDoc] = useState(false)
    const [deleteDocType, setDeleteDocType] = useState('')
    const [newPassportAiData, setnewPassportAiData] = useState({})
    const [newEmiratesAiData, setnewEmiratesAiData] = useState({})
    const navigate = useNavigate()
    const [DynamicInputValues, setDynamicInputValues] = useState(
        {
            "Country_Code": {
                value: "",
                isValid: false
            },
            "type_of_document": {
                value: "",
                isValid: false
            },
            "Passport_No": {
                value: "",
                isValid: false
            },
            "Nationality": {
                value: "",
                isValid: false
            },
            "Gender": {
                value: "Gender *",
                isValid: false
            },
            "Date_of_Birth": {
                value: "",
                isValid: false
            },
            "Given_name": {
                value: "",
                isValid: false
            },
            "Place_of_Birth": {
                value: "",
                isValid: false
            },
            "Place_of_issue": {
                value: "",
                isValid: false
            },
            "Date_of_issue": {
                value: "",
                isValid: false
            },
            "Date_of_Expiry": {
                value: "",
                isValid: false
            },
            "Name_of_father": {
                value: "",
                isValid: true
            },
            "Name_of_mother": {
                value: "",
                isValid: true
            },
            "Name_of_Spouse": {
                value: "",
                isValid: true
            },
            "Address": {
                value: "",
                isValid: true
            },
            "Old_Passport_No_with_Date_and_Place_of_Issue": {
                value: "",
                isValid: true
            },
            "File_No": {
                value: "",
                isValid: true
            },
            // "Document_type": {
            //     value: "",
            //     isValid: false
            // },
            // "employee_id": {
            //     value: "",
            //     isValid: false
            // },
            Validation: false
        }
    );

    const [emiratesIDFileds, setEmiratesIDFileds] = useState(
        {
            "ID_Number": {
                value: "",
                isValid: false
            },
            "Name": {
                value: "",
                isValid: false
            },
            "Date_of_Birth": {
                value: "",
                isValid: false
            },
            "Nationality": {
                value: "",
                isValid: false
            },
            "Issuing_Date": {
                value: "",
                isValid: false
            },
            "Date_of_Expiry": {
                value: "",
                isValid: false
            },
            "Gender": {
                value: "Gender *",
                isValid: false
            },
            "Signature": {
                value: "",
                isValid: false
            },
            "Occupation": {
                value: "",
                isValid: false
            },
            "Employer": {
                value: "",
                isValid: false
            },
            "Issuing_Place": {
                value: "",
                isValid: false
            },
            "Card_Number": {
                value: "",
                isValid: false
            },
            Validation: false
        }
    );

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const client_id_param = searchParams.get('client_id')


    const documentUpload = useSelector((e) => e.documentUpload)

    let activeDoc = documentUpload.docType;


    const inputFile = useRef(null)
    const inputFile2 = useRef(null)
    const imageRef = useRef(null);
    const imageRef2 = useRef(null);
    const [fileName, setFileName] = useState("")
    const [fileName2, setFileName2] = useState("")
    const [edit_id, setEdit_id] = useState('')
    const [updateDynamicField, setUpdateDynamicField] = useState({})
    const [type, setType] = useState('')
    const { employee_ID } = useParams()

    // const EmployeeDocumentsList = ['Passport', 'Visa', 'Emirates ID', 'Medical insurance', 'Driving license', 'Family documents', 'Others']
    const EmployeeDocumentsList = ['Passport', 'Emirates ID', 'Others']

    useEffect(() => {
        if (employee_ID?.includes("id=") || employeeManagement?.employee_type == 'old') {
            setType("old")
            setEdit_id(employee_ID?.replace('id=', ''))
            if (personalDetails?.activeTab === "Documents") {
                const data = employee_ID?.includes("id=") && employee_ID?.replace("id=", "")
                if (data !== undefined && data !== null && client_id_param !== "undefined") {
                    dispatch(getListOfDocument(data))
                }
            }

        } else {
            setType("new")
        }
        if (personalDetails?.update) {
            const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
            // dispatch(getUploadedDocs(data))
            dispatch(getListOfDocument(data))
        }

    }, [employeeManagement?.employee_type, employee_ID, personalDetails?.update, documentUpload?.deleted, dispatch, personalDetails?.activeTab])
    // const [DynamicInputValues, setDynamicInputValues] = useState({}); 
    function onChangeFile(e, ref) {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
        console.log(file?.type, "fdf")
        if (file?.type == 'application/pdf' && ref === imageRef2) {
            setGetFile2(file)
        } else if (file?.type == 'application/pdf' && ref === imageRef) {
            setGetFile(file)
        }
        if (file?.type.includes("image") || file?.type === 'application/pdf') {
            // if (file.size <= maxSize) {
            if (true) {
                if (file) {
                    // dispatch(showSuccessToast('Uploded successfully!'))

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        ref.current.src = e.target.result

                        // Passport analyzer implementation
                        const formData = new FormData();
                        // formData.append("passport_image", file);
                        formData.append("file", file);

                        if (ref === imageRef) {
                            formData.append("back", 0);
                            setFileName(file.name)
                            // dispatch(resetPassportAnalyzerFrontSide())
                            console.log("this is side front")
                            // dispatch(setPassportAnalyzer(formData))  //Api for Passport analyze
                            dispatch(setPassportAnalyzer(formData, 0))  //Api for Passport analyze
                        } else {
                            formData.append("back", 1);
                            setFileName2(file.name)
                            console.log("this is side back")
                            // dispatch(resetPassportAnalyzerBackSide())
                            dispatch(setPassportAnalyzer(formData, 1))
                        }




                    };
                    reader.readAsDataURL(file);
                    // e.target.value = null;
                }
            } else {
                alert('File size exceeds the limit of 2 MB');
                e.target.value = null;
            }
        }
        else {
            alert('Accept only .jpg, .jpeg, .png , .webp files.');
        }
    }
    console.log(documentUpload, "SDfgsgh")

    //  FOR PDF VIEW
    useEffect(() => {
        if (getFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const pdfData = new Uint8Array(reader.result);
                pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                    pdf.getPage(1).then((page) => {
                        const viewport = page.getViewport({ scale: 1.0 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };

                        page.render(renderContext).promise.then(() => {
                            const imageData = canvas.toDataURL('image/jpeg');
                            setPDFImg2(imageData);
                        });
                    });
                });
            };
            reader.readAsArrayBuffer(getFile);
        }
        if (getFile2) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const pdfData = new Uint8Array(reader.result);
                pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                    pdf.getPage(1).then((page) => {
                        const viewport = page.getViewport({ scale: 1.0 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };

                        page.render(renderContext).promise.then(() => {
                            const imageData = canvas.toDataURL('image/jpeg');
                            setPDFImg(imageData);
                        });
                    });
                });
            };
            reader.readAsArrayBuffer(getFile2);
        }
    }, [getFile2, onChangeFile]);


    // END OF PDF VIEW 


    const handleDelete = (inputRef, imgRef) => {
        inputRef.current.value = ''
        // imgRef.current.src = dummyImg;
        // dispatch(showSuccessToast("Deleted successfully!"))
        if (imgRef === imageRef) {

            setFileName("")
            if (documentUpload?.document?.front_document) {
                switch (activeDoc) {
                    case "Passport":
                        return dispatch(DeletePassportImg(edit_id, 'front_document'))
                    case "Emirates ID":
                        return dispatch(DeleteEmiratesImg(edit_id, 'front_document'))
                    default:
                        break;
                }
            }

        } else {
            setFileName2("")
            if (documentUpload?.document?.back_document) {
                switch (activeDoc) {
                    case "Passport":
                        return dispatch(DeletePassportImg(edit_id, 'back_document'))
                    case "Emirates ID":
                        return dispatch(DeleteEmiratesImg(edit_id, 'back_document'))
                    default:
                        break;
                }
            }
        }
    }



    const CancleDeleteDoc = () => {
        setDeleteDocType("");
        setDeletePopDoc(false);
    }

    const handleDeleteBtnPop = (inputRef, imgRef) => {
        console.log(inputRef, imgRef, "Wsdfcasgfesdgdvc")
        setDeleteDocType("");
        setDeletePopDoc(false);
        handleDelete(inputRef, imgRef)
    }


    const HandleDownload = (imageRef, fileName) => {
        useHandleDownload(imageRef, fileName)
    };


    const validationConfirm = (inputValue, inputName) => {
        if (activeDoc === "Passport") {
            switch (inputName) {
                case "Country_Code":
                    return Valid.isNotEmpty(inputValue);
                case "type_of_document":
                    return Valid.isNotEmpty(inputValue);
                case "Passport_No":
                    return Valid.isNotEmpty(inputValue);
                case "Nationality":
                    return Valid.isNotEmpty(inputValue);
                case "Gender":
                    return inputValue !== "Gender *";
                case "Date_of_Birth":
                    return Valid.isNotEmpty(inputValue);
                case "Given_name":
                    return Valid.isNotEmpty(inputValue);
                case "Place_of_Birth":
                    return Valid.isNotEmpty(inputValue);
                case "Place_of_issue":
                    return Valid.isNotEmpty(inputValue);
                case "Date_of_issue":
                    return Valid.isNotEmpty(inputValue);
                case "Date_of_Expiry":
                    return Valid.isNotEmpty(inputValue);
                case "Name_of_father":
                    return true;
                case "Name_of_mother":
                    return true;
                case "Name_of_Spouse":
                    return true;
                case "Address":
                    return true;
                case "Old_Passport_No_with_Date_and_Place_of_Issue":
                    return true;
                case "File_No":
                    return true;

                default:
                    return false;
            }
        } else if (activeDoc === "Emirates ID") {
            switch (inputName) {
                case "ID_Number":
                    return Valid.isNotEmpty(inputValue);
                case "Name":
                    return Valid.isNotEmpty(inputValue);
                case "Date_of_Birth":
                    return Valid.isNotEmpty(inputValue);
                case "Nationality":
                    return Valid.isNotEmpty(inputValue);
                case "Issuing_Date":
                    return Valid.isNotEmpty(inputValue);
                case "Date_of_Expiry":
                    return Valid.isNotEmpty(inputValue);
                case "Gender":
                    return inputValue !== "Gender *";
                case "Signature":
                    return Valid.isNotEmpty(inputValue);
                case "Occupation":
                    return Valid.isNotEmpty(inputValue);
                case "Employer":
                    return Valid.isNotEmpty(inputValue);
                case "Issuing_Place":
                    return Valid.isNotEmpty(inputValue);
                case "Name_of_father":
                    return Valid.isNotEmpty(inputValue);
                case "Card_Number":
                    return Valid.isNotEmpty(inputValue);
                default:
                    return false;
            }
        }
    };

    // check form is validation 
    const isFormValid = () => {
        // return   true ? true : false;
        if (activeDoc === "Passport") {
            return (
                DynamicInputValues.Country_Code.isValid && DynamicInputValues.type_of_document.isValid
                    && DynamicInputValues.Passport_No.isValid && DynamicInputValues.Nationality.isValid
                    && DynamicInputValues.Gender.isValid && DynamicInputValues.Date_of_Birth.isValid
                    && DynamicInputValues.Given_name.isValid && DynamicInputValues.Place_of_Birth.isValid
                    && DynamicInputValues.Place_of_issue.isValid && DynamicInputValues.Date_of_issue.isValid
                    && DynamicInputValues.Date_of_Expiry.isValid && DynamicInputValues.Name_of_father.isValid
                    && DynamicInputValues.Name_of_mother.isValid && DynamicInputValues.Name_of_Spouse.isValid
                    && DynamicInputValues.Address.isValid && DynamicInputValues.Old_Passport_No_with_Date_and_Place_of_Issue.isValid
                    && DynamicInputValues.File_No.isValid ? true : false
            )
        } else if (activeDoc === "Emirates ID") {
            return (
                emiratesIDFileds?.ID_Number?.isValid &&
                    emiratesIDFileds?.Name?.isValid &&
                    emiratesIDFileds?.Date_of_Birth?.isValid &&
                    emiratesIDFileds?.Nationality?.isValid &&
                    emiratesIDFileds?.Issuing_Date?.isValid &&
                    emiratesIDFileds?.Date_of_Expiry?.isValid &&
                    emiratesIDFileds?.Gender?.isValid &&
                    emiratesIDFileds?.Signature?.isValid &&
                    emiratesIDFileds?.Occupation?.isValid &&
                    emiratesIDFileds?.Employer?.isValid &&
                    emiratesIDFileds?.Issuing_Place?.isValid &&
                    emiratesIDFileds?.Card_Number?.isValid ? true : false
            )
        }
    };


    const handleInputChange = (e, inputState, setInputState) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...inputState,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };

        // if (inputName == "Card_Number") {
        //     const regex = /^[0-9]+$/; // Regex to allow only numbers

        //     if (regex.test(inputValue) || inputValue === '') {
        //         setInputState(data)
        //         setRegexError('');
        //     } else {
        //         setRegexError(inputName);
        //     }
        // } else {
        //     setInputState(data)
        // }

        switch (inputName) {
            case "Card_Number":
                if (validReg.acceptNums(inputValue) || inputValue === '') {
                    return setInputState(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "ID_Number":
                if (validReg.acceptNumsForCard(inputValue) || inputValue === '') {
                    return setInputState(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "Occupation":
            case "Employer":
            case "Name_of_Spouse":
            case "Issuing_Place":
            case "Name":
            case "Nationality":
            case "Signature":
                if (validReg.acceptAlphaWithSpace(inputValue)) {
                    return setInputState(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            default:
                return setInputState(data);
        }

    };

    // DOB Func 

    const handleDateInput = (date, inputName, inputState, setInputState) => {
        console.log(date, "adfafg")
        let DateInfo = {
            ...inputState,
            [inputName]: {
                value: date,
                isValid: date ? true : false,
            },
            validation: isFormValid(),
        };
        setInputState(DateInfo);
    }





    const ondynamicDropdownChange = (field, value, setInputState) => {
        setInputState((prevValues) => ({
            ...prevValues,
            [field]: { value: value, isValid: true }, // Assuming dropdown value is always valid
        }))
    }
    const handleDocumentChnage = (val) => {
        dispatch(setDocType(val));
        onActiveDocChange && onActiveDocChange(val)
        dispatch(resetUpdateDocs())
        setGetFile(null)
        setGetFile2(null)
        setPDFImg(null)
        setPDFImg2(null)
        if (activeDoc === "Passport") {
            setDynamicInputValues(
                {
                    "Country_Code": {
                        value: "",
                        isValid: false
                    },
                    "type_of_document": {
                        value: "",
                        isValid: false
                    },
                    "Passport_No": {
                        value: "",
                        isValid: false
                    },
                    "Nationality": {
                        value: "",
                        isValid: false
                    },
                    "Gender": {
                        value: "Gender",
                        isValid: false
                    },
                    "Date_of_Birth": {
                        value: "",
                        isValid: false
                    },
                    "Given_name": {
                        value: "",
                        isValid: false
                    },
                    "Place_of_Birth": {
                        value: "",
                        isValid: false
                    },
                    "Place_of_issue": {
                        value: "",
                        isValid: false
                    },
                    "Date_of_issue": {
                        value: "",
                        isValid: false
                    },
                    "Date_of_Expiry": {
                        value: "",
                        isValid: false
                    },
                    "Name_of_father": {
                        value: "",
                        isValid: true
                    },
                    "Name_of_mother": {
                        value: "",
                        isValid: true
                    },
                    "Name_of_Spouse": {
                        value: "",
                        isValid: true
                    },
                    "Address": {
                        value: "",
                        isValid: true
                    },
                    "Old_Passport_No_with_Date_and_Place_of_Issue": {
                        value: "",
                        isValid: true
                    },
                    "File_No": {
                        value: "",
                        isValid: true
                    },
                    "Document_type": {
                        value: "",
                        isValid: false
                    },
                    "employee_id": {
                        value: "",
                        isValid: false
                    },
                    Validation: false
                }
            )
        }
    }
    console.log(DynamicInputValues, "SDsfgfdbvdfg")

    const documentSaveBtn = async (docType, docImage1, docImage2) => {
        const formData = new FormData();
        // formData.append('document1', docImage1);
        // formData.append('document2', docImage2);

        const PassportData = {
            "Country_Code": DynamicInputValues?.Country_Code?.value,
            "type_of_document": DynamicInputValues?.type_of_document?.value,
            "Passport_No": DynamicInputValues?.Passport_No?.value,
            "Nationality": DynamicInputValues?.Nationality?.value,
            "Gender": DynamicInputValues?.Gender?.value,
            "Date_of_Birth": formatDateDatePicker(DynamicInputValues?.Date_of_Birth?.value),
            "Given_name": DynamicInputValues?.Given_name?.value,
            "Place_of_Birth": DynamicInputValues?.Place_of_Birth?.value,
            "Place_of_issue": DynamicInputValues?.Place_of_issue?.value,
            "Date_of_issue": formatDateDatePicker(DynamicInputValues?.Date_of_issue?.value),
            "Date_of_Expiry": formatDateDatePicker(DynamicInputValues?.Date_of_Expiry?.value),
            "Name_of_father": DynamicInputValues?.Name_of_father?.value,
            "Name_of_mother": DynamicInputValues?.Name_of_mother?.value,
            "Name_of_Spouse": DynamicInputValues?.Name_of_Spouse?.value,
            "Address": DynamicInputValues?.Address?.value,
            "Old_Passport_No_with_Date_and_Place_of_Issue": DynamicInputValues?.Old_Passport_No_with_Date_and_Place_of_Issue?.value,
            "File_No": DynamicInputValues?.File_No?.value,
            'Document_type': activeDoc,
            // "employee_id": singleEmployee?.employee_id,
            "added_by_user_id": userId,
            "client_id": client_id_param
        }

        // Append employee data to the FormData object using the spread operator
        {
            if (docType == "Passport") {
                Object.entries(PassportData).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                // Append employee image to the FormData object
                // { docImage1 && formData.append("front_document", docImage1); }
                // { docImage2 && formData.append("back_document", docImage2); }
                if (docImage1) {
                    try {
                        const compressedFile = await compressFile(docImage1); // Wait for the compressed file
                        formData.append("front_document", compressedFile, docImage1.name); // Append the compressed file 
                    } catch (error) {
                        console.error("Error compressing file:", error);
                    }
                }
                if (docImage2) {
                    try {
                        const compressedFile = await compressFile(docImage2); // Wait for the compressed file
                        formData.append("back_document", compressedFile, docImage2.name); // Append the compressed file 
                    } catch (error) {
                        console.error("Error compressing file:", error);
                    }
                }
            } else if (docType == "Emirates ID") {
                const EmiratesIdData = {
                    "ID_Number": emiratesIDFileds?.ID_Number?.value,
                    "Name": emiratesIDFileds?.Name?.value,
                    "Date_of_Birth": formatDateDatePicker(emiratesIDFileds?.Date_of_Birth?.value),
                    "Nationality": emiratesIDFileds?.Nationality?.value,
                    "Issuing_Date": formatDateDatePicker(emiratesIDFileds?.Issuing_Date?.value),
                    "Date_of_Expiry": formatDateDatePicker(emiratesIDFileds?.Date_of_Expiry?.value),
                    "Gender": emiratesIDFileds?.Gender?.value,
                    "Signature": emiratesIDFileds?.Signature?.value,
                    "Occupation": emiratesIDFileds?.Occupation?.value,
                    "Employer": emiratesIDFileds?.Employer?.value,
                    "Issuing_Place": emiratesIDFileds?.Issuing_Place?.value,
                    "Card_Number": emiratesIDFileds?.Card_Number?.value,
                    'Document_type': activeDoc,
                    "employee_id": singleEmployee?.employee_id,
                    "added_by_user_id": userId,
                }
                Object.entries(EmiratesIdData).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                // Append employee image to the FormData object
                // { docImage1 && formData.append("front_document", docImage1); }
                // { docImage2 && formData.append("back_document", docImage2); }
                if (docImage1) {
                    try {
                        const compressedFile = await compressFile(docImage1); // Wait for the compressed file
                        formData.append("front_document", compressedFile, docImage1.name); // Append the compressed file 
                    } catch (error) {
                        console.error("Error compressing file:", error);
                    }
                }
                if (docImage2) {
                    try {
                        const compressedFile = await compressFile(docImage2); // Wait for the compressed file
                        formData.append("back_document", compressedFile, docImage2.name); // Append the compressed file 
                    } catch (error) {
                        console.error("Error compressing file:", error);
                    }
                }
            }

        }

        if (isFormValid()) {
            if (documentUpload?.set_update) {
                switch (docType) {
                    case "Passport": return dispatch(updateUploadPassport(edit_id, formData))
                    case "Emirates ID": return dispatch(updateEmiratesID(edit_id, formData))

                    default:
                        break;
                }
            } else {
                switch (docType) {
                    case "Passport": return dispatch(uploadPassport(formData))
                    case "Emirates ID": return dispatch(uploadEmiratesID(formData))

                    default:
                        break;
                }
                // if (docType == "Passport") {
                //     dispatch(uploadPassport(formData))
                // } else if (docType == "Emirates ID") {
                //     dispatch(uploadEmiratesID(formData))
                // }
            }
        } else {
            setShowDocError(true)
        }
    }

    const deleteHandler = (id, docType) => {
        switch (docType) {
            case 'Passport': return dispatch(deleteUploadedPassport(id))
            case 'Emirates ID': return dispatch(deleteEmiratesID(id))
            default:
                break;
        }


        // if (docType === "Passport") {
        //     dispatch(deleteUploadedPassport(id))
        // }
    }

    useEffect(() => {
        if (documentUpload?.deleted) {
            // dispatch(getUploadedDocs(singleEmployee?.employee_id))
        }
    }, [documentUpload?.deleted, dispatch])


    // edit handler  
    const DocuemntEditBtnHandler = (id, Document_type) => {
        dispatch(setDocType(Document_type));
        onActiveDocChange && onActiveDocChange(Document_type)
        setEdit_id(id)
        dispatch(setUpdateDocs())

        // if (Document_type === "Passport") {
        //     dispatch(viewPassport(id))
        // }


        if (client_id_param !== "undefined") {
            switch (Document_type) {
                case "Passport": return dispatch(viewPassport(id))
                case "Emirates ID": return dispatch(viewEmiratesID(id))

                default:
                    break;
            }
        }
    }

    console.log(
        ValidationFile.isValidDateFormat(documentUpload?.document?.data?.["Date of Issue"]),
        ValidationFile.isValidDateFormat(documentUpload?.document?.data?.["Date of Birth"]),

        "activeDocactiveDoc useDocumetn")

    const getUpdateInputField = () => {
        const newPassportData = {
            "Country_Code": {
                value: documentUpload?.document?.Country_Code,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Country_Code)
            },
            "type_of_document": {
                value: documentUpload?.document?.type_of_document,
                isValid: Valid.isNotEmpty(documentUpload?.document?.type_of_document)
            },
            "Passport_No": {
                value: documentUpload?.document?.Passport_No,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Passport_No)
            },
            "Nationality": {
                value: documentUpload?.document?.Nationality,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Nationality)
            },
            "Gender": {
                value: documentUpload?.document?.Gender,
                isValid: documentUpload?.document?.Gender !== "Gender *"
            },
            "Date_of_Birth": {
                value: documentUpload?.document?.Date_of_Birth,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Date_of_Birth)
            },
            "Given_name": {
                value: documentUpload?.document?.Given_name,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Given_name)
            },
            "Place_of_Birth": {
                value: documentUpload?.document?.Place_of_Birth,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Place_of_Birth)
            },
            "Place_of_issue": {
                value: documentUpload?.document?.Place_of_issue,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Place_of_issue)
            },
            "Date_of_issue": {
                value: documentUpload?.document?.Date_of_issue,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Date_of_issue)
            },
            "Date_of_Expiry": {
                // value: documentUpload?.document?.Date_of_Expiry || dateFormatYYYY_MM_DD(documentUpload?.document?.data?.["Date of Expiry"]),
                value: documentUpload?.document?.Date_of_Expiry,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Date_of_Expiry)
            },
            "Name_of_father": {
                value: documentUpload?.document?.Name_of_father,
                isValid: true
            },
            "Name_of_mother": {
                value: documentUpload?.document?.Name_of_mother,
                isValid: true
            },
            "Name_of_Spouse": {
                value: documentUpload?.document?.Name_of_Spouse,
                isValid: true
            },
            "Address": {
                value: documentUpload?.document?.Address,
                isValid: true
            },
            "Old_Passport_No_with_Date_and_Place_of_Issue": {
                value: documentUpload?.document?.Old_Passport_No_with_Date_and_Place_of_Issue,
                isValid: true
            },
            "File_No": {
                value: documentUpload?.document?.File_No,
                isValid: true
            },
            Validation: false
        };
        const newEmiratesIdData = {
            "ID_Number": {
                value: documentUpload?.document?.ID_Number,
                isValid: true
            },
            "Name": {
                value: documentUpload?.document?.Name,
                isValid: true
            },
            "Date_of_Birth": {
                value: documentUpload?.document?.Date_of_Birth,
                isValid: true
            },
            "Nationality": {
                value: documentUpload?.document?.Nationality,
                isValid: true
            },
            "Issuing_Date": {
                value: documentUpload?.document?.Issuing_Date,
                isValid: true
            },
            "Date_of_Expiry": {
                value: documentUpload?.document?.Date_of_Expiry,
                isValid: true
            },
            "Gender": {
                value: documentUpload?.document?.Gender,
                isValid: true
            },
            "Signature": {
                value: documentUpload?.document?.Signature,
                isValid: true
            },
            "Occupation": {
                value: documentUpload?.document?.Occupation,
                isValid: true
            },
            "Employer": {
                value: documentUpload?.document?.Employer,
                isValid: true
            },
            "Issuing_Place": {
                value: documentUpload?.document?.Issuing_Place,
                isValid: true
            },
            "Card_Number": {
                value: documentUpload?.document?.Card_Number,
                isValid: true
            },
            Validation: true
        }
        switch (activeDoc) {
            case "Passport": return setDynamicInputValues(newPassportData)
            case "Emirates ID": return setEmiratesIDFileds(newEmiratesIdData)

            default:
                break;
        }

    }





    const getFrontSideAIData = () => {
        console.log(DynamicInputValues, "ADSFsagf")
        const newPassportData = {
            ...DynamicInputValues,
            "Country_Code": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Country Code"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Country Code"])
            },
            "type_of_document": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["ID Type"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["ID Type"])
            },
            "Passport_No": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Passport No."] || documentUpload?.aiAnalyzer?.frontSide?.data?.["Passport No"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Passport No."] || documentUpload?.aiAnalyzer?.frontSide?.data?.["Passport No"])
            },
            "Nationality": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Nationality"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Nationality"])
            },
            "Gender": {
                // value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Sex"],
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Sex"] === "M" ? "Male" : documentUpload?.aiAnalyzer?.frontSide?.data?.["Sex"] === "F" ? "Female" : null,
                isValid: documentUpload?.aiAnalyzer?.frontSide?.Gender !== "Gender *"
            },
            "Date_of_Birth": {
                value: ValidationFile.isValidDateFormat(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Birth"]) ? dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Birth"]) : "",
                isValid: Valid.isNotEmpty(ValidationFile.isValidDateFormat(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Birth"]) ? dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Birth"]) : "")
            },
            "Given_name": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Name"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Name"])
            },
            "Place_of_Birth": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Place of Birth"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Place of Birth"])
            },
            "Place_of_issue": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Issuing Aathority"] || documentUpload?.aiAnalyzer?.frontSide?.data?.["Place of Issue"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Issuing Aathority"] || documentUpload?.aiAnalyzer?.frontSide?.data?.["Place of Issue"])
            },
            "Date_of_issue": {
                value: ValidationFile.isValidDateFormat(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Issue"]) ? dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Issue"]) : "",
                isValid: Valid.isNotEmpty(ValidationFile.isValidDateFormat(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Issue"]) ? dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Issue"]) : "")
            },
            "Date_of_Expiry": {
                // value: documentUpload?.aiAnalyzer?.frontSide?.Date_of_Expiry || dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Expiry"]),
                value: ValidationFile.isValidDateFormat(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Expiry"]) ? dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Expiry"]) : "",
                isValid: Valid.isNotEmpty(dateFormatYYYY_MM_DD(documentUpload?.aiAnalyzer?.frontSide?.data?.["Date of Expiry"]))
            },

            Validation: false
        };
        const newEmiratesIdData = {
            ...emiratesIDFileds,
            "ID_Number": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["ID Number"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["ID Number"])
            },
            "Name": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Name"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Name"])
            },
            "Date_of_Birth": {
                value: documentUpload?.document?.Date_of_Birth,
                isValid: Valid?.isNotEmpty(documentUpload?.document?.Date_of_Birth)
            },
            "Nationality": {
                value: documentUpload?.aiAnalyzer?.frontSide?.data?.["Nationality"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.frontSide?.data?.["Nationality"])
            },
            "Issuing_Date": {
                value: documentUpload?.document?.Issuing_Date,
                isValid: Valid?.isNotEmpty(documentUpload?.document?.Issuing_Date)
            },
            "Date_of_Expiry": {
                value: documentUpload?.document?.Date_of_Expiry,
                isValid: Valid?.isNotEmpty(documentUpload?.document?.Date_of_Expiry)
            },
            "Gender": {
                value: documentUpload?.document?.Gender,
                isValid: Valid?.isNotEmpty(documentUpload?.document?.Gender)
            },
            "Signature": {
                value: documentUpload?.document?.Signature,
                isValid: Valid?.isNotEmpty(documentUpload?.document?.Signature)
            },

            Validation: false
        }

        // setnewPassportAiData(newPassportData)
        // setnewEmiratesAiData(newEmiratesIdData)


        switch (activeDoc) { // Replace `yourVariable` with the actual variable you're checking
            case "Passport":
                setDynamicInputValues(newPassportData);
                break;
            case "Emirates ID":
                setEmiratesIDFileds(newEmiratesIdData);
                break;
            default:
                // Optional: handle default case if needed
                break;
        }


    }

    const getBackSideAIData = () => {
        const newPassportData = {
            ...DynamicInputValues,
            "Name_of_father": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["Name of Father/Legal Guardian"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.backside?.data?.["Name of Father/Legal Guardian"]) && documentUpload?.aiAnalyzer?.backside?.data?.["Name of Father/Legal Guardian"] != null
            },
            "Name_of_mother": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["Name of Mother"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.backside?.data?.["Name of Mother"]) && documentUpload?.aiAnalyzer?.backside?.data?.["Name of Mother"] != null
            },
            "Name_of_Spouse": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["Name of Spouse"] != null ? documentUpload?.aiAnalyzer?.backside?.data?.["Name of Spouse"] : "",
                isValid: true
            },
            "Address": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["Address"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.backside?.data?.["Address"]) && documentUpload?.aiAnalyzer?.backside?.data?.["Address"] != null
            },
            "Old_Passport_No_with_Date_and_Place_of_Issue": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["old Passport No. with date and Place of Issue"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.backside?.data?.["old Passport No. with date and Place of Issue"]) && documentUpload?.aiAnalyzer?.backside?.data?.["old Passport No. with date and Place of Issue"] != null
            },
            "File_No": {
                value: documentUpload?.aiAnalyzer?.backside?.data?.["File No"],
                isValid: Valid.isNotEmpty(documentUpload?.aiAnalyzer?.backside?.data?.["File No"]) && documentUpload?.aiAnalyzer?.backside?.data?.["File No"] != null
            },
            Validation: false
        };
        const newEmiratesIdData = {
            ...emiratesIDFileds,
            "Occupation": {
                value: documentUpload?.document?.Occupation,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Occupation)
            },
            "Employer": {
                value: documentUpload?.document?.Employer,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Employer)
            },
            "Issuing_Place": {
                value: documentUpload?.document?.Issuing_Place,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Issuing_Place)
            },
            "Card_Number": {
                value: documentUpload?.document?.Card_Number,
                isValid: Valid.isNotEmpty(documentUpload?.document?.Card_Number)
            },
            Validation: true
        }
        switch (activeDoc) {
            case "Passport": return setDynamicInputValues(newPassportData)
            case "Emirates ID": return setEmiratesIDFileds(newEmiratesIdData)

            default:
                break;
        }

    }


    useEffect(() => {
        if (documentUpload?.set_update || documentUpload?.document) {
            getUpdateInputField()
        }

    }, [documentUpload])

    // useEffect(() => {
    //     if (documentUpload?.aiAnalyzer?.frontSide?.success !== null) {
    //         getFrontSideAIData()
    //         // dispatch(resetPassportAnalyzerFrontSide())
    //     }
    //     if (documentUpload?.aiAnalyzer?.backside?.success) {
    //         getBackSideAIData()
    //         // dispatch(resetPassportAnalyzerBackSide())
    //     }

    // }, [documentUpload?.aiAnalyzer, documentUpload?.aiAnalyzer?.backside, setDynamicInputValues])


    // Fill new ai data 
    useEffect(() => {
        // setDynamicInputValues(newPassportAiData)
        // setEmiratesIDFileds(newEmiratesAiData)
    }, [newPassportAiData])

    console.log(newPassportAiData, "Dfzzv")

    // console.log(newPassportAiData, "ADSFsagf 1111111111111");


    useEffect(() => {
        // if (documentUpload?.aiAnalyzer?.frontSide?.success !== null) {
        //     getFrontSideAIData()
        //     // dispatch(resetPassportAnalyzerFrontSide())
        // }
        // if (documentUpload?.aiAnalyzer?.backside?.success) {
        //     getBackSideAIData()
        //     // dispatch(resetPassportAnalyzerBackSide())
        // }

    }, [documentUpload?.aiAnalyzer, documentUpload?.aiAnalyzer?.backside, setDynamicInputValues])


    useEffect(() => {
        if (documentUpload?.aiAnalyzer?.frontSide?.success) {
            getFrontSideAIData()
        }

    }, [documentUpload?.aiAnalyzer?.frontSide?.success, setDynamicInputValues,])

    useEffect(() => {
        if (documentUpload?.aiAnalyzer?.backside?.success) {
            getBackSideAIData()
        }

    }, [documentUpload?.aiAnalyzer?.backside?.success, setDynamicInputValues,])


    // Fill new ai data 
    useEffect(() => {
        // setDynamicInputValues(newPassportAiData)
        // setEmiratesIDFileds(newEmiratesAiData)
    }, [newPassportAiData])

    console.log(DynamicInputValues, documentUpload?.aiAnalyzer, "Dfzzv")

    console.log(documentUpload, DynamicInputValues, "sdcdsfgevsfvc")
    // console.log(newPassportAiData, "ADSFsagf 1111111111111");

    const handleDocumentCancelBtn = () => {
        dispatch(setDocType('Add document*'));
        onActiveDocChange && onActiveDocChange('Add document*')
        dispatch(resetUpdateDocs())
        setShowDocError(false)
    }

    const resetPassport = () => {
        setDynamicInputValues(
            {
                "Country_Code": {
                    value: "",
                    isValid: false
                },
                "type_of_document": {
                    value: "",
                    isValid: false
                },
                "Passport_No": {
                    value: "",
                    isValid: false
                },
                "Nationality": {
                    value: "",
                    isValid: false
                },
                "Gender": {
                    value: "Gender",
                    isValid: false
                },
                "Date_of_Birth": {
                    value: "",
                    isValid: false
                },
                "Given_name": {
                    value: "",
                    isValid: false
                },
                "Place_of_Birth": {
                    value: "",
                    isValid: false
                },
                "Place_of_issue": {
                    value: "",
                    isValid: false
                },
                "Date_of_issue": {
                    value: "",
                    isValid: false
                },
                "Date_of_Expiry": {
                    value: "",
                    isValid: false
                },
                "Name_of_father": {
                    value: "",
                    isValid: false
                },
                "Name_of_mother": {
                    value: "",
                    isValid: false
                },
                "Name_of_Spouse": {
                    value: "",
                    isValid: true
                },
                "Address": {
                    value: "",
                    isValid: false
                },
                "Old_Passport_No_with_Date_and_Place_of_Issue": {
                    value: "",
                    isValid: false
                },
                "File_No": {
                    value: "",
                    isValid: false
                },
                "Document_type": {
                    value: "",
                    isValid: false
                },
                "employee_id": {
                    value: "",
                    isValid: false
                },
                Validation: false
            }
        )
    }

    const resetEmirates = () => {
        setEmiratesIDFileds({
            "ID_Number": {
                value: "",
                isValid: false
            },
            "Name": {
                value: "",
                isValid: false
            },
            "Date_of_Birth": {
                value: "",
                isValid: false
            },
            "Nationality": {
                value: "",
                isValid: false
            },
            "Issuing_Date": {
                value: "",
                isValid: false
            },
            "Date_of_Expiry": {
                value: "",
                isValid: false
            },
            "Gender": {
                value: "Gender *",
                isValid: false
            },
            "Signature": {
                value: "",
                isValid: false
            },
            "Occupation": {
                value: "",
                isValid: false
            },
            "Employer": {
                value: "",
                isValid: false
            },
            "Issuing_Place": {
                value: "",
                isValid: false
            },
            "Card_Number": {
                value: "",
                isValid: false
            },
            Validation: false
        })
    }
    useLayoutEffect(() => {
        if (documentUpload?.success) {
            dispatch(uploadReset())
            dispatch(setDocType('Add document*'));
            onActiveDocChange && onActiveDocChange('Add document*')
            setFileName("")
            setFileName2("")
        }
        if (activeDoc === "Passport") {
            setDynamicInputValues(
                {
                    "Country_Code": {
                        value: "",
                        isValid: false
                    },
                    "type_of_document": {
                        value: "",
                        isValid: false
                    },
                    "Passport_No": {
                        value: "",
                        isValid: false
                    },
                    "Nationality": {
                        value: "",
                        isValid: false
                    },
                    "Gender": {
                        value: "Gender",
                        isValid: false
                    },
                    "Date_of_Birth": {
                        value: "",
                        isValid: false
                    },
                    "Given_name": {
                        value: "",
                        isValid: false
                    },
                    "Place_of_Birth": {
                        value: "",
                        isValid: false
                    },
                    "Place_of_issue": {
                        value: "",
                        isValid: false
                    },
                    "Date_of_issue": {
                        value: "",
                        isValid: false
                    },
                    "Date_of_Expiry": {
                        value: "",
                        isValid: false
                    },
                    "Name_of_father": {
                        value: "",
                        isValid: true
                    },
                    "Name_of_mother": {
                        value: "",
                        isValid: true
                    },
                    "Name_of_Spouse": {
                        value: "",
                        isValid: true
                    },
                    "Address": {
                        value: "",
                        isValid: true
                    },
                    "Old_Passport_No_with_Date_and_Place_of_Issue": {
                        value: "",
                        isValid: true
                    },
                    "File_No": {
                        value: "",
                        isValid: true
                    },
                    "Document_type": {
                        value: "",
                        isValid: false
                    },
                    "employee_id": {
                        value: "",
                        isValid: false
                    },
                    Validation: false
                }
            )
        } else if (activeDoc === "Emirates ID") {
            setEmiratesIDFileds({
                "ID_Number": {
                    value: "",
                    isValid: false
                },
                "Name": {
                    value: "",
                    isValid: false
                },
                "Date_of_Birth": {
                    value: "",
                    isValid: false
                },
                "Nationality": {
                    value: "",
                    isValid: false
                },
                "Issuing_Date": {
                    value: "",
                    isValid: false
                },
                "Date_of_Expiry": {
                    value: "",
                    isValid: false
                },
                "Gender": {
                    value: "Gender *",
                    isValid: false
                },
                "Signature": {
                    value: "",
                    isValid: false
                },
                "Occupation": {
                    value: "",
                    isValid: false
                },
                "Employer": {
                    value: "",
                    isValid: false
                },
                "Issuing_Place": {
                    value: "",
                    isValid: false
                },
                "Card_Number": {
                    value: "",
                    isValid: false
                },
                Validation: false
            })
        }
    }, [documentUpload, dispatch])


    useEffect(() => {
        dispatch(resetPassportAnalyzerBackSide())
        dispatch(resetPassportAnalyzerFrontSide())
        // resetPassport()
        // resetEmirates()
    }, [tabIndex])



    useEffect(() => {
        dispatch(resetPassportAnalyzerBackSide())
        dispatch(resetPassportAnalyzerFrontSide())
        resetPassport()
        resetEmirates()
        // inputFile.current = null;
    }, [tabIndex, activeDoc])

    console.log(documentUpload, "DZvdgbdfb")
    useEffect(() => {
        setFileName("")
        setFileName2("")
        setShowDocError(false)
    }, [activeDoc])



    useEffect(() => {
        if (documentUpload?.success) {
            if (employee_ID?.includes("id=") || employeeManagement?.employee_type == 'old') {
                const data = employee_ID?.includes("id=") && employee_ID?.replace("id=", "")
                if (data !== undefined && data !== null && client_id_param !== "undefined") {
                    dispatch(getListOfDocument(data))
                }
            } else {
                navigate('/employees-management')
            }
        }

    }, [documentUpload?.success])

    return {
        activeDoc, DynamicInputValues, setDynamicInputValues, documentSaveBtn,
        employeeManagement, onChangeFile, handleDelete, HandleDownload, handleInputChange,
        ondynamicDropdownChange, handleDocumentChnage, inputFile,
        inputFile2,
        imageRef2,
        fileName,
        fileName2, setFileName,
        setFileName2, EmployeeDocumentsList, imageRef, documentUpload, deleteHandler, DocuemntEditBtnHandler
        , handleDocumentCancelBtn, showDocError, PDFImg, PDFImg2, emiratesIDFileds, setEmiratesIDFileds, setPDFImg,
        setPDFImg2, regexError, setShowDocError, handleDateInput, CancleDeleteDoc, deletePopDoc, setDeletePopDoc,
        deleteDocType, setDeleteDocType, handleDeleteBtnPop
    }
}

export default useDocumentDetails