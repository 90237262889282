import Request from "../../../Classes/Requests";

class foldersReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            create_company_folder: super.url("/create_company_folder"),
            get_company_folder: super.url("/get_company_folder?added_by_user_id=USER_ID&client_id=__CLIENT_ID__"),
            delete_company_folders: super.url("/delete_company_folders"),
            rename_company_folder: super.url("/rename_company_folder"),
            search_company_folders: super.url("/search_company_folders?query=__VALUE__&added_by_user_id=USER_ID"),
            get_company_subfolder: super.url("/get_company_subfolder"),
            upload_companydoc: super.url("/upload_companydoc"),
            // upload_companydoc: super.url("/upload_folder"),
            delete_company_file: super.url("/delete_company_file"),
            set_reminder_folder: super.url("/set_reminder_folder"),
            set_reminder_file: super.url("/set_reminder_file"),
            trigger_folder_and_file_notification: super.url("/api/trigger_folder_and_file_notification"),
            get_folder_file_notification: super.url("/get_folder_file_notification"),
        }
    }
}
export default new foldersReq();
