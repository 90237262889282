import React, { useRef, useState } from 'react'
import './accessManagement.scss'
import data from './data.json'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import Dashboard from '../DashBoard'
import dots from '../../Assets/icons/dots-vertical.svg'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import { useOutsideClick } from 'rooks'
import Auth from '../../Classes/Auth'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import TopActionButtons from '../DashBoard/RightBarPannel/TopActionButtons/TopActionButtons'
import useAccessManagement from '../../CustomHooks/AccessManagement/useAccessManagement'
import { resetGrantBoError, resetViewAccessDeatils } from '../../store/Actions/AccessManagement'
import { useDispatch, useSelector } from 'react-redux';
import usePageListData from '../../CustomHooks/usePageListData'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import { dateFormat } from '../../CustomHooks/dateFormat'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import AccessModal from '../DashBoard/RightBarPannel/TopActionButtons/Popups/AccessModal/AccessModal'
import useAccessModal from '../../CustomHooks/AccessManagement/useAccessModal'
import { setClient } from '../../store/Actions/EmployeeManagement'
const AccessManagement = () => {

    const dispatch = useDispatch()
    const accessManagePopRef = useRef();
    const ref = useRef(null)
    const [searchVal, setSearchVal] = useState('')
    const [activeManage, setActiveManage] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [deleteType, setDeleteType] = useState(null);
    const userId = Auth?.user()?.user_id // this is for added by user
    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    const handleManageBtn = (val) => {
        setActiveManage(activeManage === val ? -1 : val)
    }

    useOutsideClick(ref, () => {
        setActiveManage(-1)
    });

    const closeAccessPopup = () => {
        accessManagePopRef.current.close();
        resetAccessBOEmail()
        setAcessBOError(false)
        dispatch(resetGrantBoError())
        dispatch(resetViewAccessDeatils())
    }

    const { currentStep, setCurrentStep,
        accessOf, setAccessOf,
        formData, setFormData,
        nextStep,
        previousStep,
        handleInputChange, saveBtnHandler, selectAllHandler,
        employeeManagement, documentUpload, showDocs, searchValModal, handleSearchModal, handleResetSearchModal,
        selectAll, handleSelectedDocs, selectedEmployee, updateId, selectAllEmpRef, selectAll_docRef, selectAllDoc,

        accessManagement, giveAccessBtn, accessBOEmail, acessBOError,
        handleInputBOchange, resetAccessBOEmail, setAcessBOError, editBtnHandle
        , deleteBtnHandler, actionHandler, revokeBtnHandler, setUpdatedId,

        // folder 
        folders, handleFolderInputChange, handleComapanyDocInputChange,

        accessLevelDropHandler

    } = useAccessModal(closeAccessPopup)

    // const dataForCurrentPage = usePageListData(accessManagement?.data?.pending_invitations, pageNumber)

    // delete pop implement
    const deleteClickHandler = (id, type) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
        setDeleteType(type)
    }
    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
        setDeleteType(null)
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeleteType(null)
        setDeletePop(false);
        dispatch(setClient("Select client*"))
        if (deleteType == "pending") {
            revokeBtnHandler(deleteId)
        } else if (deleteType == "access") {
            deleteBtnHandler(deleteId)
        }

    }
    console.log(deleteId, "SAvsdbvsdbv")

    const openAccessPopup = () => {
        setUpdatedId(null)
        accessManagePopRef.current.open();
        setFormData({
            employee: {},
            email: "",
            permission: ""
        })
        setAcessBOError(false)
        dispatch(resetGrantBoError())
        nextStep()
        dispatch(resetViewAccessDeatils())
    }

    return (
        < >
            <div className="access-management-wrapper">
                <div className="access-management-wrap">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading ClassName={" "}>
                            Access Management
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            {/* <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            /> */}
                        </div>
                        {(Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3) &&

                            // <TopActionButtons showAccess={true} accessManagePopRef={accessManagePopRef} />
                            <CommanButton onClick={() => { openAccessPopup(); setCurrentStep(1) }} ClassName={"action-btn"} >
                                <p className='w-700'>  <span className='text-xs'>  + </span> Access</p>
                            </CommanButton>

                        }
                        {/* <div className="pagination-wrapper d-flex align-center justify-right">
                        <div className="pagination-item d-flex align-center">
                            <p className="text-xxs w-400 secondary mr-10 text-nowrap">
                                {`1-${data?.length} of ${data?.length}`}
                            </p>
                            <div className="arrow-btn-wrap d-flex align-center">
                                <button
                                    className={`arrow left transparent mr-10`}
                                >
                                    <i className='icon'></i>
                                </button>
                                <button
                                    className={`arrow right transparent`}
                                >   <i ></i></button>
                            </div>
                        </div>
                    </div> */}
                        {/* <PaginationSimple
                            className={"document-pagenation"}
                            data={accessManagement?.data}
                            shows={accessManagement?.data?.length < 20 ? accessManagement?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        /> */}
                    </div>
                    <div className="access-management-table-wrapper mt-10">
                        {/* {accessManagement?.data?.pending_invitations?.length ? */}
                        <div className="table-wrap access-management-table">
                            <h4 className='base mb-10'>Pending Invitation - </h4>
                            <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th className='name'> {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? "Name" : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? "Invited by" : ''}  </th>
                                        <th className='email'>Email Address</th>
                                        {(Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) && <th className=''>
                                            Type of User
                                        </th>}
                                        <th className='level'>Access Level</th>
                                        <th className='Invitation'>Date of Invitation</th>
                                        <th className='Expire'>Access Expire</th>
                                        <th className='Status'>Status</th>
                                        <th className='action'>Action</th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {accessManagement?.data?.pending_invitations?.length ? accessManagement?.data?.pending_invitations?.map((val, key) => {
                                        return (
                                            <tr className="table-row" key={key}>
                                                <td className='name'>
                                                    {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? val.typing_centre_name : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? val.invited_by : ''}
                                                </td>
                                                <td >
                                                    {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? val.typing_centre_email : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? val.user_email : ''}

                                                </td>
                                                {(Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) &&
                                                    <td >
                                                        {val?.type_of_user}

                                                    </td>}

                                                <td > Editor</td>
                                                <td > {dateFormat(val?.date_of_invitation)}</td>
                                                <td > {val?.access_expire ? val?.access_expire : "Never"}</td>
                                                <td > {val?.status}</td>
                                                <td >
                                                    {
                                                        (Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3)
                                                            ?
                                                            <>
                                                                <div className='dots-wrap relative' >
                                                                    <img src={dots} alt="" onClick={() => { handleManageBtn(key) }} />
                                                                    {activeManage === key && <div className="dropdown-wrap absolute" ref={ref}>
                                                                        <div className="item d-flex align-center mb-10 pointer"
                                                                            onClick={() => deleteClickHandler(val?.id, "pending")}>
                                                                            <p className="text-2xs w-500 secondary" >Revoke</p>
                                                                        </div>
                                                                        {/* <div className="item d-flex align-center pointer" onClick={() => openAccessPopup()}  > */}
                                                                        <div className="item d-flex align-center pointer" onClick={() => editBtnHandle(accessManagePopRef, val?.typing_centre_email)}  >
                                                                            <p className="text-2xs w-500 secondary" >Edit</p>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                {/* <button
                                                                    className='button bg-secondary border-0 white btn w-400 text-xxs'
                                                                    onClick={() => deleteClickHandler(val?.id, "pending")}
                                                                    style={{ padding: "3px 5px", borderRadius: "4px" }}
                                                                >Revoke</button> */}


                                                            </>


                                                            :
                                                            <div className='dots-wrap relative' >
                                                                <img src={dots} alt="" onClick={() => { handleManageBtn(key) }} />
                                                                {activeManage === key && <div className="dropdown-wrap absolute" ref={ref}>
                                                                    <div className="item d-flex align-center mb-10 pointer" onClick={() => { actionHandler(val?.id, "accept"); setActiveManage(-1) }}>
                                                                        {/* <i className='icons mr-5 accept'></i> */}
                                                                        <p className="text-2xs w-500 secondary" >Accept</p>
                                                                    </div>
                                                                    <div className="item d-flex align-center pointer" onClick={() => { actionHandler(val?.id, "reject"); setActiveManage(-1) }} >
                                                                        {/* <i className='icons mr-5 reject'></i> */}
                                                                        <p className="text-2xs w-500 secondary" >Reject</p>
                                                                    </div>
                                                                </div>}
                                                            </div>}
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={6}><DataNotFound /></td></tr>}
                                </tbody>
                            </table>
                            {/* {!dataForCurrentPage?.length ? <DataNotFound /> : ""} */}
                        </div>
                        {/* :
                            <DataNotFound />} */}
                    </div>


                    <div className="access-management-table-wrapper mt-10">
                        {/* {accessManagement?.data?.access_list?.length ? */}
                        <div className="table-wrap access-management-table">
                            <h4 className='base mb-10'>Access -</h4>
                            <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th className='name'> Name </th>
                                        <th className='email'>Email Address</th>
                                        <th className='level'>Access Level</th>
                                        <th className='Invitation'>Added On</th>
                                        <th className='Expire'>Access Expire</th>
                                        <th className='Status'>
                                            {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? "Invited by"
                                                : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? "Accepted by" : ''}

                                        </th>
                                        <th className='action'>Action</th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {accessManagement?.data?.access_list?.length ? accessManagement?.data?.access_list?.map((val, key) => {
                                        return (
                                            <tr className="table-row" key={key}>
                                                <td className='name'>
                                                    {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? val.typing_centre_name
                                                        : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? val.name : ''}
                                                </td>
                                                <td >
                                                    {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? val.typing_centre_email
                                                        : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? val.user_email : ''}
                                                </td>
                                                <td > Editor</td>
                                                <td > {dateFormat(val?.date_accepted)}</td>
                                                <td > {val?.access_expire ? val?.access_expire : "Never"}</td>
                                                <td >
                                                    {Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3 ? val?.invited_by ? val?.invited_by : "Self"
                                                        : Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4 ? val?.accepted_by : ''}
                                                </td>
                                                <td >
                                                    <button
                                                        className='button bg-secondary border-0 white btn w-400 text-xxs'
                                                        // onClick={() => deleteClickHandler(val?.typing_centre_id, 'access')}
                                                        onClick={() => {
                                                            const id = Auth?.boolUserType() === 2 ? val?.business_owner_id : val?.typing_centre_id;
                                                            deleteClickHandler(id, 'access');
                                                        }}

                                                        style={{ padding: "3px 5px", borderRadius: "4px" }}
                                                    >Remove access</button>
                                                    {/* <div className='dots-wrap relative' >
                                                        <img src={dots} alt="" onClick={() => { handleManageBtn(key) }} />
                                                        {activeManage === key && <div className="dropdown-wrap absolute" ref={ref}>
                                                            <div className="item d-flex align-center mb-10 pointer" onClick={() => deleteClickHandler(val?.typing_centre_id)}>
                                                                <i className='icons mr-5 delete'></i>
                                                                <p className="text-2xs w-500 secondary">Delete</p>
                                                            </div>
                                                            <div className="item d-flex align-center pointer" onClick={() => editBtnHandle(accessManagePopRef, val?.id)}>
                                                                <i className='icons mr-5 edit'></i>
                                                                <p className="text-2xs w-500 secondary" >Edit</p>
                                                            </div>
                                                        </div>}
                                                    </div> */}
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={7}><DataNotFound /></td></tr>}
                                </tbody>
                            </table>
                            {/* {!dataForCurrentPage?.length ? <DataNotFound /> : ""} */}
                        </div>
                        {/* : */}
                        {/* <DataNotFound />} */}
                    </div>
                </div>
                <AccessModal
                    reference={accessManagePopRef}
                    onClose={closeAccessPopup}
                    accessManagement={accessManagement}
                    giveAccessBtn={giveAccessBtn}
                    accessBOEmail={accessBOEmail}
                    acessBOError={acessBOError}
                    handleInputBOchange={handleInputBOchange}
                    resetAccessBOEmail={resetAccessBOEmail}
                    updateId={updateId}
                    selectAllEmpRef={selectAllEmpRef}
                    selectAll_docRef={selectAll_docRef}
                    selectAllDoc={selectAllDoc}



                    currentStep={currentStep} setCurrentStep={setCurrentStep}
                    accessOf={accessOf} setAccessOf={setAccessOf}
                    formData={formData} setFormData={setFormData}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    handleInputChange={handleInputChange} saveBtnHandler={saveBtnHandler} selectAllHandler={selectAllHandler}
                    employeeManagement={employeeManagement} documentUpload={documentUpload} showDocs={showDocs} searchValModal={searchValModal} handleSearchModal={handleSearchModal} handleResetSearchModal={handleResetSearchModal}
                    selectAll={selectAll} handleSelectedDocs={handleSelectedDocs} selectedEmployee={selectedEmployee}

                    folders={folders}
                    handleFolderInputChange={handleFolderInputChange}
                    handleComapanyDocInputChange={handleComapanyDocInputChange}


                    accessLevelDropHandler={accessLevelDropHandler}



                />
            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
                type={deleteType == "pending" ? "Revoke" : "Delete"}
                popupText={deleteType == "pending" ? "Are you sure you want to revoke access?" : "Are you sure you want to remove access?"}
            />}
        </>
    )
}

export default AccessManagement