import React, { useEffect, useState } from 'react'
import SectionHeading from '../../../CommanComponents/SectionHeading/SectionHeading'
import Tabs from '../../../CommanComponents/Tabs/Tabs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetDeleteClient, setClientActiveTab, setDeleteClient } from '../../../store/Actions/ManageCompany'
import PersonalClientData from './ClientPersonalData'
import './addCompany.scss'
import DeleteConfirmPop from '../../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import Auth from '../../../Classes/Auth'
import useClientPersonalData from '../../../CustomHooks/Clients/useClientPersonalData'
import usePersonalAddCompanyData from '../../../CustomHooks/ManageCompany/usePersonalAddCompanyData'
const AddCompany = () => {
    const { arrowBtn, arrowBtnHandler } = usePersonalAddCompanyData()
    const manageCompany = useSelector((e) => e.manageCompany)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user
    const [activeEdit, setActiveEdit] = useState(false)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const handleActiveTab = (e) => {
        dispatch(setClientActiveTab(e))
    }
    const editBtnHandler = () => {
        setActiveEdit(e => !e)
    }


    // Handle delete start

    const deleteHandler = (id) => {
        dispatch(setDeleteClient(id, userId))

    }

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteHandler(deleteId)
        dispatch(resetDeleteClient())
    }


    useEffect(() => {
        if (manageCompany?.delete) {
            navigate('/manage-companies')
        }
    }, [manageCompany?.delete])


    return (
        <div className="AddCompany-main-wrapper">
            <div className="heading-search-item  align-center wrap justify-between ">
                <SectionHeading  >
                    {
                        manageCompany.client_type === 'old' ?
                            manageCompany?.single_client?.data?.business_name
                            :
                            "Add new company"}
                </SectionHeading>

                <div className="employee-details-tabs-main-wrapper  ">
                    {/* <Tabs
                        // disableBtns={['Documents', 'Reminders']}
                        // tabs={['Personal', 'Documents', 'Reminders']}
                        tabs={['Personal']}
                        defaultValue={manageCompany?.activeTab}
                        activeTabHandle={(e) => handleActiveTab(e)}
                        TabButtonClass={"default-tab text-xxs w-700"}
                        ActiveTabClass={"active"}
                        TabWrapperClass={"setting-tabs-wrapper"}
                    /> */}
                </div>
                <div className="employee-top-wrapper d-flex justify-between   align-center">
                    <div className="employee-top-actions-wrap d-flex ">
                        {(manageCompany.client_type === 'old') ?
                            <>
                                <button className="icons-wrap transparent d-flex align-center justify-center" onClick={(e) => editBtnHandler()} > <i className="icons edit-icon"></i></button>
                                <button className="icons-wrap transparent d-flex align-center justify-center" onClick={() => { deleteClickHandler(manageCompany?.single_client?.data?.id) }}  > <i className="icons delete-icon"></i></button>
                                <button className={`icons-wrap transparent d-flex align-center justify-center`} disabled={arrowBtn?.prev} onClick={() => arrowBtnHandler('prev')} > <i className="icons left-icon"></i></button>
                                <button className={`icons-wrap transparent d-flex align-center justify-center `} disabled={arrowBtn?.next} onClick={() => arrowBtnHandler('next')} > <i className="icons right-icon"></i></button>
                            </> : ""
                        }
                        <button onClick={() => navigate('/manage-companies')} className="icons-wrap transparent d-flex align-center justify-center"> <i className="icons cross-icon"></i></button>
                    </div>
                </div>
            </div>
            <div className="setting-content-wrap">
                {manageCompany?.activeTab === "Personal" &&
                    <PersonalClientData activeEdit={activeEdit} setActiveEdit={setActiveEdit} />
                }
            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
            />}
        </div>
    )
}

export default AddCompany