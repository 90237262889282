import { showSuccessToast } from "../SuccessMessagePopup"
import ConatctUsActions from "./actionType"
import contactUsReq from "./contactUsReq"

const setContactUs = (data) => {
    return async (dispatch) => {
        contactUsReq.post(`${contactUsReq.endpoint.contact_snapit}`, data, (success) => {
            dispatch({ type: ConatctUsActions.CONTACT_US_SUCCESS, payload: success?.data })
            dispatch(showSuccessToast(success?.data?.success))
        },
            (error) => {

            }, 'noAuth'
        )
    }
}


const resetContactUs = () => {
    return { type: ConatctUsActions.CONTACT_US_RESET }
}

export { setContactUs, resetContactUs }