import Api from "./Classes/Api";
export const getBaseUrl = () => {   // for get baseUrl of running Api
    const api = new Api()
    const getMode = api.apiConfig.mode
    let baseUrl;
    if (getMode === "development") {
        return baseUrl = api.getApiBaseUrl().slice(0, -1);
    } else if ((getMode === "production")) {
        return baseUrl = process.env.REACT_APP_BACKEND_URL
    } else if ((getMode === "productiondev")) {
        return baseUrl = process.env.REACT_APP_BACKEND_URL_DEV
    } else if ((getMode === "productiondev2")) {
        return baseUrl = process.env.REACT_APP_BACKEND_URL_DEV2
    }
}


export const documentDefaultView = 'list'; // for notification trigger time

export const noticationTiggerTime = 300000; // for notification trigger time
// export const noticationTiggerTime = 1000; // for notification trigger time

export const EmployeeDocumentAccepted = [
    'Passport',
    'Visa',
    'Emirates ID',
    'Medical insurance',
    'Driving license',
    'Family documents',
    'Others'
]
export const EmployeeDocumentAccepted2 = [
    {
        title: 'Passport',
        value: 'passport'
    },
    {
        title: 'Visa',
        value: 'visa'
    },
    {
        title: 'Emirates ID',
        value: 'emirates_id'
    },
    {
        title: 'Medical insurance',
        value: 'medical_insurance'
    },
    {
        title: 'Driving license',
        value: 'driving_license'
    },
    {
        title: 'Family documents',
        value: 'family_documents'
    },
    {
        title: 'Others',
        value: 'others'
    },
]

export const ALLOW_IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "webp", "JPEG", "JPG", "PNG", "GIF", "BMP", "TIFF", "TIF", "WEBP"]

// export const ALLOW_FOLDER_EXTENSIONS_ARRAY=[...ALLOW_IMAGE_EXTENSIONS,]


export let adminID;
const api = new Api()
const getMode = api.apiConfig.mode
if (getMode === "development") {
    adminID = 14
} else if ((getMode === "production")) {
    adminID = 20087
} else if ((getMode === "productiondev")) {
    adminID = 10012
}


export const supportStatusOpt = ["New", "Open", "Assigned", "In-Progress", "Close"]
