import RecycleBinActions from "../Actions/RecycleBin/actionType"

const initState = {
    loading: false,
    success: false,
    error: false,
    data: []
}

const recycleBin = (state = initState, { type, payload }) => {
    switch (type) {
        case RecycleBinActions.SET_RECYCLE_LOADING: return {
            ...state,
            loading: true
        }
        case RecycleBinActions.SET_RECYCLE_LIST_SUCCESS: return {
            ...state,
            loading: false,
            data: payload
        }
        default: return state
    }
}

export default recycleBin