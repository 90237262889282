import foldersReq from './folderReq'
import CompanyDocumentsAction from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
import { setCommonError } from '../CommonError'
let clientIdSet = null;
const companydocsCreateNewFolder = (data) => {
    return async (dispatch) => {
        foldersReq.post(foldersReq.endpoint.create_company_folder, data, (success) => {
            dispatch({ type: CompanyDocumentsAction.CREATE_COMPANY_DOCS_SUCCESS },)
            dispatch(showSuccessToast("Folder created successfully!"))
        },
            (error) => {
                dispatch({ type: CompanyDocumentsAction.CREATE_COMPANY_DOCS_ERROR, payload: "Folder name already exists." },)
            }
        )
    }
}

const getComapanyDocumentsFoldersList = (userId, client_id) => {
    clientIdSet = client_id
    return async (dispatch) => {
        dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADING })
        foldersReq.get(foldersReq.endpoint.get_company_folder.replace("USER_ID", userId).replace("__CLIENT_ID__", client_id), (success) => {
            console.log(success, "SAfsafgv")
            dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADED, payload: success?.data })
        },
            (error) => {
                dispatch(setCommonError(error?.message))
            }
        )
    }
}
const deleteFolder = (id, userId, folderId) => {
    return async (dispatch) => {
        foldersReq.delete(`${foldersReq.endpoint.delete_company_folders}/${id}`, (success) => {
            dispatch({ type: CompanyDocumentsAction.DELETE_COMPANY_DOCS_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
            if (folderId !== null) {
                dispatch(getCompanyDocsSubFolder(folderId))
            } else {
                dispatch(getComapanyDocumentsFoldersList(userId, clientIdSet))
            }
        },
            (error) => {

            }
        )
    }
}
const deleteFiles = (id, userId, folderId) => {
    return async (dispatch) => {
        foldersReq.delete(`${foldersReq.endpoint.delete_company_file}/${id}`, (success) => {
            dispatch({ type: CompanyDocumentsAction.DELETE_COMPANY_DOCS_FILES_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
            if (folderId !== null) {
                dispatch(getCompanyDocsSubFolder(folderId))
            } else {
                dispatch(getComapanyDocumentsFoldersList(userId, clientIdSet))
            }
        },
            (error) => {

            }
        )
    }
}


const renameCompanyDocsFolder = (id, data, folderId, userId) => {
    return async (dispatch) => {
        foldersReq.put(`${foldersReq.endpoint.rename_company_folder}/${id}`, data, (success) => {
            dispatch({ type: CompanyDocumentsAction.RENAME_COMPANY_DOCS_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
            if (folderId !== null) {
                dispatch(getCompanyDocsSubFolder(folderId))
            } else {
                dispatch(getComapanyDocumentsFoldersList(userId, clientIdSet))
            }
        },
            (error) => {
                dispatch(setCommonError(error?.message))
            }
        )
    }
}

const serachFolder = (value, userId, folderId) => {
    // Correcting the URL to be well-formed
    const url = window.location.href;
    const correctedUrl = url.replace('?', '&amp;').replace('&amp;', '?');

    // Parsing the corrected URL
    const urlObj = new URL(correctedUrl);

    // Extracting the search parameters
    const params = new URLSearchParams(urlObj.search);

    // Getting the folderId
    const folderId2 = params.get('folderId');
    return async (dispatch) => {
        if (value?.length > 0) {
            foldersReq.get(foldersReq.endpoint.search_company_folders.replace('__VALUE__', value).replace("USER_ID", userId), (success) => {
                // let payload = success.data?.map((item, index, array) => {
                //     return array[array.length - 1 - index];
                // });
                dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_SEARCH_SUCCESS, payload: success.data },)
            },
                (error) => {

                }
            )
        } else {
            console.log(folderId2, "folderId11111 serachFolder")
            dispatch(searchReset(userId, folderId2))
        }
    }
}
const getCompanyDocsSubFolder = (id) => {
    return async (dispatch) => {
        dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADING })
        foldersReq.get(`${foldersReq.endpoint.get_company_subfolder}/${id}`, (success) => {
            dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_LIST_LOADED, payload: success?.data })
        },
        )
    }
}
const uploadComapanyDocsFile = (data, id, folderId) => {
    return async (dispatch) => {
        foldersReq.post(foldersReq.endpoint.upload_companydoc, data, (success) => {
            // dispatch({ type: CompanyDocumentsAction.FOLDER_DETAIL_SET, payload: payload },)
            dispatch(showSuccessToast(success?.data?.message))
            if (folderId !== null) {
                dispatch(getCompanyDocsSubFolder(folderId))
            } else {
                dispatch(getComapanyDocumentsFoldersList(id, clientIdSet))
            }
        },
            (error) => {

            }
        )
    }
}



const setBreadcrumb = (payload) => {
    return { type: CompanyDocumentsAction.SET_BREADCRUMB, payload: payload }
}
const createFolderReset = () => {
    return { type: CompanyDocumentsAction.CREATE_COMPANY_DOCS_RESET }
}
const ErrorReset = () => {
    return { type: CompanyDocumentsAction.CREATE_COMPANY_DOCS_ERROR_RESET }
}
const renameReset = () => {
    return { type: CompanyDocumentsAction.RENAME_COMPANY_DOCS_RESET }
}
const searchReset = (userId, folderId) => {
    return (dispatch) => {
        console.log(folderId, "folderId11111 searchReset 111")
        if (folderId !== null) {
            dispatch(getCompanyDocsSubFolder(folderId))
        } else {
            dispatch(getComapanyDocumentsFoldersList(userId, clientIdSet))
        }
    }
}

const setReminderFolder = (data) => {
    return async (dispatch) => {
        foldersReq.put(`${foldersReq.endpoint.set_reminder_folder}/${data?.folder_id}`, data, (success) => {
            dispatch({ type: CompanyDocumentsAction.SET_REMINDER_SUCCESS },)
            dispatch(showSuccessToast(success?.data?.message))
        },
            (error) => {
                dispatch({ type: CompanyDocumentsAction.SET_REMINDER_ERROR, payload: error?.response?.data?.error },)
            }
        )
    }
}
const setReminderFile = (data) => {
    return async (dispatch) => {
        foldersReq.put(`${foldersReq.endpoint.set_reminder_file}/${data?.folder_id}`, data, (success) => {
            dispatch({ type: CompanyDocumentsAction.SET_REMINDER_SUCCESS },)
            dispatch(showSuccessToast(success?.data?.message))
        },
            (error) => {
                dispatch({ type: CompanyDocumentsAction.SET_REMINDER_ERROR, payload: error?.response?.data?.error },)
            }
        )
    }
}



const triggerCompanyDocumentsNotification = (user_id) => {
    return async (dispatch) => {
        foldersReq.get(`${foldersReq.endpoint.trigger_folder_and_file_notification}/${user_id}`, (success) => {
            dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_NOTIFICATION_TRIGGER_SET, payload: success.data })
        },
            (error) => {

            }
        )
    }
}
const setCompanyDocumentsNotifications = (user_id) => {
    return async (dispatch) => {
        foldersReq.get(`${foldersReq.endpoint.get_folder_file_notification}/${user_id}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });

            dispatch({ type: CompanyDocumentsAction.COMPANY_DOCS_NOTIFICATION_SUCCESS, payload: success.data })
        },
            (error) => {

            }
        )
    }
}



const resetFolderDetail = () => {
    return { type: CompanyDocumentsAction.COMPANY_DOCS_DETAIL_RESET }
}
const resetFolderList = () => {
    return { type: CompanyDocumentsAction.COMPANY_DOCS_LIST_RESET }
}
const resetReminderFolder = () => {
    return { type: CompanyDocumentsAction.RESET_REMINDER_SUCCESS }
}
const resetReminderError = () => {
    return { type: CompanyDocumentsAction.RESET_REMINDER_ERROR }
}

const setCompanyDocsClient = (val) => {
    return {
        type: CompanyDocumentsAction.COMPANY_DOCS_SET_CLIENT,
        payload: val
    }
}

export {
    companydocsCreateNewFolder, getComapanyDocumentsFoldersList, createFolderReset, ErrorReset, deleteFolder,
    renameCompanyDocsFolder, renameReset, serachFolder, searchReset, getCompanyDocsSubFolder,
    uploadComapanyDocsFile, resetFolderDetail, resetFolderList, setBreadcrumb, deleteFiles,
    setReminderFolder, resetReminderFolder, setReminderFile, triggerCompanyDocumentsNotification,
    setCompanyDocumentsNotifications, resetReminderError, setCompanyDocsClient

}