import Request from "../../../Classes/Requests";

class foldersReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            Create_Folder: super.url("/Create_Folder"),
            List_folders: super.url("/List_folders?added_by_user_id=USER_ID&client_id=__CLIENT_ID__"),
            Delete_folders: super.url("/Delete_folders"),
            Rename_folders: super.url("/Rename_folders"),
            files_rename: super.url("/files_rename"),
            Search_folders: super.url("/Search_folders?query=__VALUE__&added_by_user_id=USER_ID"),
            Folders_detail: super.url("/Folders_detail"),
            upload_file_in_folder: super.url("/upload_file_in_folder"),
            Delete_file: super.url("/Delete_file"),
        }
    }
}
export default new foldersReq();
