import React from "react";
import "./JoyFulUsers.scss";
import Happy1 from "./images/Happy1.png";
import Happy2 from "./images/Happy2.png";

import Happy3 from "./images/Happy3.png";
import Happy4 from "./images/Happy4.png";

const JoyFulUsers = () => {
  return (
    <div className="joyful-users-container">
      <div className="text-content">
        <h1>Our Joyful Users</h1>
        <p>
          SnapIT's Document Management System has transformed the way our
          customers handle documents. From enhanced organization to seamless
          collaboration, businesses across industries trust SnapIT for its
          efficiency and reliability. We're proud to be the solution they count
          on to streamline workflows and achieve success every day.
        </p>
        <h2>Abu Dhabi</h2>
        <div className="clients">
          <img src={Happy1} alt="Waiz Logo" />
          <img src={Happy2} alt="Info Hub Typing Services Logo" />
          <img src={Happy3} alt="Takhlees Logo" />
          <img src={Happy4} alt="Signature Typing Logo" />
        </div>
      </div>
    </div>
  );
};

export default JoyFulUsers;
