import React, { useState } from 'react'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import './recycleBin.scss'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import { useDispatch, useSelector } from 'react-redux'
import useRecycleBin from '../../CustomHooks/RecycleBin/useRecycleBin'
import usePageListData from '../../CustomHooks/usePageListData'
import { deleteSingle, emptyTrash, restoreSingleData } from '../../store/Actions/RecycleBin'
import Auth from '../../Classes/Auth'
const RecycleBin = () => {
    const { } = useRecycleBin()
    const userId = Auth?.user()?.user_id // this is for added by user
    const recycleBin = useSelector((e) => e.recycleBin)
    const dispatch = useDispatch()
    const [pageNumber, setPageNumber] = useState(1)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [actionsType, setActionsType] = useState("");

    const employeeManagement = {
        data: [
            // {
            //     "employee_id": 9,
            //     "first_name": "WQe",
            //     "last_name": "Dwrewr",
            //     "e_mail": "testww.t3est@gmail.com",
            //     "mobile": "971 234543564364",
            //     "current_country": "Kuwait",
            //     "employee_image": null,
            //     "client_id": null
            // },
            ...(recycleBin?.data?.Folders || []),
            ...(recycleBin?.data?.File || []),
            ...(recycleBin?.data?.Company_folders || []),
            ...(recycleBin?.data?.Company_files || []),
        ]
    }
    const dataForCurrentPage = usePageListData(employeeManagement?.data, pageNumber)

    const actionsHandler = (id, type) => {
        setActionsType(type)
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = (e, actionsType) => {
        setDeleteId("");
        setDeletePop(false);
        if (actionsType === "Empty trash") {
            dispatch(emptyTrash(userId))
        } else if (actionsType === "Restore") {
            const data = { [deleteId?._type]: [deleteId] }
            dispatch(restoreSingleData(data, userId))
        } else if (actionsType === "Delete") {
            const data = { [deleteId?._type]: [deleteId] }
            console.log(data, "Dsgsdfhgb")
            dispatch(deleteSingle(data, userId))
        }
    }
    console.log(employeeManagement, "recycleBin")
    return (
        <div className="recycle-bin-wrapper">
            <div className="heading-search-item justify-between align-center">
                <SectionHeading>
                    Recycle bin
                </SectionHeading>
                <div className='d-flex align-center justify-center search-page-wrap '>
                    {/* <SearchControl
                        // value={searchVal}
                        placeholder={'Search for name'}
                        // onChange={handleSearch}
                        // reset={() => handleResetSearch()}
                        classNameWrappper={'employees-search'}
                    /> */}
                    {/* ------- Pagination add ------ */}
                </div>

                <div className="actions-wrappper plus-emp-wrapper relative">
                    {/* <CommanButton ClassName={"action-btn"} >
                        <p className='w-700 d-flex align-center para-wrap'>
                            <i className='icons sT-icon primary i-xs delete-icon '></i>
                            Restore trash</p>
                    </CommanButton> */}
                    <CommanButton ClassName={"action-btn"} onClick={() => { actionsHandler(3, "Empty trash") }} >
                        <p className='w-700 d-flex align-center para-wrap'>
                            <i className='icons sT-icon primary i-xs delete-icon '></i>
                            Empty trash</p>
                    </CommanButton>
                </div>
                <PaginationSimple
                    className={"document-pagenation"}
                    data={employeeManagement?.data}
                    shows={employeeManagement?.data?.length < 20 ? employeeManagement?.data?.length : "20"}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            </div>
            <div className="employees-management-table-wrapper mt-10">

                {
                    <div className="table-wrap mt-20">
                        <table className="table-item">
                            <thead className='table-head'>
                                <tr className="table-row">
                                    <th className='name'>File name  </th>
                                    <th className='srNo'>Deleted date  </th>
                                    {/* <th className='email'>Deleted by</th> */}
                                    <th className='action'>Action</th>
                                </tr>
                            </thead>

                            <tbody className='table-body'>
                                {dataForCurrentPage?.map((val, key) => {
                                    return (
                                        <tr className="table-row" key={key}  >
                                            <td  >{val?.name ? val?.name : val?.file?.split('/')?.pop()}</td>
                                            <td  >12-09-2034</td>
                                            {/* <td>  Owner </td> */}
                                            <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                    <div className="item d-flex align-center justify-center pointer " title='Restore'
                                                        onClick={() => { actionsHandler(val, "Restore") }}
                                                    >
                                                        <i className='icons sT-icon secondary i-xxs restore-icon'></i>
                                                    </div>
                                                    <div className="item d-flex align-center justify-center pointer " title='Delete permanent'
                                                        onClick={() => { actionsHandler(val, "Delete") }}
                                                    >
                                                        <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                }

                {deletePop && <DeleteConfirmPop
                    handleCancelVar={CancleDelete}
                    type={actionsType === "Restore" ? "Restore" : "Delete"}
                    popupText={actionsType === "Empty trash" ? "All items in the trash will be deleted forever and you won't be able to restore them." : actionsType === "Delete" ? "Deleted forever and you won't be able to restore them." : "Are you sure you want to restore ?"}
                    headingPara={actionsType === "Empty trash" && "Delete forever ?"}
                    deleteVarHandler={(e) => handleDeleteBtn(e, actionsType)}
                />}
            </div>
        </div>
    )
}

export default RecycleBin