import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
// import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
// import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import { EmployeeDocumentAccepted } from '../../config';
import useDocuments from '../../CustomHooks/Documents/useDocuments';
const DocumentListView = ({ document_param, DocumentDetailsHandler }) => {
    const documents = useSelector(e => e.documents)
    // const { document_param, DocumentDetailsHandler } = useDocuments()
    return (
        <div className="list-folder-item-wrap ">
            <div className="folder-table-wrapper ">
                <div className="table-wrap">
                    <table className="table-item">
                        <thead className='table-head'>
                            <tr className="table-row">
                                <th > Document Type  </th>
                                <th > Emolpyee count  </th>
                                <th > Document count </th>
                            </tr>
                        </thead>

                        <tbody className='table-body'>
                            {document_param == 'all' && documents.document_count?.map((val, key) => {
                                return (
                                    <tr className="table-row relative" key={key} onClick={() => DocumentDetailsHandler(val?.document_name)}>
                                        <td  >
                                            <div className="folder-name-wrap d-flex align-center">
                                                <i className="folder-icon"></i>
                                                {val?.document_name}
                                            </div>
                                        </td>
                                        <td >
                                            {val.employee_count}
                                        </td>
                                        <td >
                                            {val.no_passports ? val.no_passports : 0}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default DocumentListView