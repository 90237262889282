import React, { useEffect } from 'react'
import Modals from '../../../../../../CommanComponents/Modals'
import ModalsHeader from '../../../../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../../../../CommanComponents/Modals/ModalsBody'
import './folderModal.scss';
import CommanButton from '../../../../../../CommanComponents/CommanButton/CommanButton';
import useFolders from '../../../../../../CustomHooks/Folders/useFolders';
import FormError from '../../../../../../CommanComponents/Form/FormError';
import { useSelector } from 'react-redux';
const FolderModal = ({ reference, onClose, setShowError, uploadType, folderName, handleInput, saveBtnHandle, showError, resetFolderName, data }) => {
    const folders = useSelector((e) => e.folders) // FOLDERS SELECTOR    
    useEffect(() => {
        if (data?.created || data?.rename) {
            onClose();
        }
    }, [data, onClose]);

    const onCloseFunc = () => {
        onClose()
        resetFolderName(); // Reset folderName state
        setShowError(false)
    }
    console.log(folderName, "SDfgrghreg")
    return (
        <Modals
            ref={reference}
            ClosePopUp={onCloseFunc}
            // slide={'top'}
            slide={"zoom"}
            Position="center"
            ModalsSize={'modal-xs'}
            ClassName={'folder-modal-wrapper'}
        // croseShow={true}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>{uploadType === 'create' ? 'Create New Folder' : uploadType === 'rename' ? 'Rename folder' : "Rename file"}</p>
                </h4>
            </ModalsHeader>
            <ModalBody >
                <form className="folder-modal-content-wrap" onSubmit={(e) => { e.preventDefault(); saveBtnHandle() }}  >
                    <div className="form-wrapper">
                        <div className="form-field ">
                            <input type="text" className='folder-input base' placeholder='Enter Folder Name*'
                                name='name'
                                value={folderName?.name?.value}
                                onChange={handleInput}
                                maxLength={25}
                            />
                        </div>
                        <FormError show={!folderName?.name?.isValid && showError}
                            error={'Please enter folder name.'}
                            className={'base'}
                        />
                        {data?.error_msg?.length ? <FormError show={true}
                            error={data?.error_msg}
                            className={'base'}
                        /> : ''}

                    </div>
                    <CommanButton
                        // onClick={saveBtnHandle}
                        type={'submit'}
                        Text={uploadType === 'create' ? 'Create ' : 'Rename'}
                        ClassName={'folder-btn mt-50 mb-5'}
                    />
                </form>
            </ModalBody >
        </Modals>
    )
}

export default FolderModal