import React, { useState } from 'react'
import { AccessConsumer } from '../../../../../../../../../Context/AccessContext';
import CheckboxInput from '../../../../../../../../../CommanComponents/Form/CheckboxInput';
import SearchControl from '../../../../../../../../../CommanComponents/SearchControl/SearchControl';
import "./selectFolders.scss"
import { data } from "./data"
import { useSelector } from 'react-redux';
const SelectFolders = () => {
    const accessManagement = useSelector(e => e.accessManagement)
    const [collapsedFolders, setCollapsedFolders] = useState({}); // Track collapsed state for each folder

    const toggleFolder = (folderId) => {
        setCollapsedFolders((prevState) => ({
            ...prevState,
            [folderId]: !prevState[folderId], // Toggle the collapsed state
        }));
    };
    console.log(collapsedFolders, "collapsedFolders")
    return (
        <AccessConsumer>
            {(value) => {
                const { selectAllHandler, employeeManagement, handleFolderInputChange, searchValModal,
                    handleSearchModal,
                    handleResetSearchModal, formData, selectAll, handleSelectedDocs, selectedEmployee, selectAllEmpRef, folders, } = value


                // Helper function to check if an employee is selected 
                const checkedArrayFolders = formData?.folders?.selected_folders?.map((item, index) => {
                    return item?.id
                });
                const checkedArrayFiles = formData?.files?.selected_files?.map((item, index) => {
                    return item?.id
                });
                // Helper function to check if an employee is selected  
                console.log(checkedArrayFolders, "SADvdfbfdgb")

                return (
                    <div className="SelectEmployees-wrapper SelectFolders-wrapper">
                        <div className="employee-wrapper">
                            <ul className="employee-wrap">
                                <div className='access-employee-search'>
                                    <SearchControl
                                        value={searchValModal}
                                        placeholder={'Search...'}
                                        onChange={handleSearchModal}
                                        reset={() => handleResetSearchModal()}
                                        classNameWrappper={'access-employees-search mb-15'}
                                        className={"access-employees-search-input"}
                                    />

                                    {/* ------- Pagination add ------ */}
                                </div>
                                {!searchValModal.length && <li>
                                    <div className="input-custom-type mb-10">
                                        <CheckboxInput
                                            label={`Select All`}
                                            LabelClass={"secondary"}
                                            className={""}
                                            onChange={(e) => selectAllHandler(e)}
                                            name="select_all_emp"
                                            ref={selectAllEmpRef}
                                            checked={employeeManagement?.data?.length === formData?.employee?.selected_employees?.length}
                                        />
                                    </div>
                                </li>}

                                {accessManagement?.folders?.map((val, key) => {
                                    return (
                                        <>
                                            <li className='step1'>
                                                <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                    <CheckboxInput
                                                        className={"selected_folder"}
                                                        name={JSON.stringify(val)}
                                                        value={JSON.stringify(val)}
                                                        onChange={(e) => handleFolderInputChange(e)}
                                                        checked={checkedArrayFolders?.includes(val?.id)}
                                                    />
                                                    <div className='d-flex align-center'>
                                                        <p className='secondary cursor mr-20'
                                                            onClick={(e) => { return toggleFolder(val?.id) }}
                                                        >
                                                            {val.name}
                                                        </p>
                                                        <i className={`icons sT-icon secondary i-xxs arrow-icon ${collapsedFolders[val?.id] ? "minus" : "plus"}-icon `}></i>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* {console.log((checkedArrayFolders?.includes(val?.id) || collapsedFolders), "SADvdfbfdgb 11111111111")} */}
                                            {(collapsedFolders[val?.id]) && val?.subfolder?.map((val, key) => {
                                                return (
                                                    <>
                                                        <li className={`step2`}>
                                                            <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                <CheckboxInput
                                                                    className={"selected_folder"}
                                                                    name={JSON.stringify(val)}
                                                                    value={JSON.stringify(val)}
                                                                    onChange={(e) => handleFolderInputChange(e)}
                                                                    checked={checkedArrayFolders?.includes(val?.id)}
                                                                />
                                                                <div className='d-flex align-center'>
                                                                    <p className='secondary cursor mr-20'
                                                                        onClick={(e) => { return toggleFolder(val?.id) }}
                                                                    >
                                                                        {val.name}
                                                                    </p>
                                                                    <i className={`icons sT-icon secondary i-xxs arrow-icon ${collapsedFolders[val?.id] ? "minus" : "plus"}-icon `}></i>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {collapsedFolders[val?.id] && val?.subfiles?.map((val, key) => {
                                                            return (
                                                                <>
                                                                    <li className={`step3`}>
                                                                        <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                            <CheckboxInput
                                                                                className={"selected_folder"}
                                                                                name={JSON.stringify(val)}
                                                                                value={JSON.stringify(val)}
                                                                                onChange={(e) => handleFolderInputChange(e)}
                                                                                checked={checkedArrayFiles?.includes(val?.id)}
                                                                            />
                                                                            <p className='secondary cursor'
                                                                                onClick={(e) => { return !selectAll.length ? handleSelectedDocs(val.id) : null }}
                                                                            >
                                                                                {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}
                                                                            </p>
                                                                        </div>
                                                                    </li>

                                                                </>
                                                            )
                                                        })}
                                                        {collapsedFolders[val?.id] && val?.subfolder?.map((val, key) => {
                                                            return (
                                                                <>
                                                                    <li className={`step3`}>
                                                                        <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                            <CheckboxInput
                                                                                className={"selected_folder"}
                                                                                name={JSON.stringify(val)}
                                                                                value={JSON.stringify(val)}
                                                                                onChange={(e) => handleFolderInputChange(e)}
                                                                                checked={checkedArrayFolders?.includes(val?.id)}
                                                                            />
                                                                            <div className='d-flex align-center'>
                                                                                <p className='secondary cursor mr-20'
                                                                                    onClick={(e) => { return toggleFolder(val?.id) }}
                                                                                >
                                                                                    {val.name}
                                                                                </p>
                                                                                <i className={`icons sT-icon secondary i-xxs arrow-icon ${collapsedFolders[val?.id] ? "minus" : "plus"}-icon `}></i>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    {collapsedFolders[val?.id] && val?.subfiles?.map((val, key) => {
                                                                        return (
                                                                            <>
                                                                                <li className={`step4`}>
                                                                                    <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                                        <CheckboxInput
                                                                                            className={"selected_folder"}
                                                                                            name={JSON.stringify(val)}
                                                                                            value={JSON.stringify(val)}
                                                                                            onChange={(e) => handleFolderInputChange(e)}
                                                                                            checked={checkedArrayFiles?.includes(val?.id)}
                                                                                        />
                                                                                        <p className='secondary cursor'
                                                                                            onClick={(e) => { return !selectAll.length ? handleSelectedDocs(val.id) : null }}
                                                                                        >
                                                                                            {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}
                                                                                        </p>
                                                                                    </div>
                                                                                </li>

                                                                            </>
                                                                        )
                                                                    })}
                                                                    {
                                                                        collapsedFolders[val?.id] && val?.subfolder?.map((val, key) => {
                                                                            return (
                                                                                <>
                                                                                    <li className={`step4`}>
                                                                                        <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                                            <CheckboxInput
                                                                                                className={"selected_folder"}
                                                                                                name={JSON.stringify(val)}
                                                                                                value={JSON.stringify(val)}
                                                                                                onChange={(e) => handleFolderInputChange(e)}
                                                                                                checked={checkedArrayFolders?.includes(val?.id)}
                                                                                            />
                                                                                            <div className='d-flex align-center'>
                                                                                                <p className='secondary cursor mr-20'
                                                                                                    onClick={(e) => { return toggleFolder(val?.id) }}
                                                                                                >
                                                                                                    {val.name}
                                                                                                </p>
                                                                                                <i className={`icons sT-icon secondary i-xxs arrow-icon ${collapsedFolders[val?.id] ? "minus" : "plus"}-icon `}></i>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>

                                                                                    {collapsedFolders[val?.id] && val?.subfiles?.map((val, key) => {
                                                                                        return (
                                                                                            <>
                                                                                                <li className={`step5`}>
                                                                                                    <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                                                        <CheckboxInput
                                                                                                            className={"selected_folder"}
                                                                                                            name={JSON.stringify(val)}
                                                                                                            value={JSON.stringify(val)}
                                                                                                            onChange={(e) => handleFolderInputChange(e)}
                                                                                                            checked={checkedArrayFiles?.includes(val?.id)}
                                                                                                        />
                                                                                                        <p className='secondary cursor'
                                                                                                            onClick={(e) => { return !selectAll.length ? handleSelectedDocs(val.id) : null }}
                                                                                                        >
                                                                                                            {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </li>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                    {
                                                                                        collapsedFolders[val?.id] && val?.subfolder?.map((val, key) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <li className={`step5`}>
                                                                                                        <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                                                            <CheckboxInput
                                                                                                                className={"selected_folder"}
                                                                                                                name={JSON.stringify(val)}
                                                                                                                value={JSON.stringify(val)}
                                                                                                                onChange={(e) => handleFolderInputChange(e)}
                                                                                                                checked={checkedArrayFolders?.includes(val?.id)}
                                                                                                            />
                                                                                                            <p className='secondary cursor'
                                                                                                                onClick={(e) => { return toggleFolder(val?.id) }}
                                                                                                            >
                                                                                                                {val.name}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}

                                            {/* Filessss  */}
                                            {collapsedFolders[val?.id] && val?.subfiles?.map((val, key) => {
                                                return (
                                                    <>

                                                        <li className={`step2`}>
                                                            <div className={`input-custom-type select-employee-checkbox-wrap `}  >
                                                                <CheckboxInput
                                                                    className={"selected_folder"}
                                                                    name={JSON.stringify(val)}
                                                                    value={JSON.stringify(val)}
                                                                    onChange={(e) => handleFolderInputChange(e)}
                                                                    checked={checkedArrayFiles?.includes(val?.id)}
                                                                />
                                                                <p className='secondary cursor'
                                                                    onClick={(e) => { return !selectAll.length ? handleSelectedDocs(val.id) : null }}
                                                                >
                                                                    {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}
                                                                </p>
                                                            </div>
                                                        </li>

                                                    </>
                                                )
                                            })}

                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }}

        </AccessConsumer >
    )
}

export default SelectFolders