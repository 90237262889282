import React, { useRef, useState } from 'react';
import './deleteConfirmPop.scss';
import ReactDom from 'react-dom'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';


const DeleteConfirmPop = ({ index, handleCancelVar, deleteVarHandler, loading, type = "Delete", popupText, headingPara }) => {

  const outsideRef = useRef(null)
  const [closeoutSide, setCloseOutSide] = useDetectOutsideClick(outsideRef, false)

  return ReactDom.createPortal(
    <React.Fragment>
      <div className='deletePop-container-admin'>
        <div className='trueThemepop-wrap-admin' ref={outsideRef}>
          <div className="deleteContent">
            {
              type === "Cancel" &&
              <button className='cancel-btn' onClick={() => handleCancelVar()}> <i className='text-xs  icon-cross' ></i></button>
            }
            <p className='deletepop-p text-center'>
              {headingPara ? headingPara : type === "Cancel" ? "Cancel Confirmation " : type === "Restore" ? "Restore Confirmation" : type === "Revoke" ? "Revoke Confirmation" : "Delete Confirmation"}</p>
            <p className='delete-para'>
              {popupText ? popupText : type == 'Cancel' ? "Are you sure you want to cancel ?" : type == 'Delete' ? "Are you sure you want to delete ?" : type === "Restore" ? "Are you sure you want to restore ?" : type === "Revoke" ? "Are you sure you want to revoke ?" : ""}
            </p>
          </div>
          <div className='deletePop-btn-wrapper'>
            <hr className='line' />
            <div className="d-flex">
              <button onClick={() => handleCancelVar()} className="buttonpop">Cancel</button>
              {
                loading ? (
                  <button className={`buttonpop  btn-delete`}>  {type === "Restore" ? "Restoring..." : type === "Cancel" ? "Cancelling..." : "Deleting..."}  </button>
                ) : (
                  <button onClick={() => { index || index === 0 ? deleteVarHandler(index, 'change') : deleteVarHandler('', 'multi') }} className={`buttonpop  btn-delete`}>  {type === "Cancel" || type === "Restore" ? "Confirm" : type === "Revoke" ? "Revoke" : "Delete"} </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>,

    document.getElementById('blue')
  )
}

export default DeleteConfirmPop