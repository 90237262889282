export const AccessManagement_Actions = {
    SET_GRANT_ACCESS_LOADING: "SET_GRANT_ACCESS_LOADING",

    SET_GRANT_ACCESS_SUCCESS: "SET_GRANT_ACCESS_SUCCESS",
    RESET_GRANT_ACCESS_SUCCESS: "RESET_GRANT_ACCESS_SUCCESS",

    SET_GRANT_ACCESS_ERROR: "SET_GRANT_ACCESS_ERROR",
    RESET_GRANT_ACCESS_ERROR: "RESET_GRANT_ACCESS_ERROR",

    SET_LIST_ACCESS_USERS_LOADING: "SET_LIST_ACCESS_USERS_LOADING",
    SET_LIST_ACCESS_USERS_SUCCESS: "SET_LIST_ACCESS_USERS_SUCCESS",

    GET_ACCESS_DETAILS_SUCCESS: "GET_ACCESS_DETAILS_SUCCESS",
    RESET_ACCESS_DETAILS_SUCCESS: "RESET_ACCESS_DETAILS_SUCCESS",
    UPDATE_ACCESS_DETAILS_SUCCESS: "UPDATE_ACCESS_DETAILS_SUCCESS",
    UPDATE_ACCESS_DETAILS_RESET: "UPDATE_ACCESS_DETAILS_RESET",

    GET_FOLDERS_AND_FILES_ACCESS_DATA: "GET_FOLDERS_AND_FILES_ACCESS_DATA",
    GET_DOCUMENTS_FOLDERS_AND_FILES_ACCESS_DATA: "GET_DOCUMENTS_FOLDERS_AND_FILES_ACCESS_DATA",

};