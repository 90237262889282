import React, { useEffect, useRef, useState } from 'react'
import RightSidebar from '../RightSideBar/RightSidebar'
import './loginStyle.scss'
import backIcon from '../../../Assets/icons/back-icon.svg'
import { Link, useNavigate, useParams } from "react-router-dom";
import Auth from '../../../Classes/Auth'
import FormError from '../../../CommanComponents/Form/FormError';
import ValidationFile from './ValidationFile';
import IndustryType from '../../IndustrySelection/IndustryType/IndustryType';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrorMessage, resetLogin, setlogin } from '../../../store/Actions/Auth';
import { hideSuccessToast } from '../../../store/Actions/SuccessMessagePopup';
import { notificationTrigger, setNotifications } from '../../../store/Actions/Notifications';
import Storage from '../../../Classes/Storage';
import { rem_user_name, rem_user_pass } from '../../../Constant/auth';
import { resetNewPass } from '../../../store/Actions/ForgotPassword';
import { adminID } from '../../../config';
import { setCompanyDocumentsNotifications, triggerCompanyDocumentsNotification } from '../../../store/Actions/CompanyDocuments';
const Login = () => {
    const { login, signUp, message } = useSelector((state) => {
        return ({
            login: state.auth.login,
            signUp: state.auth.signUp,
            message: state.auth
        })
    })
    const notifications = useSelector((state) => state.notifications)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const userId = Auth?.user()?.user_id // this is for added by user
    const indeustrySelectionRef = useRef();
    const [eyeBtn, setEyeBtn] = useState(true)

    const [showLoginError, setShowLoginError] = useState(false);

    // State to store user credentials
    const [storedCredentials, setStoredCredentials] = useState({
        username: Storage.alive(rem_user_name) && Storage.getString(rem_user_name) || '',
        password: Storage.alive(rem_user_pass) && Storage.getString(rem_user_pass) || '',
    });

    const [rememberMe, setRememberMe] = useState((storedCredentials.username.length && storedCredentials.password.length) || false); // State variable to track "Remember Me" checkbox

    const [loginData, setLoginData] = useState({
        email: {
            value: storedCredentials.username,
            isValid: storedCredentials.username.length || false,
        },
        password: {
            value: storedCredentials.password,
            isValid: storedCredentials.password.length || false,
        },
        validation: (storedCredentials.username.length && storedCredentials.password.length) || false,
    });

    const isFormValid = () => {
        return loginData.email.isValid && loginData.password.isValid ? true : false;
    };

    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...loginData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setLoginData(data);
        if (inputName === "password") {
            dispatch(resetErrorMessage())
        }
    };


    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "email":
                return ValidationFile.validEmpty(inputValue);

            case "password":
                return ValidationFile.validEmpty(inputValue);

            default:
                return false;
        }
    };


    const loginBtnHandle = async (e) => {
        e.preventDefault()
        if (loginData.validation) {

            const data = {
                email: loginData.email.value,
                password: loginData.password.value
            }

            // Save credentials to local storage if "Remember Me" is checked
            if (rememberMe) {
                Storage.set(rem_user_name, data?.email)
                Storage.set(rem_user_pass, data?.password)
            } else {
                Storage.remove(rem_user_name)
                Storage.remove(rem_user_pass)
            }


            dispatch(setlogin(data))
            if (login.success) {
                Auth.setLogin();
                navigate('/summary');
                // You can also show a success popup here if needed
            } else {
                // Handle invalid login credentials here
                // For example, you can show an error message
                setShowLoginError(true);
            }
        } else {
            setShowLoginError(true);
        }
    }

    const signupBtnHandle = () => {
        indeustrySelectionRef.current.open();
    }

    const closeIndustrySelectionPop = () => {
        indeustrySelectionRef.current.close();
    }

    useEffect(() => {
        if (signUp.success) {
            dispatch({ type: "SET_SIGNUP_HIDE" })
        }
        if (login.success) {
            Auth.setLogin()
            // navigate('/summary')

            if (Auth?.boolUserType() === 3 && adminID === Auth?.user().user_id) {
                window.location.href = "/snapit-admin"
            } else if (Auth?.boolUserType() !== 5) {
                window.location.href = "/summary"
                // navigate('/summary')
            } else {
                window.location.href = `/employees-management/id=${Auth?.user()?.employee_id}`
                // navigate(`/employees-management/id=${Auth?.user()?.employee_id}`)
            }

            dispatch(notificationTrigger(userId))
            dispatch(setNotifications(userId))
            dispatch(triggerCompanyDocumentsNotification(userId))
            dispatch(setCompanyDocumentsNotifications(userId))
            dispatch(resetLogin())
        }
    }, [signUp.success, login.success])

    useEffect(() => {
        dispatch(resetErrorMessage())
        // setLoginData({
        //     email: {
        //         value: storedCredentials.username,
        //         isValid: storedCredentials.username.length || false,
        //     },
        //     password: {
        //         value: storedCredentials.password,
        //         isValid: storedCredentials.password.length || false,
        //     },
        //     validation: (storedCredentials.username.length && storedCredentials.password.length) || false,
        // })
    }, [])
    // useEffect(() => {
    //     if (notifications?.trigger) {
    //         dispatch(setNotifications(userId))
    //     }
    // }, [notifications?.trigger])

    useEffect(() => {
        dispatch(resetNewPass())
    }, [])
    return (
        <div className="login-wrapper">
            <RightSidebar>
                <div className="form-container">
                    {/* <button className="back-wrap mb-40 transparent" onClick={() => navigate('/')}>
                        <img src={backIcon} alt="" />
                    </button> */}
                    <div className="form-wrap">
                        <form className="form" onSubmit={loginBtnHandle}>
                            <h3 className="heading text-md w-500 primary text-center">Welcome</h3>
                            <p className="sub-heading w-400 text-center secondary"> Please enter your details</p>
                            <div className="form-control mt-40">
                                <div>
                                    <div className="form-field ">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder='Username*'
                                            value={loginData.email.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}
                                        />
                                        <i className="icon user-icon"></i>
                                    </div>
                                    <FormError show={!loginData.email.isValid && showLoginError} error={'Please enter username'} />
                                </div>
                                <div>
                                    <div className="form-field ">
                                        <input
                                            type={eyeBtn ? 'password' : 'text'}
                                            name="password" placeholder='Password*'
                                            className='pass-input'
                                            value={loginData.password.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}

                                        />
                                        <i className="icon lock-icon"></i>
                                        <button className="eye-btn" type='button' onClick={() => setEyeBtn((e) => !e)}>
                                            <i className={`${eyeBtn ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                        </button>
                                    </div>
                                    <FormError show={!loginData.password.isValid && showLoginError} error={'Please enter password'} />
                                </div>
                            </div>
                            <FormError show={message?.message?.length && loginData.password.isValid && true} error={message?.message} />
                            <div className="remember-pass-wrapper mt-15">
                                <div className="remember-wrap">
                                    <input
                                        type="checkbox"
                                        name=""
                                        id='remember'
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                    />
                                    <label htmlFor='remember' className='w-400 text-xs secondary'>Remember Me</label>
                                </div>
                                <button className='w-400 text-xs primary transparent' type='button' onClick={() => navigate('/forgot-password')}>Forgot password?</button>
                            </div>
                            <button type='submit' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white"

                            >
                                Login
                            </button>
                            <p className='signup-btn text-center mt-30 text-xs w-400'>Don’t have an account? <span className='pointer w-600 signup-para' onClick={signupBtnHandle}> Sign up</span></p>
                        </form>
                    </div>
                </div>
            </RightSidebar>
            <IndustryType reference={indeustrySelectionRef} onClose={closeIndustrySelectionPop} />
        </div>
    )
}

export default Login