import remindersReq from './remindersReq'
import RemindersAction from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
const getReminderList = (id) => {
    return async (dispatch) => {
        remindersReq.get(`${remindersReq.endpoint.list_of_reminder}/${id}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: RemindersAction.REMINDER_LIST_SET, payload: payload })
        },
            (error) => {

            }
        )
    }
}

const setRemider2 = (id, docType, data) => {
    return async (dispatch) => {
        remindersReq.post(remindersReq.endpoint.reminder_2.replace('__EMPLOYEE__ID', id)
            .replace('__VALUE__', docType), data, (success) => {
                dispatch(showSuccessToast('Update successfully!'))
                dispatch(getReminderList(id))
            }, (error) => {

            }
        )
    }
}
const setReminders = (id, data) => {
    return async (dispatch) => {
        remindersReq.put(`${remindersReq.endpoint.set_reminders}/${id}`, data, (success) => {
            dispatch(showSuccessToast(success?.data?.message))
            dispatch(getReminderList(id))
        }, (error) => {

        }
        )
    }
}

const resetReminderList = () => {
    return { type: RemindersAction.REMINDER_LIST_RESET }
}

export { getReminderList, resetReminderList, setRemider2, setReminders }