import Request from "../../../Classes/Requests";

class AccessManagementReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            grant_access: super.url("/grant_access"),
            check_typing_center_exists: super.url("/check_typing_center_exists"),
            list_access_users: super.url("/list_access_users?business_owner_id=__USER_ID__"),
            update_access: super.url("/update_access"),
            // delete_access: super.url("/delete_access?business_owner_id=__USER_ID__"),
            delete_access: super.url("/delete_access?business_owner_id=__USER_ID__&typing_centre_id=__TC_ID__"),
            get_typing_centre_invites_and_access_list: super.url("/get_typing_centre_invites_and_access_list?typing_centre_id=__USER_ID__"),
            respond_to_invitation: super.url("/respond_to_invitation?invite_id=__ID__"),
            revoke_invitation: super.url("/revoke_invitation?invite_id=__ID__"),
            view_access_content: super.url("/view_access_content?email=__EMAIL_ID__"),
            update_content_access: super.url("/update_content_access"),
            get_folders_files_for_access: super.url("/get_folders_files_for_access?user_id=__USER_ID__"),
            get_company_folders_files_for_access: super.url("/get_company_folders_files_for_access?user_id=__USER_ID__"),
            send_invitaion_to_typing_center: super.url("/send_invitaion_to_typing_center"),
        }
    }
}
export default new AccessManagementReq();
