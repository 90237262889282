import aiChatReq from './aiChatReq'
import AiChatActions from './actionType'
import axios from 'axios'
import { hideLoadingFetch, showLoadingFetch } from '../LoaderFetching'

const getAiChatResponse = (data, signal) => {
    return async (dispatch) => {
        dispatch({ type: AiChatActions.AI_CHAT_LOADING });
        try {
            const response = await axios.post(process.env.REACT_APP_AI_CHAT, data, { signal });
            dispatch({ type: AiChatActions.AI_CHAT_SUCCESS, payload: response?.data });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                if (error?.response?.status === 404) {
                    // Handle 404 error
                }
                console.log('Request canceled', error?.response?.data?.error);
                dispatch({ type: AiChatActions.AI_CHAT_ERROR, payload: { response: error?.response?.data?.error } });
            }
        }
    }
}


// const getAiChatResponse = (data) => {
//     return async (dispatch) => {
//         dispatch({ type: AiChatActions.AI_CHAT_LOADING })
//         axios.post(process.env.REACT_APP_AI_CHAT, data)
//             .then(response => {
//                 dispatch({ type: AiChatActions.AI_CHAT_SUCCESS, payload: response?.data })

//             })
//             .catch(error => {
//                 if (error?.response?.status == 404) {
//                 }
//                 dispatch({ type: AiChatActions.AI_CHAT_ERROR })
//             })
//     }
// }



// const getAiChatResponse = (data) => {
//     return async (dispatch) => {
//         dispatch({ type: AiChatActions.AI_CHAT_LOADING });

//         // Simulating a 2-second loading delay
//         setTimeout(() => {
//             const dataPay = {
//                 "response": " Hi there! How can I assist you today? Please provide me with more context or information so that I can better understand your question and provide a helpful response."
//             };
//             dispatch({ type: AiChatActions.AI_CHAT_SUCCESS, payload: dataPay });
//         }, 2000);
//     };
// }



const resetChataI = () => {
    return async (dispatch) => {
        dispatch({ type: AiChatActions.AI_CHAT_RESET, })
    }
}


export { getAiChatResponse, resetChataI }