import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dashboard from '../DashBoard'
import './folders.scss';
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';
import { Link, useNavigate } from 'react-router-dom';
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading';
import CommanButton from '../../CommanComponents/CommanButton/CommanButton';
import FolderModal from '../DashBoard/RightBarPannel/TopActionButtons/Popups/FolderModal/FolderModal';
import SearchControl from '../../CommanComponents/SearchControl/SearchControl';
import Storage from '../../Classes/Storage';
import { folder_grid_View } from '../../Constant/auth';
import { useDispatch, useSelector } from 'react-redux';
import useFolders from '../../CustomHooks/Folders/useFolders';
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop';
import { searchReset, serachFolder } from '../../store/Actions/Folders';
import ImageViewer from '../../CommanComponents/ImageViewer/ImageViewer';
import FolderListView from './FolderListView';
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import Auth from '../../Classes/Auth';
import DocumentPrev from '../../CommanComponents/DocumentPrev/DocumentPrev';
import { SwiperSlide } from 'swiper/react';
import Breadcrumb from '../../CommanComponents/Breadcrumb';
import BreadcrumbItem from '../../CommanComponents/Breadcrumb/BreadcrumbItem';
import getFileExtension from '../../HelperFunction/getFileExtension';
import { ALLOW_IMAGE_EXTENSIONS, getBaseUrl } from '../../config';
import FileIcon from '../../Assets/images/fileIcon.png'
import { fileDownloaderHelperFunc } from '../../HelperFunction/fileDownloaderHelperFunc';
import { FILE_ACCEPT, IMG_ACCEPT } from '../../Constant/Constants';
import LoadingData from '../../CommanComponents/LoadingData/LoadingData';
import DropdownSearch from '../../CommanComponents/DropdownSearch/DropdownSearch';
const Folders = ({ children }) => {
    const folders = useSelector((e) => e.folders) // FOLDERS SELECTOR   
    const dispatch = useDispatch()
    const ref = useRef(null)
    const ref2 = useRef(null)
    const folderPopPopRef = useRef();
    const uploadFileRef = useRef();
    const inputFile = useRef(null)
    const navigate = useNavigate()
    const userId = Auth?.user()?.user_id // this is for added by user
    const [activeOptionsDrop, setActiveOptionsDrop] = useDetectOutsideClick(ref, false)
    const [activeOptionsDrop2, setActiveOptionsDrop2] = useDetectOutsideClick(ref2, false)
    // const [searchVal, setSearchVal] = useState('')
    // const [image, setImage] = useState(null);

    const GridVal = Storage.alive(folder_grid_View) ? Storage.getString(folder_grid_View) : null
    const [gridView, setGridView] = useState(GridVal !== null ? GridVal : 'grid')
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [deleteType, setDeleteType] = useState(null);

    const {
        folderName, setFolderName, handleInput, saveBtnHandle,
        showError, deleteHandlerBtn, resetFolderName, renameHandlerBtn, editable,
        uploadType, setUploadType, setShowError, FolderDetailsHandler, onUploadFileChange,
        breadcrumb, folderId, setFolderId, searchVal, setSearchVal, filePrevHandler, prevRef, filePrevHandlerClose,
        prevData, deleteFileHanlder,

        folderSelectClientData, selectedClientDropdown, DropdownClientChanger
    } = useFolders(folderPopPopRef)


    useEffect(() => {
        Storage.set(folder_grid_View, gridView)
    }, [gridView])

    const handleDotsBtn = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop(prev => val === prev ? -1 : val)
    }
    const handleDotsBtn2 = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop2(prev => val === prev ? -1 : val)
    }
    const onClickHandle = () => {
        // navigate("/folder/data")
    }
    const openFolderPopup = () => {
        folderPopPopRef.current.open();
        setUploadType('create')
    }

    const closeFolderPopup = () => {
        folderPopPopRef.current.close();
    }

    const closeUploadFilePop = () => {
        uploadFileRef.current.close();
        // setImage(null);
    }
    // Debounced search function
    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(serachFolder(value, userId, folderId));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
        if (e.target.value == 0) {
            // setFolderId(null)
        }
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const handleResetSearch = () => {
        setSearchVal('')
        dispatch(searchReset(userId, folderId))
    }

    const handleDltBtn = (e, id, type) => {
        e.stopPropagation()
        setDeletePop(true);
        setDeleteId(id)
        setDeleteType(type)
    }

    const CancleDelete = () => {
        setDeletePop(false);
        setDeleteType(null)
    }

    const handlePopDeleteBtn = () => {
        setDeletePop(false);
        setDeleteType(null)
        if (deleteType == "folder") {
            deleteHandlerBtn(deleteId)
        } else if (deleteType == "file") {
            deleteFileHanlder(deleteId)
        }
        // deleteHandler(deleteId) 
    }
    // console.log(folders?.data?.folders, "asfefg")
    console.log(folders?.client?.access_level, Auth.boolUserType() == 2, "asfefg")
    return (
        <>
            <div className="folder-main-wrapper">
                <div className="folder-wrap">
                    <div className="heading-search-item justify-between align-center">
                        {/* <SectionHeading ClassName={"d-flex align-center justify-center"}>
                            <p onClick={() => navigate('/folders')} className='cursor'>Folders
                                {folders?.breadcrumb && "> "}</p>   <p className='ml-5'>{folders?.breadcrumb}
                            </p>
                        </SectionHeading> */}
                        <Breadcrumb >
                            <BreadcrumbItem title={"Folders"}
                                className={`section-heading primary text-sm w-500 
                            ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}
                            />
                            {folders?.data?.breadcrumbs?.map((val, key) => {
                                const disableLast = folders?.data?.breadcrumbs?.length - 1
                                return (
                                    <>
                                        <p
                                            className={`section-heading primary text-sm w-500 
                                          ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}

                                        >
                                            &nbsp;{`>`} &nbsp;</p>
                                        <BreadcrumbItem
                                            key={key}
                                            title={`${val?.name}`}
                                            className={`section-heading text-sm w-500   ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"} ${disableLast == key && "disabled"} `}
                                            to={`?folderId=${val?.id}&folderName=${val?.name}`}
                                            disabled={disableLast == key}
                                        />
                                    </>
                                )
                            })}
                        </Breadcrumb>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>
                        <div className="item-wrapper justify-between">
                            <form className=" actions-wrappper d-flex justify-between align-center " onSubmit={e => e.preventDefault()}>
                                {/* {folders?.data?.folders ?
                                    folders?.data?.folders[0]?.hasOwnProperty("level") == undefined
                                        || folders?.data?.folders[0]?.level <= 4
                                        ? */}
                                {(folders?.data?.breadcrumbs?.length < 4 || folders?.data?.breadcrumbs == undefined) && <CommanButton onClick={(e) => { return openFolderPopup() }} ClassName={"action-btn"} >
                                    <p className='w-700'> <span className='text-xs'>  + </span> Folder</p>
                                </CommanButton>}
                                {/* : "" : ''
                                } */}
                                {folderId !== null &&
                                    <CommanButton ClassName={"action-btn"} onClick={() => inputFile.current.click()} >
                                        <p className='w-700'> <span className='text-xs'>  + </span> Upload File</p>
                                        <input id="myInput"
                                            type="file"
                                            ref={inputFile}
                                            style={{ display: 'none' }}
                                            accept={`${IMG_ACCEPT},${FILE_ACCEPT}`}
                                            onChange={(e) => { return onUploadFileChange(e) }}
                                        />
                                    </CommanButton>

                                }
                            </form>
                            <div className="view-changer-wrapper">
                                <div className="view-change-wrap">
                                    <button className={`transparent  icons-wrap d-flex align-center justify-center  ${gridView === 'list' && "active"}`} title='List view'
                                        onClick={() => setGridView('list')}
                                    >
                                        <i className="icon list-view"></i>
                                    </button>
                                    <button className={`transparent  icons-wrap d-flex align-center justify-center ${gridView === "grid" && "active"} `} title='Grid view'
                                        onClick={() => setGridView('grid')}
                                    >
                                        <i className="icon grid-view"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        // folders?.data?.folders?.length ?
                        <div className="folder-item-wrapper">
                            {(Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) && !folders?.data?.files && <DropdownSearch
                                data={folderSelectClientData?.map((e) => e)}
                                defaultValue={selectedClientDropdown?.business_name ? selectedClientDropdown?.business_name : selectedClientDropdown?.first_name ? selectedClientDropdown?.first_name + " " + selectedClientDropdown?.last_name : selectedClientDropdown}
                                className={'select-client-dropper mb-10'}
                                clickHandle={(val) => DropdownClientChanger(val)}
                                ObjectKey={`business_name`}
                            />}
                            {gridView === "grid" ?
                                folders?.loading ? <LoadingData /> : folders?.data?.folders?.length || folders?.data?.files?.length ?
                                    <div className="grid-folder-item-wrap ">
                                        {folders?.data?.folders?.map((val, key) => {
                                            return (
                                                <div className="folder-item d-flex align-center relative" key={key} onClick={() => FolderDetailsHandler(val?.id, val?.name)} >
                                                    <i className="folder-icon"></i>
                                                    <p className="text-xxs w-400 secondary mr-15 folder-name" onClick={() => onClickHandle()} title={val.name}> {val.name}</p>
                                                    <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn(e, key)}>
                                                        <i className='dots-icon'> </i>
                                                    </div>
                                                    {activeOptionsDrop === key && <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref}>
                                                        {/* <p className="text-2xs w-500 secondary mb-10 pointer" onClick={(e) => renameHandlerBtn(e, val?.id)} >Rename</p>
                                                        <p className="text-2xs w-500 secondary pointer" onClick={(e) => handleDltBtn(e, val?.id, 'folder')} >Delete</p> */}
                                                        <div className={`item d-flex align-center ${folders?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? 'mb-10' : Auth?.boolUserType() !== 2 ? "mb-10" : ""} pointer`} onClick={() => FolderDetailsHandler(val?.id, val?.name)}  >
                                                            <i className='icons mr-5 sT-icon secondary i-xxs eye'></i>
                                                            <p className="text-2xs w-500 secondary" >View</p>
                                                        </div>
                                                        {
                                                            folders?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? <>
                                                                <div className="item d-flex align-center mb-10 pointer" onClick={(e) => renameHandlerBtn(e, val?.id)}  >
                                                                    <i className='icons mr-5 sT-icon secondary i-xxs edit-icon'></i>
                                                                    <p className="text-2xs w-500 secondary" >Rename</p>
                                                                </div>
                                                                <div className="item d-flex align-center pointer" onClick={(e) => handleDltBtn(e, val?.id, 'folder')}  >
                                                                    <i className='icons mr-5 sT-icon secondary i-xxs delete-icon'></i>
                                                                    <p className="text-2xs w-500 secondary" >Delete</p>
                                                                </div>
                                                            </> :

                                                                Auth?.boolUserType() !== 2 ? <>
                                                                    <div className="item d-flex align-center mb-10 pointer" onClick={(e) => renameHandlerBtn(e, val?.id)}  >
                                                                        <i className='icons mr-5 sT-icon secondary i-xxs edit-icon'></i>
                                                                        <p className="text-2xs w-500 secondary" >Rename</p>
                                                                    </div>
                                                                    <div className="item d-flex align-center pointer" onClick={(e) => handleDltBtn(e, val?.id, 'folder')}  >
                                                                        <i className='icons mr-5 sT-icon secondary i-xxs delete-icon'></i>
                                                                        <p className="text-2xs w-500 secondary" >Delete</p>
                                                                    </div>
                                                                </> : ""
                                                        }
                                                        {/* <p className="text-2xs w-500 secondary pointer">Access</p> */}
                                                    </div>}
                                                </div>
                                            )
                                        })
                                        }
                                        {folders?.data?.files?.map((val, key) => {
                                            return (
                                                <>
                                                    <div className="folder-item file-wrapper relative" key={key}   >
                                                        <div className="file-top-wrap d-flex mb-10 align-center">
                                                            <i className="file-icon"></i>
                                                            <p className="text-xxs w-400 secondary mr-15 folder-name" >
                                                                {/* {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]} */}
                                                                {val?.name}
                                                            </p>
                                                            <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn2(e, key)}>
                                                                <i className='dots-icon'> </i>
                                                            </div>
                                                        </div>
                                                        <div className="file-img-wrap" onDoubleClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}>
                                                            <ImageViewer
                                                                object={ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) && val.file}
                                                                defaultImage={FileIcon}
                                                                className={!ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) && 'doc-img'}
                                                            />
                                                        </div>
                                                        {activeOptionsDrop2 === key && <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref2}>
                                                            <p className={`text-2xs w-500 secondary ${folders?.client?.access_level == "Editor" && Auth?.boolUserType() == 2 ? 'mb-10' : Auth?.boolUserType() !== 2 ? "mb-10" : ""} pointer`}
                                                                // onClick={() => filePrevHandler(val)}
                                                                onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}
                                                            >
                                                                Preview
                                                            </p>
                                                            {
                                                                folders?.client?.access_level == "Editor" ?
                                                                    <>
                                                                        <p className="text-2xs w-500 secondary pointer mb-10" onClick={(e) => renameHandlerBtn(e, val?.id, "file")} >
                                                                            Rename
                                                                        </p>
                                                                        <p className="text-2xs w-500 secondary pointer" onClick={(e) => handleDltBtn(e, val?.id, 'file')} >
                                                                            Delete
                                                                        </p>
                                                                    </>
                                                                    : Auth?.boolUserType() !== 2 ?
                                                                        <>
                                                                            <p className="text-2xs w-500 secondary pointer mb-10" onClick={(e) => renameHandlerBtn(e, val?.id, "file")}>
                                                                                Rename
                                                                            </p>
                                                                            <p className="text-2xs w-500 secondary pointer" onClick={(e) => handleDltBtn(e, val?.id, 'file')} >
                                                                                Delete
                                                                            </p>
                                                                        </>
                                                                        : ""
                                                            }
                                                            {/* <p className="text-2xs w-500 secondary pointer">Access</p> */}
                                                        </div>}
                                                    </div>

                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                    :
                                    <div className='d-flex add-folder-btn'>
                                        <DataNotFound />
                                        {folderId === null && (folders?.data?.breadcrumbs?.length < 4 || folders?.data?.breadcrumbs == undefined) && <CommanButton onClick={(e) => { return openFolderPopup() }} ClassName={"action-btn"} >
                                            <p className='w-700'> <span className='text-xs'>  + </span> Folder</p>
                                        </CommanButton>}
                                    </div>
                                :
                                <FolderListView deleteHandlerBtn={deleteHandlerBtn} renameHandlerBtn={renameHandlerBtn}
                                    FolderDetailsHandler={FolderDetailsHandler} filePrevHandler={filePrevHandler}
                                    deleteFileHanlder={deleteFileHanlder} openFolderPopup={openFolderPopup} folderId={folderId}
                                />

                            }
                        </div>
                        // : <DataNotFound />
                    }
                </div>

            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handlePopDeleteBtn()}
            />}
            <FolderModal reference={folderPopPopRef} onClose={closeFolderPopup} uploadType={uploadType}
                folderName={folderName} handleInput={handleInput} saveBtnHandle={saveBtnHandle}
                showError={showError} setShowError={setShowError}
                resetFolderName={resetFolderName}
                data={folders}
            />
            <DocumentPrev reffrence={prevRef} onClose={filePrevHandlerClose} >
                <SwiperSlide>
                    <div className="prev-img-wrap">
                        <ImageViewer object={prevData?.file} alt="Image" />
                    </div>
                </SwiperSlide>
            </DocumentPrev>
        </>
    )
}

export default Folders