
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup";
import { ManageCompany_Actions } from "./actionType"
import manageClientReq from "./manageClientReq";
import Auth from "../../../Classes/Auth";
import Storage from "../../../Classes/Storage";
import { parentUserDetail } from "../../../Constant/auth";
const userId = Auth?.user()?.user_id // this is for added by user
const userId_user = Auth?.user()?.user_id_user
const getSubCompaniesList = (id) => {
    return async (dispatch) => {
        dispatch(
            { type: ManageCompany_Actions.SET_LOADING },
        )
        manageClientReq.get(`${manageClientReq.endpoint.get_sub_companies}`, (success) => {
            console.log(success, "ADfvsdghb")
            dispatch(
                { type: ManageCompany_Actions.SET_SUBCOMPANY_LIST, payload: success?.data?.data },
            )
        }, (error) => {

        }
        )
    }
}
const getUsersClientsList = (id, userId) => {
    console.log("ADfvsdghb 1")
    return async (dispatch) => {
        dispatch(
            { type: ManageCompany_Actions.SET_LOADING },
        )
        manageClientReq.get(`${manageClientReq.endpoint.typing_client_users}/${id}?user_id=${userId}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: ManageCompany_Actions.SET_SUBCOMPANY_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}

const addClient = (data) => {
    console.log("ADfvsdghb 2")
    return async (dispatch) => {
        manageClientReq.post(manageClientReq.endpoint.add_sub_company, data, (success) => {
            dispatch(
                { type: ManageCompany_Actions.CREATE_CLIENT_SUCCESS },
            )
            dispatch(showSuccessToast(success?.data?.message))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch(
                    { type: ManageCompany_Actions.CREATE_CLIENT_ERROR, payload: 'User with this email already exists. ' },
                )
            }
        }
        )
    }
}
const setCompanySwitch = (data) => {
    return async (dispatch) => {
        if (data?.email === Auth?.user().email) {
            return null
        } else if (data?.email !== Auth?.getParentUser().email) {
            Auth?.setParentUser()
            manageClientReq.post(manageClientReq.endpoint.company_login, data, (success) => {
                // dispatch(
                //     { type: ManageCompany_Actions.CREATE_CLIENT_SUCCESS },
                // )
                dispatch(showSuccessToast("Account change successfully!"))
                Auth.setUserLogin(success.data?.signup_data)
                Auth.updateUserDetail("token", success.data?.token?.access)
                window.location.href = "/"
            }, (error) => {
                if (error?.response?.status == 400) {
                    // dispatch(
                    //     { type: ManageCompany_Actions.CREATE_CLIENT_ERROR, payload: 'User with this email already exists. ' },
                    // )
                }
            }
            )
        } else if (data?.email === Auth?.getParentUser().email) {
            return (
                console.log("asdafsdgdfg"),
                Auth?.restoreParentUser(),
                Storage.remove(parentUserDetail),
                window.location.reload()
            )
        }
    }
}

const updateClient = (client_id, data, user_id) => {
    console.log("ADfvsdghb 3")
    return async (dispatch) => {
        manageClientReq.patch(`${manageClientReq.endpoint.update_sub_company}/${client_id}`, data, (success) => {
            dispatch(getSingleClient(user_id, client_id))
            dispatch(showSuccessToast('Client updated successfully!'))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch(
                    { type: ManageCompany_Actions.CREATE_CLIENT_ERROR, payload: 'User with this email already exists. ' },
                )
            }
        }
        )
    }
}




const getSingleClient = (user_id, client_id) => {
    console.log("ADfvsdghb 4")
    return async (dispatch) => {
        dispatch(
            { type: ManageCompany_Actions.SET_SINGLE_CLIENT_LOADING },
        )
        manageClientReq.get(`${manageClientReq.endpoint.get_sub_company}/${client_id}`, (success) => {
            dispatch(
                { type: ManageCompany_Actions.SET_SINGLE_CLIENT, payload: success?.data?.data },
            )
        }, (error) => {
        }
        )
    }
}
const setDeleteClient = (client_id, user_id) => {
    console.log("ADfvsdghb 5")
    return async (dispatch) => {
        manageClientReq.delete(`${manageClientReq.endpoint.delete_sub_company}/${client_id}`, (success) => {
            dispatch({ type: ManageCompany_Actions.SET_DELETE })
            if (Auth?.boolUserType() == 4) {
                dispatch(getUsersClientsList(userId_user, userId))
                dispatch(showSuccessToast('Deleted successfully!'))
            } else {
                dispatch(getSubCompaniesList(user_id))
                dispatch(showSuccessToast(success?.data?.message))
            }
        }, (error) => {
        }
        )
    }
}

const clientSearch = (userId, value) => {
    console.log("ADfvsdghb 6")
    return async (dispatch) => {
        manageClientReq.get(manageClientReq.endpoint.search_clients.replace('__TC_ID__', userId).replace('__VALUE__', value), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: ManageCompany_Actions.SET_SUBCOMPANY_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}
const getDropdownListDropdown = (userId, value) => {
    console.log("ADfvsdghb 7")
    return async (dispatch) => {
        manageClientReq.get(manageClientReq.endpoint.search_clients.replace('__TC_ID__', userId).replace('__VALUE__', value), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: ManageCompany_Actions.SET_SUBCOMPANY_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}

const resettSingleClient = () => {
    return {
        type: ManageCompany_Actions.RESET_SINGLE_CLIENT,
    }
}
const resetDeleteClient = () => {
    return {
        type: ManageCompany_Actions.RESET_DELETE,
    }
}
const resetAddClient = () => {
    return {
        type: ManageCompany_Actions.CREATE_CLIENT_RESET,
    }
}
const resetError = () => {
    return {
        type: ManageCompany_Actions.CREATE_CLIENT_ERROR_RESET,
    }
}

const setClientType = (type) => {
    return {
        type: ManageCompany_Actions.SET_CLIENT_TYPE,
        payload: type
    }
}


const setClientActiveTab = (payload) => {
    return ({
        type: ManageCompany_Actions.SET_CLIENT_ACTIVE_TAB,
        payload: payload
    })
}

const resetClientSearch = (id) => {
    return async (dispatch) => {
        if (Auth?.boolUserType() == 4) {
            dispatch(getUsersClientsList(userId_user, userId))
        } else {
            dispatch(getSubCompaniesList(id))
        }
        // dispatch(getSubCompaniesList(id));
    }
}

const setFetchType = (payload) => {
    return ({
        type: ManageCompany_Actions.SET_FETCH_TYPE,
        payload: payload
    })
}
const resetClientList = (payload) => {
    return ({
        type: ManageCompany_Actions.RESET_CLIENTS_LIST
    })
}
export {
    getSubCompaniesList, setClientType, setClientActiveTab,
    getSingleClient, resettSingleClient, addClient, resetAddClient, updateClient,
    setDeleteClient, resetDeleteClient, clientSearch, resetClientSearch, resetError, setFetchType,
    getUsersClientsList, resetClientList,
    getDropdownListDropdown, setCompanySwitch
};