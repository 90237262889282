import Request from "../../../Classes/Requests";

class authRequest extends Request {
    constructor() {
        super()
        this.endpoint = {
            employee_list: super.url("/employee_list?client_id=__CLIENT_ID__&order_by=__ORDER_BY__"),
            employee_search: super.url("/employee_search?added_by_user_id=__USER_ID__&query=__VALUE__"),
            employee_bulk_upload: super.url("/upload/"),
            send_employee_credentials: super.url("/send_employee_credentials"),
            sendnotificationemployee: super.url("/sendnotificationemployee"),

            //  /employee_search?added_by_user_id=305&query=guddu

        }
    }
}
export default new authRequest();
