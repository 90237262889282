import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReminderList, setRemider2 } from '../../store/Actions/Reminders'
import { useParams } from 'react-router-dom'
import { useTodayDate } from '../useTodayDate'
import { setDynamicReminder } from '../../store/Actions/Settings'

const useReminderSettings = () => {
    const settings = useSelector(e => e.settings)
    const reminders = settings?.reminders
    const dispatch = useDispatch()
    const [data, setData] = useState({
        "Reminder_1": settings?.personal?.data?.complete_profile?.Reminder_1 ? settings?.personal?.data?.complete_profile?.Reminder_1 : 0,
        "Reminder_2": settings?.personal?.data?.complete_profile?.Reminder_2 ? settings?.personal?.data?.complete_profile?.Reminder_2 : 0,
        "Reminder_3": settings?.personal?.data?.complete_profile?.Reminder_3 ? settings?.personal?.data?.complete_profile?.Reminder_3 : 0,
    })


    const handleInputChange = (e, docType) => {
        let inputValue = e.target.value;
        let inputName = e.target.name;
        if (inputValue <= 1) {
            inputValue = 1;  // Clear invalid input or set to 1 if you prefer
        }
        const newData = {
            ...data,
            [inputName]: parseInt(inputValue),
        }
        setData(newData)
    }

    const saveBtnHandler = (e) => {
        e.preventDefault()
        dispatch(setDynamicReminder(data))
    }

    return { data, reminders, handleInputChange, saveBtnHandler }
}

export default useReminderSettings