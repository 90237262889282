import React, { useEffect, useRef, useState } from 'react'
import ValidationFile from '../../Classes/ValidationFile';
import { getSupportList, resetCreateTicket, resetViewTicket, setCreateTicket, updateTicket, viewTicket } from '../../store/Actions/Support';
import { useDispatch, useSelector } from 'react-redux'
import Auth from '../../Classes/Auth';
import { getBaseUrl } from '../../config';
import { compressFile } from '../../HelperFunction/FileCompressor';
const useSupport = () => {
    const support = useSelector(e => e.support)
    const inputFileRef = useRef(null)
    const createTicketPopRef = useRef();
    const userId = Auth?.user()?.user_id // this is for added by user
    const dispatch = useDispatch()
    const [image, setImage] = useState(null);
    const [showError, setShowError] = useState(false)
    const [actionType, setActionType] = useState('create')
    const [updateId, setUpdateId] = useState('create')
    const [ticketData, setTicketData] = useState({
        error_name: {
            value: "",
            isValid: false,
        },
        attachment: {
            value: "",
            isValid: false,
        },
        description: {
            value: "",
            isValid: false,
        },
        comment: {
            value: "",
            isValid: true,
        },
        status: {
            value: "Select",
            isValid: true,
        },
        validation: false,
    })
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "error_name":
                return ValidationFile.validEmpty(inputValue);

            case "description":
                return ValidationFile.validEmpty(inputValue);

            default:
                return false;
        }
    };


    const isFormValid = () => {
        return ticketData.error_name.isValid &&
            ticketData.attachment.isValid &&
            ticketData.description.isValid ? true : false;
    };

    const handleInputChange = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName === 'attachment') {
            const file = e.target.files[0];
            const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
            if (file) {
                // if (file?.size <= maxSize) {
                if (true) {
                    setTicketData(prevTicketData => ({
                        ...prevTicketData,
                        [inputName]: {
                            value: file,
                            isValid: file ? true : false,
                        },
                        validation: isFormValid(),
                    }));
                } else {
                    alert('File size exceeds the limit of 2 MB');
                    e.target.value = null;
                }
            }
        } else {
            let data = {
                ...ticketData,
                [inputName]: {
                    value: inputValue,
                    isValid: validationConfirm(inputValue, inputName),
                },
                validation: isFormValid(),
            };
            setTicketData(data);
        }
        console.log(ticketData, "ticketData");
    };

    const CreateTicketBtnHandle = (e) => {
        e.preventDefault()
        if (isFormValid()) {
            const formData = new FormData();
            const data = {
                error_name: ticketData.error_name.value,
                // attachment: ticketData.attachment.value,
                description: ticketData.description.value,
                added_by_user_id: userId,
                comment: ticketData.comment.value,
                status: ticketData.status.value,
            }
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });


            compressFile(ticketData.attachment.value)
                .then((compressedFile) => {
                    formData.append("attachment", compressedFile, ticketData.attachment.value.name); // Append the compressed file 
                    if (actionType == 'update') {
                        dispatch(updateTicket(formData, updateId))
                        closeTicketPopup()
                    } else {
                        dispatch(setCreateTicket(formData, userId))

                    }
                })
                .catch((err) => {
                    console.error("File compression error:", err);
                    // Handle the error appropriately, maybe show an error message to the user
                });






        } else {
            setShowError(true)
        }
    }

    const handleStatus = (val) => {
        setTicketData((prev) => ({ ...prev, status: { ...prev?.status, value: val } }))
    }


    const openTicketPopup = () => {
        createTicketPopRef.current.open();
    }

    const closeTicketPopup = () => {
        createTicketPopRef.current.close();
        setActionType("create")
        setShowError(false)
        dispatch(resetViewTicket())
        setTicketData({
            error_name: {
                value: "",
                isValid: false,
            },
            attachment: {
                value: "",
                isValid: false,
            },
            description: {
                value: "",
                isValid: false,
            },
            comment: {
                value: "",
                isValid: false,
            },
            status: {
                value: "Select",
                isValid: true,
            },
            validation: false,
        })
    }

    const handleEditButton = (id) => {
        openTicketPopup()
        dispatch(viewTicket(id))
        setActionType("update")
        setUpdateId(id)
    }

    useEffect(() => {
        dispatch(getSupportList(userId))
    }, [])

    // useEffect(() => {
    //     if (support?.success) {
    //         closeTicketPopup()
    //     }
    //     const baseUrl = getBaseUrl()
    //     if (support?.update?.success) {
    //         setTicketData({
    //             error_name: {
    //                 value: support?.update?.data?.error_name,
    //                 isValid: false,
    //             },
    //             attachment: {
    //                 value: baseUrl + support?.update?.data?.attachment,
    //                 isValid: false,
    //             },
    //             description: {
    //                 value: support?.update?.data?.description,
    //                 isValid: false,
    //             },
    //             validation: false,
    //         })
    //     }
    // }, [support])


    useEffect(() => {
        if (support?.success) {
            closeTicketPopup();
            dispatch(resetCreateTicket())
        }
        if (support?.update?.success) {
            dispatch(resetViewTicket())
            const baseUrl = getBaseUrl();
            const attachmentUrl = support?.update?.data?.attachment;
            const fileName = attachmentUrl.split('/').pop();
            const fileExtension = fileName.split('.').pop(); // Get the file extension

            fetch(baseUrl + attachmentUrl)
                .then(response => response.blob())
                .then(blob => {
                    const file = new File([blob], fileName + fileExtension, { type: blob.type });
                    setTicketData({
                        error_name: {
                            value: support?.update?.data?.error_name,
                            isValid: true,
                        },
                        attachment: {
                            value: file,
                            isValid: true,
                        },
                        description: {
                            value: support?.update?.data?.description,
                            isValid: true,
                        },
                        comment: {
                            value: support?.update?.data?.comment,
                            isValid: true,
                        },
                        status: {
                            value: support?.update?.data?.status,
                            isValid: true,
                        },
                        validation: true,
                    });
                    setImage(URL.createObjectURL(file)); // Create a URL for the file to display
                });
        }
    }, [support]);

    useEffect(() => {
        if (inputFileRef.current && ticketData.attachment.value) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(ticketData.attachment.value);
            inputFileRef.current.files = dataTransfer.files;
        }
    }, [ticketData.attachment]);


    console.log(ticketData?.attachment, "support?.update?.data?.attachment")
    return {
        ticketData, setTicketData, handleInputChange, CreateTicketBtnHandle,
        showError, inputFileRef, openTicketPopup, createTicketPopRef,
        closeTicketPopup, handleEditButton, actionType, handleStatus
    }
}

export default useSupport