import React, { useState } from 'react'
import Modals from '../../../../CommanComponents/Modals'
import ModalsHeader from '../../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../../CommanComponents/Modals/ModalsBody'
import CommanButton from '../../../../CommanComponents/CommanButton/CommanButton';
import { useDispatch } from 'react-redux';
import { dateFormat } from '../../../../CustomHooks/dateFormat';
import { useTodayDate } from '../../../../CustomHooks/useTodayDate';
import './expiryFilter.scss'

const ExpiryFilter = ({ date1, setDate1, date2, setDate2, reference, onClose, onSubmitFunc, resetRangeFilter }) => {
    const todayDate = useTodayDate()
    const dispatch = useDispatch()
    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            Position="center"
            ModalsSize={'modal-s'}
            ClassName={'ReminderPopup-upload-wrapper'}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>Date Range Filter</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={'ReminderPopup-modal-body'}>
                <div className="ReminderPopup-body-wrapper">
                    <div className="reminder-wrapper d-flex">
                        <div className="reminder-item">
                            <p className="text-xxs w-600 secondary description-para"> Set Date 1</p>
                            <button className='transparent set-reminder-btn mt-5'>
                                <div className={`dob-wrap d-flex align-center details-InputClassName`}>
                                    <p className={`dob_para d-flex align-center secondary ${date1 ? 'active' : ""}`}>{date1 ? dateFormat(date1) : date1 ? dateFormat(date1) : "DD-MM-YYYY"}</p>
                                    <input
                                        className={'details-InputClassName'}
                                        type="date"
                                        min={todayDate}
                                        value={date1}
                                        onChange={(e) => { return setDate1(e.target.value) }}
                                        onKeyUp={(e) => { return setDate1(e.target.value) }}
                                    />
                                </div>
                            </button>
                        </div>
                        <div className="reminder-item">
                            <p className="text-xxs w-600 secondary description-para"> Set Date 2 </p>
                            <button className='transparent set-reminder-btn mt-5'>

                                <div className={`dob-wrap d-flex align-center details-InputClassName`}>
                                    <p className={`dob_para d-flex align-center secondary ${date2 ? 'active' : ""}`}>{date2 ? dateFormat(date2) : date2 ? dateFormat(date2) : "DD-MM-YYYY"}</p>
                                    <input
                                        className={'details-InputClassName'}
                                        type="date"
                                        min={todayDate}
                                        value={date2}
                                        onChange={(e) => setDate2(e.target.value)}
                                        onKeyUp={(e) => setDate2(e.target.value)}
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="save-btn-wrap d-flex ">
                    <CommanButton
                        Text={'Search'}
                        ClassName={'save-btn'}
                        onClick={onSubmitFunc}
                    />
                    <CommanButton
                        Text={'Reset'}
                        cancel={true} type={'button'}
                        onClick={() => resetRangeFilter()}
                    />
                </div>
            </ModalBody >

        </Modals>
    )
}

export default ExpiryFilter