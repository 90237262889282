
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup";
import AccessManagementReq from "./AccessManagementReq";
import { AccessManagement_Actions } from "./actionType"
import Auth from "../../../Classes/Auth";
const UserType = Auth?.boolUserType()
const userIds = Auth?.user()?.user_id // this is for added by user 
const setSendAccessBoToTc = (data) => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_LOADING })
        AccessManagementReq.post(AccessManagementReq.endpoint.grant_access, data, (success) => {
            dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_SUCCESS })
            dispatch(showSuccessToast('Access request sent successfully!'))
            dispatch(getAccessUsers(data?.business_owner_id))
        }, (error) => {
            console.log(error, "Axcsavc")
            if (error?.response?.status == 409) {
                dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_ERROR, payload: error.response.data.message })
                // dispatch(showErrorToast(error.response.data.message ))
            } else if (error?.response?.status == 400) {
                dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_ERROR, payload: "Typing center with this email not found!" })
                // dispatch(showErrorToast("Typing center with this email not found!"))
            }
        }
        )
    }
}
const checkTcExist = (data) => {
    return async (dispatch) => {
        AccessManagementReq.post(AccessManagementReq.endpoint.check_typing_center_exists, data, (success) => {
            // dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_SUCCESS })

        }, (error) => {
            dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_ERROR, payload: error.response.data.message })
        }
        )
    }
}





const setSendAccessBoToTcUpdate = (data) => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_LOADING })
        AccessManagementReq.put(AccessManagementReq.endpoint.update_access, data, (success) => {
            dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_SUCCESS })
            dispatch(showSuccessToast('Access request sent successfully!'))
            dispatch(getAccessUsers(data?.business_owner_id))
        }, (error) => {
            dispatch({ type: AccessManagement_Actions.SET_GRANT_ACCESS_ERROR })
        }
        )
    }
}



const getAccessUsers = (userId) => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.SET_LIST_ACCESS_USERS_LOADING })

        AccessManagementReq.get(UserType == 1 || UserType == 3 ? (AccessManagementReq.endpoint.list_access_users.replace("__USER_ID__", userId)) : (AccessManagementReq.endpoint.get_typing_centre_invites_and_access_list.replace("__USER_ID__", userId)), (success) => {
            dispatch({ type: AccessManagement_Actions.SET_LIST_ACCESS_USERS_SUCCESS, payload: success?.data })
        }, (error) => {
        }
        )
    }
}
const deleteAccess = (userId, typing_center_id) => {
    return async (dispatch) => {
        AccessManagementReq.delete(AccessManagementReq.endpoint.delete_access.replace("__USER_ID__", userId).replace("__TC_ID__", typing_center_id), (success) => {
            dispatch(showSuccessToast("Deleted successfully"))
            dispatch(getAccessUsers(userId))
        }, (error) => {
        }
        )
    }
}
const revokeAccess = (id) => {
    return async (dispatch) => {
        AccessManagementReq.post(AccessManagementReq.endpoint.revoke_invitation.replace("__ID__", id), '', (success) => {
            dispatch(showSuccessToast("Revoked successfully"))
            dispatch(getAccessUsers(userIds))
        }, (error) => {
        }
        )
    }
}

const actionHandlerTypingCenter = (id, data) => {
    return async (dispatch) => {
        AccessManagementReq.post(AccessManagementReq.endpoint.respond_to_invitation.replace("__ID__", id), data, (success) => {
            dispatch(showSuccessToast(success?.data?.message))
            dispatch(getAccessUsers(userIds))
        }, (error) => {
        }
        )
    }
}
const sendInviteToTypingCenter = (data) => {
    return async (dispatch) => {
        AccessManagementReq.post(AccessManagementReq.endpoint.send_invitaion_to_typing_center, data, (success) => {
            dispatch(showSuccessToast(success?.data?.message))
            dispatch(resetGrantBoError())
            // dispatch(getAccessUsers(userIds))
        }, (error) => {
        }
        )
    }
}


const getViewAccessDeatils = (email) => {
    return async (dispatch) => {
        AccessManagementReq.get(`${AccessManagementReq.endpoint.view_access_content.replace("__EMAIL_ID__", email)}`, (success) => {
            const data22 = success?.data?.employees?.map(emp => ({
                ...emp,
                emp_id: emp.emp_id
            }));
            dispatch({ type: AccessManagement_Actions?.GET_ACCESS_DETAILS_SUCCESS, payload: success?.data })
        }, (error) => {

        }
        )
    }
}


const updateAccessBO_TC = (data) => {
    return async (dispatch) => {
        AccessManagementReq.put(AccessManagementReq.endpoint.update_content_access, data, (success) => {
            dispatch({ type: AccessManagement_Actions.UPDATE_ACCESS_DETAILS_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
        }, (error) => {
        }
        )
    }
}

const getFolderAndFilesAccessData = (user_id) => {
    return async (dispatch) => {
        AccessManagementReq.get(`${AccessManagementReq.endpoint.get_folders_files_for_access.replace("__USER_ID__", user_id)}`, (success) => {
            dispatch({ type: AccessManagement_Actions?.GET_FOLDERS_AND_FILES_ACCESS_DATA, payload: success?.data })
        }, (error) => {

        }
        )
    }
}
const getCompanyFolderAndFilesAccessData = (user_id) => {
    return async (dispatch) => {
        AccessManagementReq.get(`${AccessManagementReq.endpoint.get_company_folders_files_for_access.replace("__USER_ID__", user_id)}`, (success) => {
            dispatch({ type: AccessManagement_Actions?.GET_DOCUMENTS_FOLDERS_AND_FILES_ACCESS_DATA, payload: success?.data })
        }, (error) => {

        }
        )
    }
}



const resetGrantBoError = (id) => {
    return { type: AccessManagement_Actions.RESET_GRANT_ACCESS_ERROR }
}

const resetSendAccessBoToTc = (id) => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.RESET_GRANT_ACCESS_SUCCESS })
    }
}
const resetViewAccessDeatils = () => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.RESET_ACCESS_DETAILS_SUCCESS })
    }
}
const resetUpdateAccessBO_TC = () => {
    return async (dispatch) => {
        dispatch({ type: AccessManagement_Actions.UPDATE_ACCESS_DETAILS_RESET })
    }
}

export {
    setSendAccessBoToTc, resetSendAccessBoToTc, resetGrantBoError,
    getAccessUsers, setSendAccessBoToTcUpdate, deleteAccess, actionHandlerTypingCenter, revokeAccess,
    getViewAccessDeatils, resetViewAccessDeatils, updateAccessBO_TC, resetUpdateAccessBO_TC,
    getFolderAndFilesAccessData, getCompanyFolderAndFilesAccessData, sendInviteToTypingCenter, checkTcExist
};