import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { docSearch, getAllPassportDocs, getDocumentCount, resetDocSearch, resetGetDocs } from '../../store/Actions/Documents'
import ValidationFile from '../../Classes/ValidationFile'
import Auth from '../../Classes/Auth'

const useDocuments = () => {
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const documents = useSelector(e => e.documents)
    const userId = Auth?.user()?.user_id // this is for added by user 
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { document_param } = useParams()
    const data = useParams()
    const [breadcrumb, setBreadcrumb] = useState('')
    const [searchVal, setSearchVal] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const selectedClient = employeeManagement?.client;

    const dateRangePopup = useRef()
    const [date1, setDate1] = useState(null)
    const [date2, setDate2] = useState(null)
    // const valid = new ValidationFile()
    const DocumentDetailsHandler = (val) => {
        setBreadcrumb(val)
        const url = ValidationFile.validUrlParam(val)
        setSearchVal('')
        navigate(`/documents/${url}`)
    }

    const openReminderPop = () => {
        dateRangePopup.current.open();
    }

    const closeReminderPop = () => {
        dateRangePopup.current.close();
    }

    const setDateRangeFilter = (e) => {
        e.preventDefault()
        closeReminderPop()
        if (document_param !== 'all' && document_param !== 'visa'
            && document_param !== 'medical_insurance' &&
            document_param !== 'driving_license' && document_param !== 'family_documents' &&
            document_param !== 'others'
        ) {
            dispatch(getAllPassportDocs(ValidationFile.validUrlParam(document_param), userId, documents?.order_by, date1, date2));
        }

        if (document_param == 'all') {
            dispatch(resetGetDocs())
            setSearchVal('')
            setBreadcrumb('')
            setPageNumber(1)
        }
        dispatch(getDocumentCount(userId, selectedClient?.id))
    }


    const resetRangeFilter = () => {
        closeReminderPop()
        setDate1(null)
        setDate2(null)
        if (document_param !== 'all' && document_param !== 'visa'
            && document_param !== 'medical_insurance' &&
            document_param !== 'driving_license' && document_param !== 'family_documents' &&
            document_param !== 'others'
        ) {
            dispatch(getAllPassportDocs(ValidationFile.validUrlParam(document_param), userId, documents?.order_by, null, null));
        }

        if (document_param == 'all') {
            dispatch(resetGetDocs())
            setSearchVal('')
            setBreadcrumb('')
            setPageNumber(1)
        }
        dispatch(getDocumentCount(userId, selectedClient?.id))
    }


    useEffect(() => {
        if (document_param !== 'all' && document_param !== 'visa'
            && document_param !== 'medical_insurance' &&
            document_param !== 'driving_license' && document_param !== 'family_documents' &&
            document_param !== 'others'
        ) {
            dispatch(getAllPassportDocs(ValidationFile.validUrlParam(document_param), userId, documents?.order_by, date1, date2));
        }

        if (document_param == 'all') {
            dispatch(resetGetDocs())
            setSearchVal('')
            setBreadcrumb('')
            setPageNumber(1)
        }
        dispatch(getDocumentCount(userId, selectedClient?.id))
    }, [document_param, documents?.order_by,])


    // Seraching Start here 
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleSearch = (event) => {
        setSearchVal(event.target.value);
        // Call your desired function here, debounced
        debouncedSearch(event.target.value);
    };

    const debouncedSearch = debounce((value) => {
        // Your search function or any other function to be debounced 
        if (value.length === 0) {
            if (data?.document_param !== 'all') {
                dispatch(resetDocSearch(data?.document_param, userId, documents?.order_by, date1, date2));
            }
        } else {
            // if (data?.document_param !== 'all') {
            dispatch(docSearch(userId, value));
            // }
        }
    }, 300); // Adjust delay as needed

    const handleResetSearch = () => {
        setSearchVal('')
        dispatch(resetDocSearch(data?.document_param, userId, documents?.order_by, date1, date2))
    }
    // Ends of search ---------------- 


    return {
        document_param, DocumentDetailsHandler, searchVal, data,
        handleSearch, handleResetSearch, pageNumber, setPageNumber, breadcrumb,
        dateRangePopup,
        date1,
        setDate1,
        date2,
        setDate2,
        openReminderPop,
        closeReminderPop,
        setDateRangeFilter,
        resetRangeFilter
    }
}

export default useDocuments