import Request from "../../../Classes/Requests";

class settingsReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            get_user_profile: super.url("/get_user_profile?added_by_user_id=__USER_ID__"),
            update_user_profile: super.url("/update_user_profile?added_by_user_id=__USER_ID__"),
            set_reminder_profile: super.url("/set_reminder_profile"),
        }
    }
}
export default new settingsReq();
