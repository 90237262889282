import recycleBinReq from './recycleBinReq'
import RecycleBinActions from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
import axios from 'axios'
import Api from '../../../Classes/Api'
const api = new Api()
const getRecycleList = (id) => {
    return async (dispatch) => {
        dispatch({ type: RecycleBinActions.SET_RECYCLE_LOADING })
        recycleBinReq.get(`${recycleBinReq.endpoint.get_deleted_folders.replace("__USER_ID__", id)}`, (success) => {
            dispatch({ type: RecycleBinActions.SET_RECYCLE_LIST_SUCCESS, payload: success?.data })
        },
            (error) => {

            }
        )
    }
}

const emptyTrash = (id) => {
    return async (dispatch) => {
        recycleBinReq.delete(`${recycleBinReq.endpoint.delete_all.replace("__USER_ID__", id)}`, (success) => {
            // dispatch({ type: PersonalDetailsAction.DELETE_EMPLOYEE_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
            dispatch(getRecycleList(id))
        }
        )
    }
}

const restoreSingleData = (data, id) => {
    return async (dispatch) => {
        recycleBinReq.put(`${recycleBinReq.endpoint.restore_single}`, data, (success) => {
            dispatch(showSuccessToast("Restore successfully!"))
            dispatch(getRecycleList(id))
        }
        )
    }
}
const deleteSingle = (data, id) => {
    return async (dispatch) => {
        axios.delete(recycleBinReq.endpoint.delete_single, { data })
            .then(function (success) {
                dispatch(showSuccessToast(success?.data?.message))
                dispatch(getRecycleList(id))
            })
            .catch(function (error) {

            });
        // recycleBinReq.delete(`${recycleBinReq.endpoint.delete_single}`, (success) => {
        //     dispatch(showSuccessToast(success?.data?.message))
        //     dispatch(getRecycleList(id))
        // }
        // )
    }
}


const resetReminderList = () => {
    // return { type: SettingsAction.REMINDER_LIST_RESET }
}

export { getRecycleList, emptyTrash, restoreSingleData, deleteSingle }