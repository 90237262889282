import React, { useCallback, useEffect, useRef, useState } from 'react'
import './employeesManagement.scss'
import data from './data.json'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import Dashboard from '../DashBoard'
import dots from '../../Assets/icons/dots-vertical.svg'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import { useNavigate } from 'react-router-dom'
import TopActionButtons from '../DashBoard/RightBarPannel/TopActionButtons/TopActionButtons'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
// import  useEmployeePersonalDetails  from '../../CustomHooks/EmployeePersonalDetails/useEmployeePersonalDetails'
import { useEmployeesManagement } from '../../CustomHooks/EmployeesManagement/useEmployeesManagement'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEmployee, resetDelete, setActiveTab } from '../../store/Actions/PersonalDetails'
import { employeeSearch, getEmployeeList, resetEmployeeSearch, setClient, setEmployeeType, setOrderBy } from '../../store/Actions/EmployeeManagement'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import Auth from '../../Classes/Auth'
import SelectClientPop from './SelectClientPop/SelectClientPop'
import DropdownSearch from '../../CommanComponents/DropdownSearch/DropdownSearch'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import usePageListData from '../../CustomHooks/usePageListData'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import Dropdown from '../../CommanComponents/Dropdown/Dropdown'
import BulkEmployeePop from './BulkEmployeePop/BulkEmployeePop'
import LoadingData from '../../CommanComponents/LoadingData/LoadingData'
const EmployeesManagement = () => {
    const [employeeManagement, personalDetails, forceUpdate, setForceUpdate,
        buldUploadRef, openEmployeeBulkPop, closeEmployeeBulkPop, handleBulkInput, inputBulkRef,
        bulkFile, handleBulkUpload, setBulkFile, crossBtnHandler, sendCredentials, updateDocument] = useEmployeesManagement()
    const ref = useRef(null)
    const selectclientRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchVal, setSearchVal] = useState('')
    const [activeManage, setActiveManage] = useDetectOutsideClick(ref, false)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const userId = Auth?.user()?.user_id // this is for added by user
    const clients = useSelector(e => e.clients)
    const selectedClient = employeeManagement?.client;
    const [pageNumber, setPageNumber] = useState(1)

    // Debounced search function
    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(employeeSearch(userId, value));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    // const handleSearch = (e) => { 
    //     dispatch(employeeSearch(e.target.value))
    // }
    const handleResetSearch = () => {
        setSearchVal('')
        dispatch(resetEmployeeSearch(selectedClient?.id))
    }
    const handleManageBtn = (e, val) => {
        e.stopPropagation()
        setActiveManage(activeManage === val ? -1 : val)
    }

    const EmployeeClickHandle = (val) => {
        if (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) {
            navigate(`id=${val}?client_id=${employeeManagement?.client?.id}`)
        } else {
            navigate(`id=${val}`)
        }
        dispatch(setEmployeeType('old'))
        dispatch(setActiveTab("Documents"))
    }

    // DELETE EMPLOYEE HERE
    const deleteHandler = useCallback((id) => {
        dispatch(deleteEmployee(id))
    }, [personalDetails?.delete])

    useEffect(() => {
        if (personalDetails?.delete) {
            dispatch(resetDelete())
        }
        getEmployeeList(selectedClient?.id, employeeManagement?.order_by)

    }, [personalDetails?.delete])


    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteHandler(deleteId)
        dispatch(resetDelete())
        setForceUpdate(prevState => !prevState);
    }
    const addToDocumentHandler = (val) => {
        dispatch(setEmployeeType('old'))
        navigate(`id=${val}`)
        dispatch(setActiveTab("Documents"))
    }

    const openSelectClientPop = () => {
        selectclientRef.current.open();
    }

    const closeSelectClientPop = () => {
        selectclientRef.current.close();
    }

    const addEmplyeeBtn = () => {
        if (Auth.boolUserType() == 2 || Auth.boolUserType() == 4) {
            openSelectClientPop()
        } else {
            navigate('add-new-employee')
            dispatch(setEmployeeType('new'))
            dispatch(setActiveTab("Documents"))
        }
    }

    const handleEmployeeDropdown = (val) => {
        if (val.toLowerCase().includes('bulk'.toLowerCase())) {
            openEmployeeBulkPop()
        } else {
            addEmplyeeBtn()
        }
    }

    const DropdownClientChanger = (val) => {
        dispatch(setClient(val))
        dispatch(getEmployeeList(val?.id, employeeManagement?.order_by))
    }

    const dataForCurrentPage = usePageListData(employeeManagement?.data, pageNumber)



    // Create a new array 'data2' by mapping over the original 'data' array
    const data2 = clients.data.map(item => {
        // Check if 'business_name' is an empty string and both 'first_name' and 'last_name' are not empty
        if (item.business_name === "" && item.first_name && item.last_name) {
            // Return a new object with all properties of the original item, but with 'business_name' set to 'first_name' + 'last_name'
            return {
                ...item,
                business_name: item.first_name + " " + item.last_name
            };
        }
        // If the condition is not met, return the item as is
        return item;
    });

    console.log(selectedClient, "SDfdsgsdfv")
    return (
        < >
            <div className="employees-management-wrapper">
                <div className="employees-management-wrapper">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading>
                            Employees List
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search for name, email, mobile, country'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                            {/* ------- Pagination add ------ */}
                        </div>

                        <div className="actions-wrappper plus-emp-wrapper relative">
                            {/* <CommanButton
                                onClick={plusEmployeeHandler}
                                ClassName={"action-btn"} >
                                <p className='w-700'><span className='text-xs'>  + </span>Employee</p>
                            </CommanButton>
                            <div className="drop-wrapper absolute">
                                <ul>
                                    <li className='text-xxs w-500 mb-10 secondary'>Add employee</li>
                                    <li className='text-xxs w-500 secondary'>Bulk upload</li>
                                </ul>
                            </div> */}
                            {/* <div className={`select-emp-wrap`}> */}
                            <Dropdown
                                // key={employeeData?.gender?.value}
                                ClassName={'emp-dropdown'}
                                padding={'12.5px 16px'}
                                borderRadius={'8px'}
                                textAlign={'left'}
                                data={['+ Single add', '+ Bulk Upload']}
                                defaultValue={'+ Add Employee'}
                                clickHandle={(val) => handleEmployeeDropdown(val)}
                            />
                            {/* </div> */}
                        </div>
                        <PaginationSimple
                            className={"document-pagenation"}
                            data={employeeManagement?.data}
                            shows={employeeManagement?.data?.length < 20 ? employeeManagement?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </div>
                    <div className="employees-management-table-wrapper mt-10">
                        {(Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) && <DropdownSearch
                            data={data2?.map((e) => e)}
                            defaultValue={selectedClient?.business_name ? selectedClient?.business_name : selectedClient?.first_name ? selectedClient?.first_name + " " + selectedClient?.last_name : selectedClient}
                            className={'select-client-dropper'}
                            clickHandle={(val) => DropdownClientChanger(val)}
                            ObjectKey={`business_name`}
                        />}
                        {

                            ((Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) && selectedClient?.business_name === "Select client*") ? "" : employeeManagement?.loading ? <LoadingData />
                                : dataForCurrentPage?.length ?
                                    <div className="table-wrap mt-20">
                                        <table className="table-item">
                                            <thead className='table-head'>
                                                <tr className="table-row">
                                                    <th className='srNo '>
                                                        <div className="d-flex">
                                                            ID
                                                            <div className="filter-wrapper ml-10">
                                                                <button className={`d-block transparent filter-button `} title='Ascending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon asc-icon'></i>
                                                                </button>
                                                                <button className={`d-block transparent filter-button `} title='Descending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon dsc-icon'></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th className='name d-flex'>
                                                        Employee Name
                                                        <div className="filter-wrapper ml-10">
                                                            <button className={`d-block transparent filter-button ${employeeManagement?.order_by === "asc" ? "active" : ""}`} title='Ascending' onClick={() => { dispatch(setOrderBy("asc")) }}>
                                                                <i className='icons sT-icon secondary i-xxxs arrow-icon asc-icon'></i>
                                                            </button>
                                                            <button className={`d-block transparent filter-button ${employeeManagement?.order_by === "desc" ? "active" : ""}`} title='Descending' onClick={() => { dispatch(setOrderBy("desc")) }}>
                                                                <i className='icons sT-icon secondary i-xxxs arrow-icon dsc-icon'></i>
                                                            </button>
                                                        </div>
                                                    </th>
                                                    <th className='email'>
                                                        <div className="d-flex">
                                                            Email Address
                                                            <div className="filter-wrapper ml-10">
                                                                <button className={`d-block transparent filter-button `} title='Ascending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon asc-icon'></i>
                                                                </button>
                                                                <button className={`d-block transparent filter-button `} title='Descending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon dsc-icon'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='phone'>Phone Number</th>
                                                    <th className='country'>
                                                        <div className="d-flex">
                                                            Country
                                                            <div className="filter-wrapper ml-10">
                                                                <button className={`d-block transparent filter-button `} title='Ascending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon asc-icon'></i>
                                                                </button>
                                                                <button className={`d-block transparent filter-button `} title='Descending'>
                                                                    <i className='icons sT-icon secondary i-xxxs arrow-icon dsc-icon'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='action'>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody className='table-body'>
                                                {employeeManagement?.data?.map((val, key) => {
                                                    return (
                                                        <tr className="table-row" key={key} onClick={() => EmployeeClickHandle(val.employee_id)}>
                                                            <td  > {(Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) ? selectedClient?.business_name?.replace(/\s/g, '').substring(0, 4) : (Auth?.boolUserType() == 1) ? Auth.user()?.business_name.replace(/\s/g, '').substring(0, 4) : Auth.user()?.full_name?.replace(/\s/g, '').substring(0, 4)}-{val.employee_id} </td>
                                                            <td  >
                                                                {val.first_name + ' ' + (val.last_name !== "undefined" && val.last_name !== null && val.last_name !== "null" ? val.last_name : "")}
                                                            </td>
                                                            <td> {val.e_mail !== "undefined" && val.e_mail !== null ? val.e_mail : "N/A"} </td>
                                                            <td > {val.mobile !== "undefined" && val.mobile !== null ? val.mobile : "N/A"} </td>
                                                            <td> {val.current_country !== "undefined" && val.current_country !== null && val.current_country.length ? val.current_country : "N/A"} </td>
                                                            <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                                <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                                    {
                                                                        selectedClient?.access_level == "Editor" ? <div title='Add Document' className={`item d-flex align-center justify-center pointer`}

                                                                            onClick={() => addToDocumentHandler(val.employee_id)}  >
                                                                            <i className='icons sT-icon secondary i-xxs addDocument-icon'></i>
                                                                        </div>
                                                                            :
                                                                            Auth?.boolUserType() != 2 && Auth?.boolUserType() !== 4 && <div title='Add Document' className={`item d-flex align-center justify-center pointer`}

                                                                                onClick={() => addToDocumentHandler(val.employee_id)}  >
                                                                                <i className='icons sT-icon secondary i-xxs addDocument-icon'></i>
                                                                            </div>

                                                                    }
                                                                    <div className="item d-flex align-center justify-center pointer " title='Edit'
                                                                        onClick={() => EmployeeClickHandle(val.employee_id)}
                                                                    >
                                                                        <i className='icons sT-icon secondary i-xs edit-icon'></i>
                                                                    </div>
                                                                    {selectedClient?.access_level == "Editor" ? <div className="item d-flex align-center justify-center pointer "
                                                                        onClick={() => { deleteClickHandler(val.employee_id) }} title='Delete'>
                                                                        <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                                    </div>
                                                                        :
                                                                        Auth?.boolUserType() != 2 && Auth?.boolUserType() !== 4 &&
                                                                        <div className="item d-flex align-center justify-center pointer "
                                                                            onClick={() => { deleteClickHandler(val.employee_id) }} title='Delete'>
                                                                            <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    :
                                    <div className='d-flex no-rec-founnd' >
                                        <DataNotFound className={"text-nowrap"} />
                                        <div className="plus-emp-wrapper">
                                            <Dropdown
                                                // key={employeeData?.gender?.value}
                                                ClassName={'emp-dropdown'}
                                                padding={'12.5px 16px'}
                                                borderRadius={'8px'}
                                                textAlign={'left'}
                                                data={['+ Single add', '+ Bulk Upload']}
                                                defaultValue={'+ Add Employee'}
                                                clickHandle={(val) => handleEmployeeDropdown(val)}
                                            />
                                        </div>
                                    </div>
                        }
                        {/* <tbody className='table-body'>
                                    {employeeManagement?.data?.map((val, key) => {
                                        return (
                                            <tr className="table-row" key={key} onClick={() => EmployeeClickHandle(val.employee_id)}>
                                                <td  > {(Auth?.boolUserType() == 2 || Auth.boolUserType() == 4) ? selectedClient?.business_name?.replace(/\s/g, '').substring(0, 4) : (Auth?.boolUserType() == 1) ? Auth.user()?.business_name.replace(/\s/g, '').substring(0, 4) : Auth.user()?.full_name?.replace(/\s/g, '').substring(0, 4)}-{val.employee_id} </td>
                                                <td  >
                                                    {val.first_name + ' ' + (val.last_name !== "undefined" && val.last_name !== null && val.last_name !== "null" ? val.last_name : "")}
                                                </td>
                                                <td> {val.e_mail !== "undefined" && val.e_mail !== null ? val.e_mail : "N/A"} </td>
                                                <td > {val.mobile !== "undefined" && val.mobile !== null ? val.mobile : "N/A"} </td>
                                                <td> {val.current_country !== "undefined" && val.current_country !== null && val.current_country.length ? val.current_country : "N/A"} </td>
                                                <td onClick={(e) => e.stopPropagation()}>
                                                    <div className='dots-wrap d-flex justify-center align-center relative' onClick={(e) => { handleManageBtn(e, key) }} >
                                                        {/* <img src={dots} alt="" onClick={(e) => { handleManageBtn(e, key) }} /> */}
                        {/* <i className='dots-icon' > </i>
                                                    </div>
                                                    {<div className={`dropdown-wrap absolute ${activeManage === key && 'active'}`} ref={ref}>
                                                        <div className="item d-flex align-center mb-10 pointer " onClick={() => addToDocumentHandler(val.employee_id)} >
                                                            <i className='icons mr-5 add '></i>
                                                            <p className="text-2xs w-500 secondary">Add Document</p>
                                                        </div>
                                                        <div className="item d-flex align-center mb-10 pointer"
                                                            // onClick={() => { deleteHandler(val.employee_id) }}
                                                            onClick={() => { deleteClickHandler(val.employee_id) }} */}


                    </div>
                </div>
            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
            />}

            <SelectClientPop reference={selectclientRef} onClose={closeSelectClientPop} />

            <BulkEmployeePop
                reference={buldUploadRef}
                onClose={closeEmployeeBulkPop}
                handleBulkInput={handleBulkInput}
                inputBulkRef={inputBulkRef}
                bulkFile={bulkFile}
                handleBulkUpload={handleBulkUpload}
                setBulkFile={setBulkFile}
                crossBtnHandler={crossBtnHandler}

            />

        </>
    )
}

export default EmployeesManagement