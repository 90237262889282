import React, { useState } from 'react'
import './settings.scss'
import Dashboard from '../DashBoard'
import PersonalSetting from './PersonalSetting/PersonalSetting'
import OtherSettings from './OtherSettings/OtherSettings'
import Auth from '../../Classes/Auth'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import Tabs from '../../CommanComponents/Tabs/Tabs'
import MySubscription from './MySubscription/MySubscription'
import { adminID } from '../../config'
import ReminderSetting from './ReminderSetting/ReminderSetting'
import { useSelector } from 'react-redux'
const Settings = () => {
    const [activeTab, setActiveTab] = useState(Auth?.boolUserType() !== 5 ? 'Personal' : "Security")
    const settings = useSelector(e => e.settings)
    console.log(settings?.personal, "SAdvdgh")
    return (
        <>
            <div className="main-setting-wrappper">
                <div className="d-flex align-center justify-between mb-20">
                    <SectionHeading ClassName={''}>
                        Settings
                    </SectionHeading>
                    <div className="d-flex align-center ml-10 mr-30">
                        <p className="text-xs w-600 secondary description-para mr-5"> Account No. :-</p>
                        <p className="text-xs w-400 secondary description-para"> {settings?.personal?.data?.account_no} </p>
                    </div>
                </div>
                {/* {console.log(Auth?.user().Ac_no, "Qfdehrgbv")} */}
                {/* <div className="tabs-wrapper d-flex align-center">
                    <button className="tab-btn transparent text-xxs w-700 active">Personal</button>
                    <button className="tab-btn transparent text-xxs w-700">Setting</button>
                </div> */}
                <div className="tabs-main-wrapper">
                    <Tabs
                        tabs={[Auth?.boolUserType() !== 5 && 'Personal', 'Security', Auth?.boolUserType() !== 5 && adminID !== Auth?.user().user_id && "My Subscription", "Reminder"].filter(Boolean)}
                        defaultValue={activeTab}
                        activeTabHandle={(e) => setActiveTab(e)}
                        TabButtonClass={"default-tab text-xs w-700"}
                        ActiveTabClass={"active"}
                        TabWrapperClass={"setting-tabs-wrapper"}
                    />
                </div>
                <div className="setting-content-wrap">
                    {activeTab === "Personal" ? <PersonalSetting /> :
                        activeTab === "Security" ? <OtherSettings /> :
                            activeTab === "Reminder" ? <ReminderSetting /> :
                                <MySubscription />

                    }
                </div>
            </div>

        </>
    )
}

export default Settings