import { FALSE } from "sass"
import AdminRegisterUserAction from "../Actions/AdminRegisteredUser/actionType"



const initState = {
    loading: false,
    succuss: false,
    error: false,
    data: [],
    login_history: {
        loading: false,
        succuss: false,
        error: false,
        data: [],
    }
}

const adminRegisteredUser = (state = initState, { type, payload }) => {
    switch (type) {
        case AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_LOADING: return {
            ...state,
            loading: true
        }
        case AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_SUCCESS: return {
            ...state,
            loading: false,
            data: payload
        }
        case AdminRegisterUserAction.SET_ADMIN_REGISTER_LOGIN_HISTORY_LOADING: return {
            ...state,
            login_history: {
                ...state?.login_history,
                loading: true
            }
        }
        case AdminRegisterUserAction.SET_ADMIN_REGISTER_LOGIN_HISTORY: return {
            ...state,
            login_history: {
                ...state?.login_history,
                loading: false,
                data: payload
            }
        }
        default: return state
    }
}

export default adminRegisteredUser